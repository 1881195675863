.container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 500;
    box-sizing: border-box;
    background: linear-gradient(110.45deg, rgba(0, 29, 38, 0.4) 43.18%, rgba(1, 51, 67, 0.4) 110.25%);
    backdrop-filter: blur(3px);

}

.deletebox {

    position: absolute;
    width: 40%;
    height: 60%;
    left: 30%;
    top: 20%;

    background: #FFFFFF;

    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
}

.boxsplit {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
}

.title {
    width: 100%;
    height: 15%;
    box-sizing: border-box;

    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px;


    border-bottom: 1px solid #DDDEE0;
    order: 0;
}

.titleText {
    font-family: 'Zilla Slab';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;

    color: #0A0B09;

    order: 0;
}

.buttonOut {

    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    justify-content: center;
    align-self: center;
    order: 1;
}


.xbutton {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    justify-content: center;
    align-self: center;
    order: 1;
    width: 24px;
    height: 24px;
    cursor: pointer;
    display: inline;
}

.deleteboxContent {
    width: 100%;
    height: 85%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;

    order: 1;
}

.editboxContentTextNimage {
    width: 100%;
    height: 82%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 0px;

    order: 0;
}

.imageUploadPart {
    margin: 20px 0px 20px 0px;
    width: 88%;
    display: flex;
    flex-direction: row;
    align-self: center;
    align-items: flex-start;
    padding: 12px;
    gap: 12px;

    background: #EAF2F4;
    border-radius: 12px;

    order: 0;
}

.book_image {
    /* width: 68px;
    height: 100px; */
    width: 7vw;
    border-radius: 6px;

    /* Inside auto layout */

   
    order: 0;
}

.book_name {
align-self: center;
font-family: 'Zilla Slab';
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 20px;

color: #0A0B09;

}
.editboxContentTextP{
    width: 92%;
    align-self: center;

/* Inside auto layout */

order: 0;


}
.editboxContentTextTitle{
text-align: center;
font-family: 'Zilla Slab';
font-style: normal;
font-weight: 600;
font-size: 32px;
line-height: 40px;
/* or 125% */

text-align: center;
box-sizing: border-box;
/* Storywise/Vampire Black */

color: #0A0B09;


}

.buttons {
    width: 100%;
    height: 18%;
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 16px 16px 16px 0px;
    gap: 15px;

    border-top: 1px solid #DDDEE0;

    /* Inside auto layout */

    order: 1;
}

.cancelButtton {
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 10px;
    gap: 8px;

    background: #FFFFFF;
    /* Storywise/Jet Stream */

    border: 2px solid #BAC6CA;
    border-radius: 14px;

    /* Inside auto layout */

    cursor: pointer;
    order: 0;
}

.cancelButtton:hover {
    opacity: 0.5;
}

.cancelButttonText {

    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height, or 120% */

    display: flex;
    align-items: center;
    text-align: center;

    /* Storywise/Vampire Black */

    color: #0A0B09;


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

.deleteButtton {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 12px;
    gap: 8px;


    background: #E91E63;
    border-radius: 14px;

    /* Inside auto layout */
    cursor: pointer;

    order: 1;
}

.deleteButtton:hover {
    opacity: 0.8;
}

.deleteButttonImage {
    width: 20px;
    height: 20px;

    order: 0;
}

.deleteButttonText {

    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height, or 120% */

    display: flex;
    align-items: center;
    text-align: center;

    /* Storywise/White */

    color: #FFFFFF;


    order: 1;
}
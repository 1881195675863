.categoty-card {
  display: flex;
  padding: 1.4vh 1.6vh 1.4vh 1.6vh;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid #e9e7e7;
  /* background: var(--storywise-page-bg, #F7F7F7); */
  position: relative;
  user-select: none;
}

.show-hand {
  cursor: pointer;
}

.selected {
  display: flex;
  padding: 1.4vh 1.6vh 1.4vh 1.6vh;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid #e9e7e7;
  background: var(--storywise-gainsboro, #dddee0);
}

.categoty-name {
  color: var(--storywise-vampire-black, #0a0b09);
  font-family: 'Lato';
  font-size: 1.6vh;
  font-style: normal;
  font-weight: 500;
  line-height: 2vh;
  /* 125% */
}

.clickable {
  cursor: pointer;
}

.greenButtons {
  background: rgba(28, 234, 160, 0.5);
}

.deleteButton {
  border-color: none;
  border-width: 0px;
  position: relative;
  flex-shrink: 0;
  box-sizing: border-box;
  background: none;
}

.deletexmark {
  width: 25.2px;
  min-width: 0px;
  min-height: 0px;
  position: relative;
  flex-shrink: 0;
  box-sizing: border-box;
}
.input {
  display: flex;
  padding: 0vh;
  justify-content: center;
  align-items: center;
  color: var(--storywise-vampire-black, #0a0b09);
  font-family: 'Lato';
  font-size: 1.6vh;
  font-style: normal;
  font-weight: 500;
  line-height: 2vh;
  border: none;
  background: var(--storywise-white, #f7f7f7);
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  border-radius: 0px;
}

.input::placeholder {
  color: var(--storywise-vampire-black, #0a0b09);
  font-family: 'Lato';
  font-size: 2vh;
  font-style: normal;
  font-weight: 500;
  line-height: 2vh;
  text-align: left;

  color: #a2a4a1;
}

.categoty-card-input {
  display: flex;
  padding: 1.4vh 1.6vh 1.4vh 1.6vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid #e9e7e7;
  background: var(--storywise-page-bg, #f7f7f7);
  cursor: pointer;
  position: relative;
}

.tooltip1 {
  display: none;
}

.categoty-card:hover .tooltip1 {
  display: block;
  position: absolute;
  top: 3vh;
  left: 3vw;
  z-index: 1000;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* add border with this color 1CEAA0 */
  /* border-color: #1CEAA0; */
  border-width: 1px;
  border-style: solid;
  width: 250%;
}

.categoty-card-input:hover .tooltip1 {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: #dddee0;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* add border with this color 1CEAA0 */
  border-color: #1ceaa0;
  border-width: 1px;
  border-style: solid;
  width: 250%;
}

.tooltipText {
  color: #000;
  font-family: 'Lato';
  font-size: 1.8vh;
  font-style: normal;
  font-weight: 500;
  line-height: 2.2vh;
}

.customCheckbox {
  cursor: pointer;
}

.FrameRootRoot{
    width: 100%;
    height: 100%;
    position: relative;
    gap: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;
    /* height: 80vh; */
    overflow-y: auto;
}



.FrameRootRoot::-webkit-scrollbar {
    width: 4px;
    /* Adjust the scrollbar width as desired */
    /* background-color: #f2f2f2; */
    /* Set a background color for the scrollbar */
  }
  
  .FrameRootRoot::-webkit-scrollbar-thumb {
    /* background-color: #d2cfcf; */
    /* Set the color of the scrollbar thumb */
    border-radius: 4px;
    /* Round the corners of the thumb */
  }

  
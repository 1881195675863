.genreContainer {
  width:100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 2px 2px 2px;
  border-width: 0px 0px 0px 0px;
  border-style: solid;
  border-color: #dddee0;
  height: 100%; /* Set container height to viewport height */
  box-sizing: border-box;
}

.constPart {
  position: sticky;
  top: 0;
  width: 100%;
  padding-top: 24px;
  padding-bottom: 4px;
  background-color: #fff; /* Set your preferred background color */
}


.title {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0px 0px 12px 0px;
  padding: 24px 8px 23px 16px;
  border-width: 0px 0px 1px 0px;
  border-style: solid;
  border-color: #0a0a09;
  box-sizing: border-box;
}

.titleText{
  position: relative;
  font-size: 20px;
  font-weight: 600;
  font-family: Zilla Slab;
  line-height: 20px;
  white-space: nowrap;
  box-sizing: border-box;
}


select {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  padding: 17px;
  border-width: 1px;
  border-radius: 8px;
  border-style: dashed;
  border-color: #bac6ca;
  box-sizing: border-box;
  align-items: center;
  appearance: none; /* Remove default appearance */
  font-size: 18px;
  font-weight: 600;
  font-family: Lato;
  line-height: 20px;
  white-space: nowrap;
  margin-bottom: 6px;
  color: #000; /* Set the color to a dark value */
  text-align: center;
}


select option {
  font-size: 18px;
  font-weight: 600;
  font-family: Lato;
  line-height: 20px;
  white-space: nowrap;
  box-sizing: border-box;
  color: #000; /* Set the color to a dark value */
  text-align: center;
}



select:hover {
  border-color: #272725;
}

select:focus {
  outline: none;
}


/* .dropdown select::-webkit-scrollbar {
  width: 4px; 
  background-color: #f5f5f5; 
}

.dropdown select::-webkit-scrollbar-thumb {
  background-color: #888;
    border-radius: 8px;

}

.dropdown select::-webkit-scrollbar-thumb:hover {
  background-color: #7f7f7f;
    border-radius: 8px;
} */



option:hover{
  background-color: #888; 
  border-radius: 8px;
}

.addnew {
  position: relative;
  font-size: 18px;
  font-weight: 600;
  font-family: Lato;
  line-height: 20px;
  white-space: nowrap;
  box-sizing: border-box;
  color: #000; /* Set the color to a dark value */
  text-align: center;
}

.genres{
  width: 100%;
  position: relative;
  overflow-y: auto;
}

.genre {
  width: 100%;
  position: relative;
  align-items: center;
  box-sizing: border-box;

}







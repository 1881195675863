.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
    background: rgba(0, 0, 0, 0.75);
}



.container {
    position: fixed;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.18);
    box-sizing: border-box;
    z-index: 100;
    top: 6.5%;
    right: 0%;
    width: 33.33%;
    height: 94%;

}


.filter{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0px 0px;
    box-sizing: border-box;
    padding: 2%;
}


.filterContainer {
    gap: 16px;
    display: flex;
    flex-shrink: 0;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 24px;
    border-width: 0px 0px 1px 0px;
    border-style: solid;
    border-color: #dddee0;
    box-sizing: border-box;
}

.filterTitle {
    font-size: 24px;
    font-weight: 600;
    font-family: Zilla Slab;
    line-height: 20px;
    box-sizing: border-box;
}

.filterButton {
    gap: 16px;
    display: flex;
    flex-shrink: 0;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 24px;
    border-width: 0px 0px 1px 0px;
    border-style: solid;
    border-color: #dddee0;
    box-sizing: border-box;
}

.clearAll {
    width: 83px;
    height: 36px;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: flex-start;
    padding: 6px 10px;
    border-width: 0px;
    border-radius: 10px;
    border-top-width: 2px;
    border-right-width: 2px;
    border-bottom-width: 2px;
    border-left-width: 2px;
    border-style: solid;
    border-color: #bac6ca;
    box-sizing: border-box;
    background-color: #ffffff;
    cursor: pointer;
}


.clearAll:hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
    opacity: 0.5;
}

.clearAllText {
    color: #0a0a09;
    font-weight: 600;
    font-family: Lato;
    line-height: 20px;
    text-align: center;
    white-space: nowrap;
    box-sizing: border-box;
}



.save {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 0px;
    border-width: 0px;
    border-radius: 10px;
    box-sizing: border-box;
    background-color: #1ce99f;
    cursor: pointer;
}

.save:hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
    opacity: 0.5;
}

.saveText {
    color: #0a0a09;
    font-weight: 600;
    font-family: Lato;
    line-height: 20px;
    text-align: center;
    box-sizing: border-box;}

.filterContent {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0px 0px;
    box-sizing: border-box;
    padding: 2%;
}

.editboxContentTextTitle {
    /* Your styles here */
}

.labelText {
    align-self: flex-start;
    margin: 0px 0px 1px 28px;
    color: #0a0a09;
    font-size: 14px;
    font-weight: 700;
    font-family: Lato;
    line-height: 16px;
    box-sizing: border-box;}

.lable {
    /* Your styles here */
}

.input {
    gap: 8px;
    display: flex;
    flex-shrink: 0;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 0px 24px 16.7px 24px;
    padding: 0px 15px;
    border-width: 1px;
    border-radius: 16px;
    border-style: solid;
    border-color: #c3cad4;
    box-sizing: border-box;
    background-color: #ffffff;}
.listContainer {
  position: relative;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
}
/* 


.title {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0px 0px 12px 0px;
  padding: 24px 8px 23px 16px;
  border-width: 0px 0px 1px 0px;
  border-style: solid;
  border-color: #0a0a09;
  box-sizing: border-box;
}

.titleText{
  position: relative;
  font-size: 20px;
  font-weight: 600;
  font-family: Zilla Slab;
  line-height: 20px;
  white-space: nowrap;
  box-sizing: border-box;
} */


.titleContainer {

  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* padding-top: 24px;
  padding-bottom: 4px;
  margin: 0px 0px 8px 0px;
  border-width: 0px 0px 1px 0px;
  border-style: solid;
  border-color: #0a0a09; */
  margin: 0px 0px 12px 0px;
  padding: 24px 8px 23px 16px;
  border-width: 0px 0px 1px 0px;
  border-style: solid;
  border-color: #0a0a09;
  box-sizing: border-box;
}

.title {
  position: relative;
  margin: 1px 0px 0px 0px;
  font-size: 20px;
  font-weight: 600;
  font-family: Zilla Slab;
  line-height: 20px;
  white-space: pre-wrap;
  box-sizing: border-box;
}

.expandSign {
  width: 17.3px;
  min-width: 0px;
  min-height: 0px;
  position: relative;
  flex-shrink: 0;
  box-sizing: border-box;
  cursor: pointer;
}

.horizontalLine {
  display: block;
  width: 100%;
  unicode-bidi: isolate;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
  margin-inline-start: auto;
  margin-inline-end: auto;
  overflow: hidden;
  border-style: inset;
  border-width: 1px;
  border: 0px, 0px, 0px, 0px;
}


.booksContainer {
  width: 100%;
  position: relative;
  gap: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 15px;
  border-width: 1px;
  border-radius: 8px;
  border-style: solid;
  border-color: #dddee0;
  box-sizing: border-box;
}

.arrow {
  width: 17.3px;
  min-width: 0px;
  min-height: 0px;
  position: relative;
  flex-shrink: 0;
  box-sizing: border-box;
  cursor: pointer;
  width: 24px;
  height: 24px;
  cursor: pointer;


}

.arrowup {
  width: 17.3px;
  min-width: 0px;
  min-height: 0px;
  position: relative;
  flex-shrink: 0;
  box-sizing: border-box;
  cursor: pointer;
  width: 24px;
  height: 24px;
  transform: scaleY(-1);

  cursor: pointer;


}

.arrowup:hover {
  opacity: 0.5;
}

.arrow:hover {
  opacity: 0.5;
}
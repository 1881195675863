.container {
    height: 100%;
    width: 100%;
    position: relative;
    gap: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;
    flex: none;
    order: 1;
    flex-grow: 0;
    padding: 20px;;
}

.title {
    position: relative;
    align-self: flex-start;
    margin: 0px 0px 8px 0px;
    color: #0a0a09;
    font-size: 20px;
    font-family: Lato;
    line-height: 28px;
    text-align: center;
    white-space: nowrap;
    box-sizing: border-box;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.selectfiles {
    width: 94%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 20px;

    flex: none;
    order: 1;
    flex-grow: 0;
}
.dragNdrop {
    box-sizing: border-box;
    cursor: pointer;
    flex: none;
    order: 0;
    align-self: center;
    width: 100%;
    flex-grow: 0;
}


.orLine {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 35px;

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.Line {
    width: 46.28%;
    height: 1px;
    position: relative;
    border-width: 1px 0px 0px 0px;
    border-style: solid;
    border-color: #71858b;
    box-sizing: border-box;
}

.orLineText {
    position: relative;
  font-size: 20px;
  font-weight: 700;
  font-family: Lato;
  line-height: 28px;
  text-align: center;
  box-sizing: border-box;
}

.uploadb{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex: none;
    order: 2;
    flex-grow: 0;
}


.uploadbutton{
display: flex;
flex-direction: row;
align-items: center;
padding: 12px 16px;
gap: 8px;


background: #0A0B09;
border-radius: 14px;

/* Inside auto layout */

flex: none;
order: 2;
flex-grow: 0;
}

.uploadbuttonText{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height, or 120% */
    
    display: flex;
    align-items: center;
    text-align: center;
    
    /* Storywise/White */
    
    color: #FFFFFF;
    
    
    /* Inside auto layout */
    
    flex: none;
    order: 1;
    flex-grow: 0; 
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 500;
    background: rgba(0, 0, 0, 0.75);
  }

  .uploadProgressValue{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    z-index: 100;
    
 
  }



  .uploadProgressText{
    z-index: 100;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height, or 120% */
    
    display: flex;
    align-items: center;
    text-align: center;
    
    /* Storywise/White */
    
    color: #FFFFFF;
    
  }
  
  
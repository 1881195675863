/* global.css */

@font-face {
  font-family: 'Zilla Slab';
  src: url('./fonts/Zilla_Slab/ZillaSlab-Regular.ttf') format('truetype');
}

/* Other global styles */
@font-face {
  font-family: 'Lato';
  src: url('./fonts/Lato/Lato-Regular.ttf');
}

@font-face {
  font-family: 'ROBERTO';
  src: url('./fonts/Roboto/Roboto-Medium.ttf');
}
/* //font Inter /Users/Owner/src/fe_publishers_mvp/src/fonts/Inter/static/Inter-Regular.ttf */
@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter/static/Inter-Regular.ttf');
}
::-webkit-scrollbar {
  width: 10px; /* Scrollbar width */
  height: 10px; /* Scrollbar height */
  background-color: #f5f5f5;
}

::-webkit-scrollbar-track {
  width: 2px;
  background: #f1f1f1; /* Change the color as desired */
}

::-webkit-scrollbar-thumb {
  background-color: #1ceaa0; /* Scrollbar thumb color */
  border-radius: 12px;
}

/* ::-webkit-scrollbar-thumb:hover {
   background-color: #7f7f7f; 
     border-radius: 8px;
  } */

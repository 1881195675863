.container {
  /* height: 100%;
  width: 100%; */
  /* width: 78.3vw; */
  align-self: flex-start;
  /* position: fixed; */
  /* right: 0px;
    top: 0px; */
  box-sizing: border-box;
  overflow: auto;
}

.logosignincontainer {
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.signcontainer {
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  /* padding: 2vh 3vw 2vh 3vw; */
}

.body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1.5vh;
  width: 100%;
}

.titlelogo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  /* gap: 0.5vw; */
}

.loginparttitle {
  display: flex;
  color: #0a0b09;
  text-align: left;
  font-family: 'Zilla Slab';
  font-size: 5vh;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.loginparttitlepart2 {
  display: flex;
  color: #0a0b09;
  text-align: left;
  font-family: 'Zilla Slab';
  font-size: 5vh;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.loginpartsubtitle {
  font-family: 'Lato';
  font-size: 1.8vh;
  font-style: normal;
  font-weight: 500;
  line-height: 2.4vh;
  margin-bottom: 0.5vh;
}

.loginpartsubtitle1 {
  font-family: 'Lato';
  font-size: 2.4vh;
  font-style: normal;

  line-height: 3.2vh;
  margin-bottom: 0.5vh;
}

.linktomail {
  color: #2f80ed;
  font-family: 'Inter';
  font-size: 1.8vh;
  font-style: normal;
  font-weight: 500;
  line-height: 2.4vh;
  text-decoration: none;
}

.labelInputContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.label {
  color: var(--gray-700, #344054);

  /* Text sm/Medium */
  font-family: 'Lato';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  margin-bottom: 3px;

}

.input {
  width: 98%;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* paddinxg: 18px 16px; */
  font-family: 'Inter';
  border: 1px solid #dddee0;
  border-radius: 8px;
  padding: 10px 16px;
  font-weight: normal; /* Set the desired font-weight */
  font-size: 14px;
  width: 35vw;
  box-sizing: border-box;
  text-align: left;
}

.inputnumber {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* paddinxg: 18px 16px; */
  font-family: 'Inter';
  border: 1px solid #dddee0;
  border-radius: 8px;
  padding: 5px 5px;
  font-weight: normal; /* Set the desired font-weight */
  font-size: 1.7vh;
  box-sizing: border-box;
  text-align: left;
  width: 4vw;
  height: 30px;
  text-align: center;
}

.inputnumber:focus {
  outline: none;
  font-weight: normal; /* Set the desired font-weight */
  cursor: text;
  text-align: left;
}

.inputnumber:hover {
  border: 1px solid #0a0b09;
  font-weight: normal; /* Set the desired font-weight */
  cursor: text;
  box-shadow: 0px 0px 4px rgba(10, 11, 9, 0.25);
  text-align: left;
}

.inputnumber::placeholder {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  text-align: left;
  text-align: left;

  color: #0a0b09;
}

.textarea {
  height: 40vh; /* Initial height for the textarea */
  /* resize: vertical;  */
  text-align: left;
}

.input:focus {
  outline: none;
  font-weight: normal; /* Set the desired font-weight */
  cursor: text;
  text-align: left;
}

.input:hover {
  border: 1px solid #0a0b09;
  font-weight: normal; /* Set the desired font-weight */
  cursor: text;
  box-shadow: 0px 0px 4px rgba(10, 11, 9, 0.25);
  text-align: left;
}

.input::placeholder {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  text-align: left;

  color: #0a0b09;
}

/* .fotter {
  width: 100%;
  border-top: 1px solid #dce0e5;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 1vw;
  padding-top: 1vh;
  margin-top: 3vh;
} */

.buttoncontainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:flex-end;
  align-self: flex-end;
  gap: 1vw;
}

.buttoncontainerback {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-direction: row;
  cursor: pointer;
}

.enable_auto {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  /* gap: 1vw; */
  margin-top: 0vh;
}

.label_enable_auto {
  color: var(--neutral-n-800, #474d66);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Inter';
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 133.333% */
  letter-spacing: -0.5px;
}



.toggleObservationsButton {
  display: flex;
  padding: 1vh 1vh;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 14px;
  background: transparent; /* No background color */
  border: 2px solid var(--storywise-secondary-500, #1ceaa0); /* Border color */
  cursor: pointer;
  font-family: 'Inter';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 133.333% */

}

.label_enable_auto_delay {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  /* gap: 1vw; */
  margin-top: 1vh;
  gap: 1vw;
}

.button {
  display: flex;
  padding: 1.2vh 1.6vh;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 14px;
  background: var(--storywise-secondary-500, #1ceaa0);
  cursor: pointer;
  min-width: 10vw;
}

.textbutton {
  color: var(--storywise-vampire-black, #0a0b09);
  text-align: center;
  font-family: 'Inter';
  font-size: 2vh;
  font-style: normal;
  font-weight: 600;
  line-height: 2.4vh;
  /* 120% */
}



.textbuttonBack {
  color: var(--sass-gray-300, #95a1b2);
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.buttoncontainerback {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-direction: row;
  cursor: pointer;
}
.pagenumberfirst {
  color: var(--sass-gray-700, #454f5f);
  font-family: 'Zilla Slab';
  font-size: 1.6vh;
  font-style: normal;
  font-weight: 500;
}
.pagenumber {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  color: var(--sass-gray-300, #95a1b2);
  font-family: 'Zilla Slab';
  font-size: 1.2vh;
  font-style: normal;
  font-weight: 700;
}
.fotter {
  /* height: 0px; */
  width: 100%;
  border-top: 1px solid #dce0e5;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 1vw;
  padding-top: 1vh;
  margin-top: 3vh;
  padding-bottom: 1vh;
  margin-bottom: 1vh;
}

.PageRoot {
  height: 100%;
  width: 100%;
  /* width: 78.3vw; */
  align-self: flex-start;
  /* position: fixed; */
  /* right: 0px; */
  top: 0px;
  box-sizing: border-box;
  overflow: hidden;
}
.backdrop {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  height: 94vh;
  width: 100vw;
  box-sizing: border-box;

  /* //    width: 21.7vw; */
}

.successMessage {
  z-index: 1000;
  width: 100%;
  user-select: none;
  position: absolute;
  top: 5.5vh;
}

.errorflashMessage {
  z-index: 1000;
  width: 100%;
  user-select: none;
  position: absolute;
  top: 3vh;
}

.successMessageLeft {
  /* position: absolute; */
  margin-left: 25%;
}

.secondpart {
  height: 94vh;
  width: 85vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  overflow: auto;
}

.userMenuDropdown {
  height: 94vh;
  width: 15vw;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  gap: 2vh;
  overflow: auto;
}

.userName {
  overflow: hidden;
  color: var(--neutral-800, #191d23);
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1.6vh;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.menuList {
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  box-sizing: border-box;
  /* padding: 1vh; */
  border-bottom: 1px solid #d0d5dd;
  align-self: flex-start;
  padding: 0vh 0vh 2vh 0vh;
}

.menuItem {
  overflow: hidden;
  color: var(--storywise-stormcloud, #71858b);
  text-overflow: ellipsis;
  white-space: nowrap;
  /* font-family: Manrope; */
  font-size: 1.6vh;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-self: flex-start;
  padding: 10px 0;
  width: 100%;
}

.menuItemActive {
  overflow: hidden;
  color: var(--neutral-800, #191d23);
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1.6vh;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  cursor: pointer;
  padding: 10px 0;
}

.menuItem:hover {
  background-color: #f0f4f5;
}

.menuIcon {
  margin-right: 12px;
  font-size: 2vh;
  vertical-align: middle;
}

.bottomBorder {
  border-bottom: 1px solid #f0f4f5;
  margin: 8px 0;
}

.profileTitle {
  color: var(--neutral-500, #64748b);
  font-family: 'Zilla Slab';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  align-self: flex-start;
  /* margin: 2vh 0vh 2vh 0vh; */
  box-sizing: border-box;
  width: 85%;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.profilerow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.userRole {
  overflow: hidden;
  color: var(--neutral-400, #a0abbb);
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1.6vh;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.userText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  align-self: stretch;
}

.logoutButton {
  display: flex;
  padding: 6px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 4px;
  background: var(--neutral-50, #f7f8f9);
  border: none;
  cursor: pointer;
  font-weight: bold;
  color: var(--neutral-800, #191d23);
  font-size: 1.6vh;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
  width: 80%;
  margin-top: 10px;
}

.logoutButton:hover {
  background-color: #f0f4f5;
  border: 1px solid #1ceaa0;
  border-radius: 8px;
}

/* UserMenuDropdown.module.css */

.menuSectionTitle {
  margin: 30px 0 20px;
  color: var(--neutral-500, #64748b);
  font-family: 'Zilla Slab';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  align-self: flex-start;
  /* margin: 2vh 0vh 2vh 0vh; */
  box-sizing: border-box;
  width: 85%;
  text-transform: uppercase;
}

.profileSection {
  margin-left: 20px;
  margin-top: 40px;
  width: 100%;
}

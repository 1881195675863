.NoteRootRoot {
  width: 100%;
  position: relative;
  gap: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px 12px 12px 12px;
  border-radius: 8px;
  box-sizing: border-box;
  background-color: #f8f8f8;
  cursor: pointer;
}

.HighlightRoot {
  width: 100%;
  position: relative;
  gap: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px 12px 12px 12px;
  border-radius: 8px;
  box-sizing: border-box;
  background-color: rgba(99, 217, 255, 0.4);
  cursor: pointer;

}

.ApprovedRoot {
  width: 100%;
  position: relative;
  gap: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px 12px 12px 12px;
  border-radius: 8px;
  box-sizing: border-box;
  border: 2px solid #1CEAA0;
  background: var(--storywise-white, #FFF);
  cursor: default;
}

.RejectRoot {
  width: 100%;
  position: relative;
  gap: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px 12px 12px 12px;
  border-radius: 8px;
  box-sizing: border-box;
  border-radius: 14px;
  border: 2px solid #ED437D;
  cursor: default;

  background: var(--storywise-white, #FFF);}


.NoteRootRoot:hover {
  background-color: #ebebeb;
  cursor: pointer;
}

.Group {
  width: 100%;
  /* position: relative; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  overflow: auto;

}

.Group1 {
  /* width: 100%; */
  /* position: relative; */
  gap: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
}

.date_button {
  width: 100%;
  /* position: relative; */
  gap: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
}

.writerImg {
  width: 24px;
  min-width: 0px;
  min-height: 0px;
  position: relative;
  flex-shrink: 0;
  box-sizing: border-box;
}

.writer {
  position: relative;
  margin: 0px 0px 0px 0px;
  font-size: 12px;
  font-family: Lato;
  line-height: 16px;
  text-align: justify;
  /* white-space: nowrap; */
  box-sizing: border-box;
}

.date {
  position: relative;
  margin: 0px 0px 0px 0px;
  font-size: 12px;
  font-family: Lato;
  line-height: 16px;
  text-align: justify;
  /* white-space: nowrap; */
  box-sizing: border-box;
}

.option {
  width: 28px;
  height: 20px;
  /* position: relative; */
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: flex-end;
  padding: 3px 12px;
  border-width: 0px;
  border-radius: 6px;
  margin-left: 5px;
  box-sizing: border-box;
  background: none;
  cursor: pointer;
}

.option:hover {
  opacity: 70%;

}

.threeDots {
  width: 3px;
  min-width: 0px;
  min-height: 0px;
  position: relative;
  box-sizing: border-box;
  align-self: flex-end;
}

.textNote {
  width: 100%;
  position: relative;
  font-family: Lato;
  line-height: 20px;
  box-sizing: border-box;
}
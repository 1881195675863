/* .circleProgress {
    display: flex;
   
  }
  
  .circleBackground {
   
  }
  
  .circleForeground {
   
  }
   */
   .progressText {
    color: black;
  font-weight: 500;
font-size: large;
  font-family: Zilla Slab;
  line-height: 16px;
  text-align: center;
  box-sizing: border-box;
  }

  .gradient-color1 {
    background: linear-gradient(270deg, #FF007A 7.29%, #994FBC 100%);
}


.gradient-color2 {
  background: linear-gradient(180deg, #BAFF35 0%, #D7FF35 100%);
}


.gradient-color3 {
  background: linear-gradient(243.98deg, #0BD8A7 33.6%, #1BBAC5 81.91%);
}

  
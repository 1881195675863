.chatContainer {
    position: fixed;
    bottom: 0;
    right: 0.6vw;
    width: 20vw;
    z-index: 100;
    border-radius: 30px;
    background: var(--storywise-header-bg, linear-gradient(110deg, #001D26 43.18%, #013343 110.25%));
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.18);
    box-sizing: border-box;
    height: 72vh;

}

.chatall{
    width:100%;
    height:100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    box-sizing: border-box;
    position: relative;
    scrollbar-width: thin;

} 
.chatHeader {
    width: 100%;
    height: 12vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1vw;
    box-sizing: border-box;
    justify-content: space-between;
    border-bottom: 1px solid var(--storywise-stormcloud, #71858B);
    padding: 1vh 1vw 1vh 1vw;
    order:0;


}

.avatar{
    width: 4.5vw;
    height: 8vh;
}
.avatar_name{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;


}
.name {
    color: var(--storywise-white, #FFF);
    font-family: Zilla Slab;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    overflow: hidden; /* Hide overflowing text */
    white-space: nowrap; /* Prevent text from wrapping */
    text-overflow: ellipsis; /* Display ellipsis (...) for overflow */
    width: 9vw; /* Set a fixed width that suits your design */
}

.buttons{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.8vw;
    align-self: flex-start;
    margin-right: 1.7vw;
}
.close{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 0.8vw;
    height: 0.8vw;
    cursor: pointer;
    align-self: flex-start;
    padding: 1.5vh 0.5vw 0vh 0vw;
    opacity: 0.6;
}

.closeimg{
    width: 1.1em;
    height: 1.1em;
   
}

.clearChat {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 0.8vw;
    height: 0.8vw;
    cursor: pointer;
    align-self: flex-start;
    padding: 1.5vh 0vw 0vh 0vw;
    opacity: 0.6;
    font-size: 28px; /* Adjust the font size as needed */
  }

  .clearChat:hover {
    opacity: 1;
  }
  

.close:hover{
    opacity: 1;
}

.chatBody {
    width : 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1vh;
    padding: 2vh 1vw 0vh 1vw;
    box-sizing: border-box;
    overflow-y: auto;
    height: 52vh;
    order:1;
}

.chatBodyinside{
    width : 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1vh;
    box-sizing: border-box;
 
}

.chatFooter {
    display: flex;
    align-items: center;
    gap: 0.5vw;
    width : 100%;
    padding: 0vh 1vw 0vh 1vw;
    justify-self: flex-end;
    height: 8vh;
    order:2;
    box-sizing: border-box;



}
.inputfield {
    height: 5vh;
    display: flex;
    align-items: flex-start;
    gap: 10px;
    border-radius: 16px;
    width:15vw;
    background: var(--storywise-dark-card-bg, #29383D);
    box-sizing: border-box;
}

.inputfield input[type="text"] {
    flex: 1; /* Take up remaining space */
    border: none; /* Remove default input border */
    background: transparent; /* Set background to transparent */

    /* Style the placeholder text */
    color: var(--storywise-gainsboro, #DDDEE0);
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 125% */
    text-align: left;
    align-self: center;
}

.inputfield input[type="text"]::placeholder {
    /* Style the placeholder text */
    color: var(--storywise-gainsboro, #DDDEE0);
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 125% */
    text-align: left;
}


.sendButton {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    border-radius: 16px;
    background: var(--storywise-secondary-500, #1CEAA0);
    height: 5vh;
    width: 5vh;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    /* Card/Floating */
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.18);
}

.sendButtonimg {
    display: flex;
    width: 2vh;
    height:2vh;
    justify-content: center;
    align-items: center;
}



/* Style the scrollbar */
.chatBody::-webkit-scrollbar {
    width: 0.2vw; /* Set the width of the scrollbar */
}

/* Style the scrollbar track (the background) */
.chatBody::-webkit-scrollbar-track {
    background: var(--storywise-header-bg, linear-gradient(110deg, #001D26 43.18%, #013343 110.25%));
}

/* Style the scrollbar thumb (the draggable part) */
.chatBody::-webkit-scrollbar-thumb {
    background-color:#aca8a8; /* Set the color of the scrollbar */
    border-radius: 4px; /* Add border-radius for a rounded look */
}

/* Style the scrollbar thumb on hover */
.chatBody::-webkit-scrollbar-thumb:hover {
    background-color: var(--scrollbar-thumb-hover-color, #3D4E53); /* Set the color on hover */
}






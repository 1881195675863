/* StatusCell.module.css */
.notified {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 4px 8px;
    border-radius: 8px;
    box-sizing: border-box;
    background-color: #e3edf3;
    gap: 3px;
}
.uploadFailed{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 4px 8px;
    border-radius: 8px;
    box-sizing: border-box;
    background-color: #eaeff2;
    gap: 3px; 
}

.uploadFailedText{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #798185;
}
.forwarded {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 4px 8px;
    border-radius: 8px;
    box-sizing: border-box;
    background-color: #d3f7ff;
    align-items: center;
    gap: 3px;


}

.send {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 4px 8px;
    border-radius: 8px;
    box-sizing: border-box;
    background-color: #d2d7ff;
    align-items: center;
    gap: 3px;


}

.approved{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 4px 8px;
    border-radius: 8px;
    box-sizing: border-box;
    background-color: #d2ffd2;
    align-items: center;
    gap: 3px;


}

.rejected{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 4px 8px;
    border-radius: 8px;
    box-sizing: border-box;
    background-color: #f7baba;
    gap: 3px;
    align-items: center;

}
.actionRequired {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 4px 8px;
    border-radius: 8px;
    box-sizing: border-box;
    background-color: #f7f4ba;
    gap: 3px;
    align-items: center;
}

.approvedEditorial{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 4px 8px;
    border-radius: 8px;
    box-sizing: border-box;
    background-color: #ffecd2;
    align-items: center;
    gap: 3px;

}

.approvedAcquisition{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 4px 8px;
    border-radius: 8px;
    box-sizing: border-box;
    background-color: #d3f7ff;
    align-items: center;
    gap: 3px;


}

.acquired{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 4px 8px;
    border-radius: 8px;
    box-sizing: border-box;
    background-color: #ffd2ef;
    align-items: center;
    gap: 3px;

}

.approvedEditorialTextText{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #0d0909;
}

.approvedAcquisitionText{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #0d0909;
}

.acquiredText{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #0d0909;
}
.notifiedText{

    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #617984;
    
}
.forwardedText{
    font-family: 'Lato';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
color: #4A6C74;

}


.sendText{

    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #696C88;
    
    
}
.actionRequiredText{
    font-family: 'Lato';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
color: #6F6D51;

}

.rejectedText{
    font-family: 'Lato';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
color: #0d0909;

}

.approvedText{
    font-family: 'Lato';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
color: #0d0909;


}

.underReviewPriority1 {
    background-color: #e7f4fc; /* Light Blue */
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 4px 8px;
    border-radius: 8px;
    box-sizing: border-box;
    align-items: center;
    gap: 3px;
}

.underReviewPriority1Text {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #0d0909;
}

.underReviewPriority2 {
    background-color: #f5f2dc; /* Light Yellow */
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 4px 8px;
    border-radius: 8px;
    box-sizing: border-box;
    align-items: center;
    gap: 3px;
}

.underReviewPriority2Text {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #0d0909;
}

.underReviewPriority3 {
    background-color: #fdecef; /* Light Pink */
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 4px 8px;
    border-radius: 8px;
    box-sizing: border-box;
    align-items: center;
    gap: 3px;
}

.underReviewPriority3Text {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #0d0909;
}

.referredToMe {
    background-color: #e6f7e7; /* Light Green */
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 4px 8px;
    border-radius: 8px;
    box-sizing: border-box;
    align-items: center;
    gap: 3px;
}

.referredToMeText {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #0d0909;
}

.notRelevant {
    background-color: #f5f5f5; /* Light Gray */
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 4px 8px;
    border-radius: 8px;
    box-sizing: border-box;
    align-items: center;
    gap: 3px;
}

.notRelevantText {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #0d0909;
}
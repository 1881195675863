.PageRoot {
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  background-color: #f7f7f7;
  overflow: hidden;
}

.hedder {
  height: 6vh;
  position: relative;
  gap: 8px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  background-position: 50% 50%;
  background-size: cover;
  background-image: linear-gradient(110deg, #001c26 118%, #003242 348%);
}

.comparePageTitle {
  height: 50px;
  width: 100%;
  color: #0a0a09;
  font-size: 1.5em;
  font-weight: 600;
  font-family: Zilla Slab;
  /* height: 10vh; */
  box-shadow: 0px -3px 8px 0px rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.linktoManuscripts {
  margin-right: 10px;
}

a,
a:visited {
  color: black;
  text-decoration: none;
}

.hedder2 {
  background-color: white;
  margin: 0px 0px 20px 0px;
}

.navbar {
  height: 10vh;
}

.container {
  width: 97%;
  height: 83vh;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  position: absolute;
  left: 1.5%;
  right: 1.5%;
  /* top: 10vh; */
  bottom: 0vh;

  background: #f7f7f7;
  /* Card/Raised */
}

.items {
  /* position: relative;
    top: 0; */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  background-color: #fff;
  box-shadow: 2px 2px 8px rgba(200, 200, 200, 0.12);
  border-radius: 12px;
  box-sizing: border-box;
  /* overflow: hidden; */
}

.firstPart {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  margin-left: 20px;
}

.hedderTitleImg {
  width: 41px;
  height: 35px;
  position: relative;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 0px 3.38px 0px 0px;
  /* padding: 8.57px 11.6px 14.2px 11.6px; */
  box-sizing: border-box;
}

.myManuscripts {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding: 4px 0px; */
  gap: 8px;

  /* Storywise/header btn bg */

  background: linear-gradient(132.64deg, #001d26 30.86%, #013343 93.29%);
  /* Storywise/secondary-500 */

  border: 1px solid #71858b;
  border-radius: 8px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.myM {
  position: relative;
  width: 20px;
  height: 20px;
  left: 0px;
  top: 0px;
}

.totleManu {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  /* Storywise/White */

  color: #ffffff;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.hedderTitle {
  min-width: 0px;
  min-height: 0px;
  position: relative;
  margin: 0px 40px 0px 0px;
  box-sizing: border-box;
  font-family: zilla slab;
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
  line-height: 40px;
  white-space: nowrap;
  box-sizing: border-box;
}

.item {
  position: relative;
  width: 50%;
  box-sizing: border-box;
}

.successMessage {
  z-index: 1000;
  width: 100%;
  user-select: none;
}

.successMessageLeft {
  /* position: absolute; */
  margin-left: 25%;
}

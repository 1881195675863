.backdrop {
  width: 100%;
  height: 100%;
  position: relative;
  gap: 1.2vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2vh 2vh 0vh 2vh;
  box-sizing: border-box;
  background-position: 50% 50%;
  background-size: cover;
  background-image: linear-gradient(110deg, #001c26 118%, #003242 348%);
}


.manualbackdrop{
  width: 100%;
  height: 100%;
  position: relative;
  gap: 1.2vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2vh 2vh 0vh 2vh;
  box-sizing: border-box;
  background-position: 50% 50%;
  background-size: cover;
  background-image: linear-gradient(110deg, #690b01 118%, #003242 348%);

}
/*   
  .container {
    
    } */
.TopSection {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-self: stretch; */
  align-items: flex-end;
  gap: 1vh;
  /* margin: 0px 0px 0.8vh 0px; */

  box-sizing: border-box;
}

.buttonOut {
  position: relative;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: flex-end;
  box-sizing: border-box;
  background-size: cover;
  padding: 0vh 1vh;
}

.xbutton {
  min-width: 0px;
  min-height: 0px;
  position: relative;
  box-sizing: border-box;
  border: 0px;
  color: #ffffff;
  width: 2.5vh;
}

.MiddleSection {
  width: 100%;
  position: relative;
  gap: 1.2vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* margin: 0px 0px 20px 0px; */
  /* padding: 12px 210px; */
  padding: 2vh;
  border-radius: 12px;
  box-sizing: border-box;
  background-position: 50% 50%;
  background-size: cover;
  background-image: linear-gradient(245deg, #29383d -83%, #28373c 83%);
}

.ImageContainer {
  width: 5vw;
  position: relative;
  margin: 0px 0px 0px 0px;
  box-sizing: border-box;
}

.Image {
  width: 5vw;
  min-width: 0px;
  min-height: 0px;
  position: relative;
  box-sizing: border-box;
}

.title {
  position: relative;
  align-self: stretch;
  color: #ffffff;
  font-size: 2.4vh;
  font-weight: 600;
  font-family: Zilla Slab;
  line-height: 2vh;
  white-space: break-spaces;
  box-sizing: border-box;
  text-align: center;
}

.outherName {
  position: relative;
  justify-content: flex-start;
  color: #ffffff;
  font-family: Zilla Slab;
  line-height: 2vh;
  white-space: nowrap;
  box-sizing: border-box;
}

.outher {
  display: contents;
  color: #ffffff;
  font-weight: 600;
  font-family: Zilla Slab;
  line-height: 2vh;
  white-space: pre-wrap;
}

.subMiddleSection {
  width: 100%;
  position: relative;
  gap: 1.2vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  padding: 1vh;

  box-sizing: border-box;
}

.ConfirmationTitle {
  width: 92.77%;
  position: relative;
  color: #0a0a09;
  font-size: 3.2vh;
  font-weight: 600;
  font-family: Zilla Slab;
  line-height: 4vh;
  text-align: center;
  box-sizing: border-box;
  color: #ffffff;
}

.ConfirmationSubtitle {
  width: 92.77%;
  position: relative;
  color: #0a0a09;
  font-size: 1.8vh;
  font-family: Lato;
  line-height: 2.4vh;
  text-align: center;
  box-sizing: border-box;
  color: #ffffff;
}

.EmailBody {
  width: 100%;
  position: relative;
  gap: 1.6vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 1vh;
  /* padding: 1.5vh; */

  box-sizing: border-box;
}

.EmailGreeting {
  position: relative;
  color: #3c4d52;
  font-family: Lato;
  line-height: 2vh;
  white-space: pre-wrap;
  box-sizing: border-box;
  color: white;
}

.EmailText {
  width: 100%;
  position: relative;
  color: white;
  font-family: Lato;
  font-size: 1.7vh;
  line-height: 2vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1.2vh;
}
.EditableRejectText {
  padding-right: 10px;
  width: 100%;
  height: 20vh;
  position: relative;
  color: white;
  font-family: Lato;
  font-size: 1.7vh;
  line-height: 2vh;
  box-sizing: border-box;
  background: transparent;
  border-width: 1px;
  border-radius: 12px;
  border-style: solid;
  border-color: #71858b;
  padding: 1.5vh;
  overflow: auto; /* Ensures scrollbar is only shown when needed */
  box-sizing: border-box;
}

.EditablesubjectText {
  padding-right: 10px;
  width: 100%;
  position: relative;
  color: white;
  font-family: Lato;
  font-size: 1.7vh;
  line-height: 2vh;
  box-sizing: border-box;
  background: transparent;
  border-width: 1px;
  border-radius: 12px;
  border-style: solid;
  border-color: #71858b;
  padding: 1.5vh;
  overflow: auto; /* Ensures scrollbar is only shown when needed */
  box-sizing: border-box;
}

/* Styling the scrollbar for Webkit browsers */
.EditableRejectText::-webkit-scrollbar {
  border: 4px solid transparent;
  border-radius: 12px;
  width: 2px;
}

.EditableRejectText::-webkit-scrollbar-track {
  /* background:white;  */
  border-radius: 12px; /* Match the border-radius of the textarea */
  background: transparent;
}

.EditableRejectText::-webkit-scrollbar-thumb {
  background: #1ce99f; /* Color of the scrollbar thumb */
  border-radius: 12px; /* Match the border-radius of the textarea */
  background-clip: padding-box;
}

.EditableRejectText::-webkit-scrollbar-thumb:hover {
  background: #1ce99f; /* Color when hovering over the scrollbar thumb */
}

/* Styling the scrollbar for Webkit browsers */
.EditablesubjectText::-webkit-scrollbar {
  border: 4px solid transparent;
  border-radius: 12px;
  width: 2px;
}

.EditablesubjectText::-webkit-scrollbar-track {
  /* background:white;  */
  border-radius: 12px; /* Match the border-radius of the textarea */
  background: transparent;
}

.EditablesubjectText::-webkit-scrollbar-thumb {
  background: #1ce99f; /* Color of the scrollbar thumb */
  border-radius: 12px; /* Match the border-radius of the textarea */
  background-clip: padding-box;
}

.EditablesubjectText::-webkit-scrollbar-thumb:hover {
  background: #1ce99f; /* Color when hovering over the scrollbar thumb */
}
.EmailClosing {
  position: relative;
  color: white;
  font-family: Lato;
  line-height: 2vh;
  white-space: pre-wrap;
  box-sizing: border-box;
}

.ButtonSection {
  width: 100%;
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 2vh;
  padding: 1.5vh 0vh 1.5vh 0vh;
  border-width: 0px;

  box-sizing: border-box;
}

.YesButtonContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 1.2vh 1.6vh 1.2vh 1.6vh;
  border-width: 2px;
  border-radius: 14px;
  border-style: solid;
  border-color: #f45370;
  box-sizing: border-box;
}

.YesButtonContainer:hover {
  opacity: 0.8;
  cursor: pointer;
}

.yesButton {
  position: relative;
  color: white;
  font-size: 2vh;
  font-weight: 600;
  font-family: Lato;
  line-height: 2.4vh;
  text-align: center;
  white-space: pre-wrap;
  box-sizing: border-box;
}

.NoButtonContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.2vh 1.6vh 1.2vh 1.6vh;
  border-radius: 14px;
  box-sizing: border-box;
  background-color: #1ce99f;
}

.NoButtonContainer:hover {
  opacity: 0.8;
  cursor: pointer;
}

.noButton {
  position: relative;
  color: #0a0a09;
  font-size: 2vh;
  font-weight: 600;
  font-family: Lato;
  line-height: 2.4vh;
  text-align: center;
  white-space: nowrap;
  box-sizing: border-box;
}

.enable_auto {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  /* gap: 1vw; */
  margin-top: 0.5vh;
}

.label_enable_auto {
  color: white;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Inter';
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 133.333% */
  letter-spacing: -0.5px;
}

.buttonDownload {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  gap: 8px;

  background: #ffffff;

  border: 2px solid #bac6ca;
  border-radius: 12px;
  /* min-width: 10vw; */
}

.authoremail {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1vw;
  margin-top: 0.5vh;
  width: 100%;
}

.EditablemailText {
  padding-right: 10px;
  width: 100%;
  /* height: 3vh; */
  position: relative;
  color: white;
  font-family: Lato;
  /* font-size: 1.3vh; */
  box-sizing: border-box;
  background: transparent;
  border-width: 1px;
  border-radius: 12px;
  border-style: solid;
  border-color: #71858b;
  padding: 1.5vh;
  overflow: auto; /* Ensures scrollbar is only shown when needed */
  box-sizing: border-box;
  font-weight: 500;
}

.toSubtitle {
  position: relative;
  font-size: 1.8vh;
  font-family: Lato;
  line-height: 2.4vh;
  text-align: center;
  box-sizing: border-box;
  color: #ffffff;
}

.scoreContainer {
    display: inline-flex;
    padding: 0.25vh 0.45vh 0.25vh 0.45vh;
    justify-content: center;
    align-items: center;
    color: var(--Storywise-White, #FFF);
    font-family: Zilla Slab;
    font-size: 1.9vh;
    font-style: normal;
    font-weight: 900;
    line-height: 2vh;
    /* 133.333% */
    text-transform: capitalize;
    /* color: #a2c31e; */
    gap: 4px;
}
.userMContainer{
    max-width: 60%;
    align-self: flex-end;
    display: flex;
padding: 16px;
flex-direction: column;
align-items: flex-start;
gap: 10px;
border-radius: 20px 6px 20px 20px;
background: var(--storywise-secondary-500, rgb(28, 234, 160,1));
}

.userMText{
    color: var(--storywise-vampire-black, #0A0B09);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */ 
}
.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 24px;
    gap: 40px;
    position: relative;
    z-index: 100;
    /* width: 1920px;
    height: 74px;
    left: 0px;
    top: 56px; */


}
.dropdown{
    min-width: 20%;
}

.singleValue {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 3px;
    padding-left: 1px;
    gap: 10px;
    /* min-width: 10%  ;
    max-width: 10%; */
    width: 100%;

    box-sizing: border-box;
    background-color: #f6f7fa;
}

.customOption {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 6px;
    padding-left: 6px;
    gap: 10px;
    /* min-width: 10%  ;
    max-width: 10%; */
    width: 100%;

    box-sizing: border-box;
}

.customOption:hover {
    background-color: #f6f7fa;
}

.selectedOptionImage {
    width: 24px;
    min-width: 0px;
    min-height: 0px;
    position: relative;
    flex-shrink: 0;
    box-sizing: border-box;
}

.optionImage {
    width: 24px;
    min-width: 0px;
    min-height: 0px;
    position: relative;
    flex-shrink: 0;
    box-sizing: border-box;
}

.selectedOptionLabel {

    position: relative;
    font-size: 24px;
    font-weight: 500;
    font-family: Zilla Slab;
    line-height: 20px;
    /* white-space: pre-wrap; */
    box-sizing: border-box;
}

.optionLabel {

    position: relative;
    font-size: 20px;
    font-weight: 500;
    font-family: Zilla Slab;
    line-height: 20px;
    /* white-space: pre-wrap; */
    box-sizing: border-box;
}

.navbar{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0px;
    position: relative;
    /* width: 1920px;
    height: 74px;
    left: 0px;
    top: 56px; */
}


.navLink {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px 16px;
    gap: 8px;
    text-decoration: none;

}

.navLink:hover {
    background-color: #f6f7fa;
    border-bottom: 4px solid #1CEAA0;
    cursor: pointer;
}

.activeNavLink {
    border-bottom: 4px solid #1CEAA0;
}



.navIcon {
    width: 20px;
    height: 20px;
    left: 0px;
    top: 0px;
}

.navIconText {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height, or 133% */


    /* Storywise/Outer Space */

    color: #3D4E53;

}

.infoBookProgContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px;
  width: 100%;
  z-index: 99; /* Set a higher z-index value */

}

.infoBookProgContainer:hover {
  cursor: pointer;
  opacity: 0.8;
}


.descriptionBox {
  position: absolute;
  top: 0%;
  right: 0%;
  width: 100%;
  max-height: 150%;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  visibility: hidden;
  z-index:9999; /* Set a higher z-index value */
  overflow-x: auto;
  /* Other styles... */
}

.infoBookProgContainer:hover .descriptionBox {
  opacity: 1;
  visibility: visible;
  overflow: auto; 
}

.descriptionText {
  color: rgb(18, 17, 17);
  
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
  text-align: left;
  margin: 0;
  padding: 0;
  font-family: 'Lato';
  word-wrap: break-word;
  width: 100%;
  height: 100%;
}

.infoBookProgContainer:hover .descriptionBox {
  opacity: 1;
  visibility: visible;
}



.cardContainer {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-items: center;
  /* padding: 10px 10px 10px 10px; */
  border-radius: 12px;
  box-sizing: border-box;
  background-color: #ffffff;
  color: black;
  overflow-x: auto;
  padding: 1vh 1vw 1vh 1vw;
  gap: 2vh;

  
  

}

.cardContainer:hover {
  background-color: #f2f2f2;
}

.machPercent {
  position: relative;
  display: flex;
  /* flex: 1; */
  flex-shrink: 0;
  justify-content: flex-start;
  align-items: center;
  margin: 0px 0px 0px 0px;
  /* padding: 15px 15px 15px 15px; */
  box-sizing: border-box;

}
.cardInfo {
  /* flex:3; */
  position: relative;
  gap: 2px;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
}


.cardTitle{
  position: relative;
  align-self: stretch;
  font-size: 2vh;
  font-weight: 700;
  font-family: 'Zilla Slab';
  /* line-height: 28px; */
  box-sizing: border-box;
}

.grade{
  position: relative;
  color: #0a0a09;
  font-size: 1.4vh;
  font-weight: 600;
  font-family: 'Lato';
  /* line-height: 24px; */
  white-space: nowrap;
  box-sizing: border-box;
}

.like{
  position: relative;
  width: 40px;
  padding: 8px;
}
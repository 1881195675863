.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 500;
  border-radius: 26.003px 26.003px 0px 0px;
  box-sizing: border-box;
  background: linear-gradient(
    110.45deg,
    rgba(0, 29, 38, 0.4) 43.18%,
    rgba(1, 51, 67, 0.4) 110.25%
  );
  backdrop-filter: blur(3px);
}

.containerBox {
  position: absolute;
  width: 30vw;
  height: 60vh;
  right: 5vw;
  bottom: 0;

  background: #ffffff;
  border-radius: 26.003px 26.003px 0px 0px;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.08);
}

.all {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  overflow: hidden;
}

.header {
  width: 100%;
  border-radius: 26.003px 26.003px 0px 0px;
  background: var(
    --storywise-header-bg,
    linear-gradient(110deg, #001d26 43.18%, #013343 110.25%)
  );
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1.2vh;
  padding: 1.7vh 1.8vw 1.7vh 1.8vw;

  height: 7vh;
  box-sizing: border-box;
}

.headerContent {
  font-family: 'Zilla Slab';
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  color: #ffffff;
}

.headerButonsClose {
  align-self: center;
  cursor: pointer;
}

.headerButonsResize {
  align-self: center;
  cursor: nwse-resize;
}

.icon {
  width: 3vh;
  height: 3.5vh;
  align-self: center;
}

.headerButons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  box-sizing: border-box;
}

.headerButonsResize {
  align-self: center;
}

.bodyContentBodyContentfrom {
  border-width: 0 0 2px 0;
  border-style: none none solid none;
  box-sizing: border-box;
  margin: 0vh 1vw 0vh 1vw;
  height: auto; /* Allow height to adjust dynamically */
  justify-self: flex-end;
  display: flex;
  flex-direction: row;
  align-items: flex-start; /* Align content at the start */
  flex-wrap: wrap; /* Enable wrapping for content */
  overflow-wrap: break-word; /* Ensure words wrap when necessary */
  word-wrap: break-word; /* Compatibility with older browsers */
  width: 95%;
  padding: 5px 0;
}

.bodyContentBodyContentfromtitel {
  font-family: 'Inter';
  font-size: 2vh;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0px;
  padding: 0vh 0.5vw 0vh 0.5vw;
  box-sizing: border-box;
  color: black;
  gap: 1vh;
  align-items: flex-end;
  overflow-x: auto;
  white-space: nowrap;
}

.bodyContentBodyContentSubject {
  border-width: 0 0 2px 0;
  border-style: none none solid none;
  /* border-color: #1CEAA0; */
  margin: 0px;
  padding: 0px;
  padding: 1.9vh 1vh 0.5vh 1.5vh;
  margin: 0vh 1vw 0vh 1vw;
  box-sizing: border-box;
  height: 7vh;
}

.bodyContentBodyContentSubjecttitel {
  font-family: 'Inter';
  font-size: 2vh;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0px;
  padding: 0vh 0.5vw 0vh 0.5vw;
  box-sizing: border-box;
  color: black;
  display: block; /* Allow content to flow vertically */
  overflow-wrap: break-word; /* Break long words */
  word-wrap: break-word; /* Ensure older browser support */
  white-space: normal; /* Allow text to wrap naturally */
}

/* .bodyContentBodyContentSubjecttitel::-webkit-scrollbar {
    width: 2px;
    color: #1CEAA0;
}

.bodyContentBodyContentSubjecttitel::-webkit-scrollbar-thumb {
    margin-top: 1vh;
    width: 2px;
  
    border-radius: 2px;
}

.bodyContentBodyContentSubjecttitel::-webkit-scrollbar-track {
    margin-top: 1vh;
    width: 2px;
    border-radius: 2px;
} */

.From {
  font-family: 'Inter';
  font-size: 2vh;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  color: black;
}

.bodyContentBodyContentBody {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0px;
  padding: 1vh;
  overflow-y: auto;
  word-wrap: break-word;
  box-sizing: border-box;
  height: 36vh;
  gap: 1vh;
}

.bodybuttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  /* Adjust as needed */
  align-items: center;
  gap: 1vh;
  /* padding: 1.2vh 1.2vh 1vh 1.2vh; */
  box-sizing: border-box;
  flex-wrap: wrap;
  /* Allows items to wrap to a new line */
  cursor: pointer;
  /* new line if end */
}

.bodybutton {
  border-radius: 13.002px;
  background: var(--storywise-secondary-500, #1ceaa0);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.5vw;
  padding: 1vh;
}

.bodybutton1 {
  color: #fff;
  font-family: 'Lato';
  font-size: 2.5vh;
  font-style: normal;
  font-weight: 500;
}

.bodyContentBodyContentBody::-webkit-scrollbar {
  width: 2px;
  /* Adjust the width as needed */
  color: #1ceaa0;
  /* Green color for the track */
}

/* Set the color of the scrollbar thumb (the draggable part) */
/* You can adjust the background-color to your preferred white shade */
.bodyContentBodyContentBody::-webkit-scrollbar-thumb {
  background-color: #1ceaa0;
  /* White color for the thumb */
  border-radius: 2px;
  /* Round the corners of the thumb */
}

.bodytext {
  position: relative;
  box-sizing: border-box;
  font-family: 'Inter';
  padding: 1vh 1vh 0.5vh 1.5vh;
  font-size: 1.7vh;
  box-sizing: border-box;
}

.bodytexttitel {
  font-family: 'Inter';
  position: relative;
  box-sizing: border-box;
  white-space: pre-wrap; /* Preserve whitespaces and line breaks */
}

.spinnerContainer {
  position: absolute; /* Ensure the spinner is positioned above the content */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.1); /* To darken the background */
  z-index: 300; /* Ensure this is above other content */
  border-radius: 26.003px 26.003px 0px 0px;
  box-sizing: border-box;
}

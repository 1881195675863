.userMContainer {
    display: flex;
    max-width: 60%;
    padding: 16px 20px 16px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    border-radius: 6px 20px 20px 20px;
    background: var(--storywise-outer-space, #3D4E53);
}

.userMText {
    color: var(--storywise-white, #FFF);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* 142.857% */
    /* 142.857% */
}
.container {
  height: 100%;
  width: 100%;
  /* width: 78.3vw; */
  align-self: flex-start;
  /* position: fixed; */
  /* right: 0px;
  top: 0px; */
  box-sizing: border-box;
  overflow: auto;
}

.logosignincontainer {
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.signcontainer {
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 2vh 3vw 2vh 3vw;
}

.hedder {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.signup {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5vw;
}

.backtologin {
  color: #000;
  font-family: 'Lato';
  font-size: 1.6vh;
  font-style: normal;
  font-weight: 500;
  line-height: 2vh;
  /* 125% */
}

.linkto {
  color: #2f80ed;
  font-family: 'Lato';
  font-size: 1.6vh;
  font-style: normal;
  font-weight: 600;
  line-height: 2vh;
  /* 125% */
  text-decoration-line: underline;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2.2vh;
  width: 100%;
  /* padding-top: 4.5vh; */
}

.titlelogo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  /* gap: 0.5vw; */
}

.loginparttitle {
  display: flex;
  color: #0a0b09;
  text-align: left;
  font-family: 'Zilla Slab';
  font-size: 5vh;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.loginparttitlepart2 {
  display: flex;
  color: #0a0b09;
  text-align: left;
  font-family: 'Zilla Slab';
  font-size: 5vh;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.loginpartsubtitle {
  background: linear-gradient(180deg, #001d26 0%, #013343 100%);
  background-clip: text;
  -webkit-background-clip: text;
  font-family: 'Lato';
  font-size: 1.8vh;
  font-style: normal;
  font-weight: 500;
  line-height: 2.4vh;
  margin-bottom: 2vh;
}

.linktomail {
  color: #2f80ed;
  font-family: 'Lato';
  font-size: 1.8vh;
  font-style: normal;
  font-weight: 500;
  line-height: 2.4vh;
  text-decoration: none;
}

.genres_container {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  gap: 2vh;
  flex-wrap: wrap;
}

.titles {
  color: var(--storywise-vampire-black, #0a0b09);
  font-family: 'Zilla Slab';
  font-size: 2.3vh;
  font-style: normal;
  font-weight: 700;
  line-height: 2.8vh; /* 140% */
  white-space: nowrap;
  margin-top: 2.5vh;
}

.description {
  color: var(--storywise-vampire-black, #0a0b09);
  font-family: 'Zilla Slab';
  font-size: 1.8vh;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 1.5vh;
}

.maindescription {
  color: var(--storywise-vampire-black, #0a0b09);
  font-family: 'Zilla Slab';
  font-size: 2.4vh;
  font-style: normal;
  font-weight: 700;
  /* margin-bottom: 0.5vh; */
}
.wordCountMin {
  margin-top: 2.5vh;
}

.titlesword {
  color: var(--storywise-vampire-black, #0a0b09);
  font-family: 'Zilla Slab';
  font-size: 2.3vh;
  font-style: normal;
  font-weight: 700;
  line-height: 2.8vh; /* 140% */
  white-space: nowrap;
}

.fotter {
  /* height: 0px; */
  width: 100%;
  border-top: 1px solid #dce0e5;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 1vw;
  padding-top: 1vh;
  margin-top: 3vh;
  padding-bottom: 1vh;
}

.buttoncontainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1vw;
}

.buttoncontainerback {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-direction: row;
  cursor: pointer;
}

.button {
  display: flex;
  padding: 0.8vh 1.6vh;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 14px;
  background: var(--storywise-secondary-500, #1ceaa0);
  cursor: pointer;
  min-width: 10vw;
}

.textbutton {
  color: var(--storywise-vampire-black, #0a0b09);
  text-align: center;
  font-family: 'Lato';
  font-size: 2vh;
  font-style: normal;
  font-weight: 600;
  line-height: 2.4vh;
  /* 120% */
}

.pagenumber {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  color: var(--sass-gray-300, #95a1b2);
  font-family: 'Zilla Slab';
  font-size: 1.2vh;
  font-style: normal;
  font-weight: 700;
}

.Select {
  width: 100%;
  height: 100%;
  border: 0px;
  border-radius: 16px;
}
.Select:focus {
  outline: none;
  border: 1px solid #0a0b09;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.selectrow {
  width: 10vw;
}

.input:focus {
  outline: none;
  border: 1px solid var(--storywise-secondary-500, #1ceaa0);
}

.pagenumberfirst {
  color: var(--sass-gray-700, #454f5f);
  font-family: 'Zilla Slab';
  font-size: 1.6vh;
  font-style: normal;
  font-weight: 500;
}

.tooltip1 {
  /* do no display , only on hover categoriesCard1  */
  display: none;
}

.tooltip2 {
  /* do no display , only on hover categoriesCard2  */
  display: none;
}

.tooltip3 {
  /* do no display , only on hover categoriesCard3  */
  display: none;
}

.tooltipText {
  color: #000;
  font-family: 'Lato';
  font-size: 1.6vh;
  font-style: normal;
  font-weight: 500;
  line-height: 2vh;
}

.Qualification_Categories {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  /* gap: 1vw; */
  flex-wrap: wrap;
}

.categoriesCard1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1vw;
  /* flex-wrap: wrap; */
}

.cat_title {
  display: flex;
  color: #0a0b09;
  text-align: left;
  font-family: 'Zilla Slab';
  font-size: 3vh;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.category_content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1.5vh;
  margin-top: 1.5vh;
}

.accordionSummary {
  font-size: 20px;
  font-weight: 600;
  font-family: 'Zilla Slab';
  overflow: hidden;
}

.genreImage {
  width: 48px;
  height: 60px;
  border-radius: 3.6px;
}
.summaryContainer {
  flex: 1;
  align-items: center;
  display: flex;
}
.genreName {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-left: 15px;
  white-space: nowrap;
}

.selectedKeywordWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;
}

.selectedKeyword {
  padding: 10px 12px;
  margin: 4px;
  color: #ffffff;
  background-color: #000000;
  border-radius: 10px;
  font-family: 'Lato';
  font-size: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.deleteKeywordImage {
  margin-left: 5px;
}

.wishlistParagraphWrapper {
  width: 100%;
  margin: 0 20px;
}

.wishlistParagraph {
  width: 100%;
}

.saveAndCloseButtonContainer {
  width: 100%;
  display: flex;
  justify-content: end;
  gap: 5px;
}

.subHeading {
  color: var(--storywise-vampire-black, #0a0b09);
  font-family: 'Zilla Slab';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  white-space: nowrap;
  padding: 10px 0;
}

.noneText {
  font-family: 'Lato';
  font-size: 16px;
  font-style: italic;
  color: #a2a4a1;
  margin: 0 5px 10px;
}

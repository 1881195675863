.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 500;
  background: rgba(0, 0, 0, 0.75);
}


.container {

  position: fixed;
  border-radius: 12px;
  box-sizing: border-box;
  background-color: #ffffff;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.08);
  top: 10%;
  left: 30%;
  width: 40%;
  height: 80%;
  z-index: 100;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  }
  .TopSection {
    width: 100%;
    height: 10%;
    position: relative;
    display: flex;
    gap: 25px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* margin: 0px 0px 8px 0px; */
    padding: 0px 20.5px 0px 16px;
    border-width: 0px 0px 1px 0px;
    border-style: solid;
    border-color: #dddee0;
    box-sizing: border-box;
  }
  .titleTEXT {
    position: relative;
    margin: 1px 0px 0px 0px;
    color: #0a0a09;
    font-size: 32px;
    font-weight: 700;
    font-family: Zilla Slab;
    line-height: 40px;
    white-space: nowrap;
    box-sizing: border-box;
  }
  .buttonOut {
    width: 15.1px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;
    background-size: cover;
  }
  .xbutton {
    width: 15.1px;
    min-width: 0px;
    min-height: 0px;
    position: relative;
    box-sizing: border-box;
    background-color: white;
    border: 0px;
  }
  .MiddleSectionall{
    width: 100%;
    height: 90%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 0px 0px 0px 0px;
    box-sizing: border-box;
    overflow-y: auto;


  }

  .Middle{
    padding: 20px 20px 0px 20px;
    display: flex;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 0px 0px 0px 0px;
    box-sizing: border-box;



  }
  .MiddleSection {
    width: 100%;
    position: relative;
    gap: 12px;
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    padding: 1vh;
    border-radius: 12px;
    box-sizing: border-box;
    background-color: #eaf1f4;
  }
  .ImageContainer {
    min-width: 0px;
    min-height: 0px;
    position: relative;
    margin: 0px 0px 0px 0px;
    box-sizing: border-box;
  }

  .Image{
    width: 80px;
    position: relative;
    box-sizing: border-box;
    background-size: cover;
  }
  .title {
    position: relative;
    color: #0a0a09;
    font-size: 24px;
    font-weight: 600;
    font-family: Zilla Slab;
    line-height: 20px;
    white-space: break-spaces;
    box-sizing: border-box;
    text-align: center;
  }
  .outherName {
    position: relative;
    justify-content: flex-start;
    color: #0a0a09;
    font-family: Zilla Slab;
    line-height: 20px;
    white-space: nowrap;
    box-sizing: border-box;
  }
  .outher {
    display: contents;
    color: #0a0a09;
    font-weight: 600;
    font-family: Zilla Slab;
    line-height: 20px;
    white-space: pre-wrap;
  }
  .ConfirmationTitle {
    position: relative;
    color: #0a0a09;
    font-size: 2rem;
    font-weight: 600;
    font-family: Zilla Slab;
    line-height: 40px;
    text-align: center;
    box-sizing: border-box;
    margin-bottom: 10px;
  }
  .ConfirmationSubtitle {
    position: relative;
    margin: 0px 0px 0px 0px;
    color: #0a0a09;
    font-size: 1rem;
    font-family: Lato;
    line-height: 24px;
    text-align: center;
    box-sizing: border-box;
  }
  .EmailBody {
    width: 96%;
    position: relative;
    gap: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 10px;
    border-width: 1px;
    border-radius: 12px;
    border-style: solid;
    border-color: #71858b;
    box-sizing: border-box;
  }
  .EmailGreeting {
    position: relative;
    color: #3c4d52;
    font-family: Lato;
    line-height: 1.3rem;
    white-space: pre-wrap;
    box-sizing: border-box;
  }
  .EmailText {
    width: 100%;
    position: relative;
    align-self: stretch;
    color: #3c4d52;
    font-family: Lato;
    line-height: 16px;
    font-size :  1rem;
    box-sizing: border-box;
  }

  .EmailClosing
  {
    position: relative;
    color: #3c4d52;
    font-family: Lato;
    line-height: 16px;
    white-space: pre-wrap;
    box-sizing: border-box;
  }
  .ButtonSection {
    width: 100%;
    height: 13%;
    position: relative;
    gap: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 15px 16px;
    border-width: 0px;
    border-top-width: 1px;
    border-right-width: 0px;
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-style: solid;
    border-color: #dddee0;
    box-sizing: border-box;
    background: white;
    align-self: flex-end;
    justify-self: flex-end;
    margin-top: 5%;
  }
 
  .YesButtonContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0px 0px 1px 0px;
    padding: 10px 18.5px;
    border-width: 2px;
    border-radius: 14px;
    border-style: solid;
    border-color: #f45370;
    box-sizing: border-box;
  }

  .YesButtonContainer:hover {
    opacity:0.8 ;
    cursor: pointer;
  
  }
  .NoButtonContainer:hover {
    opacity:0.8 ;
    cursor: pointer;
  
  }
  .yesButton {
    position: relative;
    color: #0a0a09;
    font-size: 1.2rem;
    font-weight: 600;
    font-family: Lato;
    line-height: 24px;
    text-align: center;
    white-space: nowrap;
    box-sizing: border-box;
  }
  .NoButtonContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 12px 16px;
    border-radius: 14px;
    box-sizing: border-box;
    background-color: #1ce99f;
  }
  .noButton {
    position: relative;
    color: #0a0a09;
    font-size: 1.2rem;
    font-weight: 600;
    font-family: Lato;
    line-height: 24px;
    text-align: center;
    white-space: nowrap;
    box-sizing: border-box;
  }
  
  .enable_auto {
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    justify-content: flex-start;
    /* gap: 1vw; */
    /* margin-top: 1vh; */
    margin-bottom: 16px;
  }
  

.label_enable_auto{
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Inter';
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 133.333% */
  letter-spacing: -0.5px;
}


.authoremail{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1vw;
  width: 96%;
  margin-bottom: 1vh;
}

.EmailBodymail {
  position: relative;
  gap: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px;
  border-width: 1px;
  border-radius: 12px;
  border-style: solid;
  border-color: #71858b;
  box-sizing: border-box;
  width: 100%;
  position: relative;
  align-self: stretch;
  color: #3c4d52;
  font-family: Lato;
  line-height: 16px;
  font-size :  1rem;
  box-sizing: border-box;
  background: transparent;
}



.EmailBodymail:hover {
  cursor: pointer;
  background-color: #eaf1f4;
}

.EmailBodymail:active {
  cursor: pointer;
  background-color: #eaf1f4;
}

.EmailBodymail:focus {
  cursor: pointer;
  background-color: transparent; /* Changed from #eaf1f4 to transparent */
}


.EmailBodymail:visited {
  cursor: pointer;
  background-color: #eaf1f4;
}


.EditableRejectText{
  width: 100%;
  position: relative;
  gap: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px;
  border-width: 1px;
  border-radius: 12px;
  border-style: solid;
  border-color: #71858b;
  box-sizing: border-box;
  position: relative;
  gap: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px;
  border-width: 1px;
  border-radius: 12px;
  border-style: solid;
  border-color: #71858b;
  box-sizing: border-box;
  width: 98%;
  position: relative;
  align-self: stretch;
  color: #3c4d52;
  font-family: Lato;
  line-height: 16px;
  font-size :  1rem;
  box-sizing: border-box;
  background: transparent;
  height: 20vh;

}
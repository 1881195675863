.dots {
  padding: 2px;
  box-sizing: border-box;
  width: 100%;
  height: 24px;
  border-radius: 6px;
  box-sizing: border-box;

  cursor: pointer;
}

.edit {
  /* padding: 8px 30px;
    gap: 10px; */
  margin-right: 30px;

  border-bottom: 1px solid #dddee0;
}

/* TableManuscripts.module.css */

.customToolbar {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #3d4e53;
  background-color: #fff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.18);
  border-radius: 8px;
}

.customToolbarButton {
  color: #3d4e53;
  padding: 6px;
}

.customToolbarButton:hover {
  background-color: transparent;
}

.containereye {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  /* background-color: aqua; */
  cursor: pointer;
  padding: 0px;
  margin: 0px;
  z-index: 1;
  box-sizing: border-box;
}

.containereyeimg {
  width: 1.7em;
  /* height: ; */
}

.processing {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
  font-family: 'Lato', sans-serif;
}

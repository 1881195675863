.submissionsPageContainer {
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  /* overflow: hidden; */
  overflow: auto;
}

.submissionsPageC {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0px 0px 0px 0px;
  box-sizing: border-box;
  background-color: #f7f7f7;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.18);
  overflow-y: hidden;
}

.navBar {
  width: 100%;
  /* height: 10vh; */
  box-shadow: 0px -3px 8px 0px rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
}

.successMessage {
  z-index: 1000;
  width: 100%;
  user-select: none;
}

.successMessageLeft {
  /* position: absolute; */
  margin-left: 25%;
}
.submissionsPage {
  width: 100vw;
  position: sticky;
  top: 0px;
  /* gap: 20px; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0px 0px 0px 0px;
  padding: 1vh 1vw 1vh 2vw;
  box-sizing: border-box;
  background: #ffffff;

  /* Storywise/Gainsboro */

  border-bottom: 1px solid #dddee0;
}

.submissionsPageTitle {
  position: relative;
  margin: 0px 0px 0px 0px;
  /* padding: 0px 100px 0px 0px; */
  color: #0a0a09;
  font-size: 1.5em;
  font-weight: 600;
  font-family: Zilla Slab;
  /* line-height: 40px; */
  white-space: nowrap;
  box-sizing: border-box;
}

.pageContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: #f7f7f7;
}

.leftSidebar {
  width: 350px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.18);
  overflow-y: hidden;
}

.buttonNewThread {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 5px;
  box-sizing: border-box;
  margin: 10px;
  font-weight: bold;
  border-radius: 8px;
  background: var(--storywise-secondary-500, #1ceaa0);
  cursor: pointer;
  color: #fff;
}

.allManuscripyPart2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 0.6vw;
  position: relative;
  /* Inside auto layout */
  box-sizing: border-box;
  order: 1;
}

.show {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 2px;

  background: #f0f4f5;
  border-radius: 12px;
}

.listicon {
  padding: 8px;
  gap: 10px;

  background: #0a0b09;
  border-radius: 8px;
  border: 0px;
}

.imggalery {
  position: relative;
  width: 20px;
  height: 20px;
  left: 0px;
  top: 0px;
}
.galeryicon {
  padding: 8px;
  gap: 10px;

  background: #f0f4f5;
  border-radius: 8px;
  border: 0px;
}

.imggalery {
  position: relative;
  width: 20px;
  height: 20px;
  left: 0px;
  top: 0px;
}

.keyicon {
  box-sizing: border-box;
  padding: 5px;
  gap: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  background: #ffffff;
  /* Storywise/Jet Stream */

  border: 2px solid #bac6ca;
  border-radius: 12px;
  cursor: pointer;
}

.keyicon:hover {
  border: 2px solid #1ceaa0;
  border-radius: 12px;
}

.imgKey {
  position: relative;
  width: 20px;
  height: 20px;
  left: 0px;
  top: 0px;
}

.buttonDownload {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 10px;
  gap: 8px;

  background: #ffffff;

  border: 2px solid #bac6ca;
  border-radius: 12px;
  /* min-width: 10vw; */
}

.buttonDownload:hover {
  border: 2px solid #1ceaa0;
  border-radius: 12px;
}

.downimg {
  position: relative;
  width: 20px;
  height: 20px;
  left: 0px;
  top: 0px;
}

.dwomtext {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;

  display: flex;
  align-items: center;
  text-align: center;

  /* Storywise/Vampire Black */

  color: #0a0b09;
}

.add {
  padding: 5px 10px;
  gap: 8px;
  background: #1ceaa0;
  border-radius: 12px;
  cursor: copy;
}
.progress {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px 16px;
  gap: 2px;
  font-family: 'Lato';
  color: #0a0b09;
}
progress::-webkit-progress-value {
  background-color: #1ceaa0 !important;
}

.addText {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #0a0b09;
}

.tableManuscripts {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* justify-content: center;
    align-items: center; */
  /* padding: 20px; */
  /* gap: 20px; */
  /* width: 97%; */
  margin-left: 1%;
  margin-right: 1%;
  position: relative;

  background: #ffffff;
  /* Card/Subtle */

  box-shadow: 2px 2px 8px rgba(200, 200, 200, 0.12);
  border-radius: 12px;
  /* Didn't have time to figure out why this can't be 100%, but this worked. */
  height: 88%;
  margin-top: 2%;
  margin-bottom: 2%;
  /* margin: 20px; */
  box-sizing: border-box;
  overflow-y: auto;
  flex-grow: 1;
  font-family: 'Lato';
}

button:hover {
  opacity: 0.8;
  cursor: pointer;
}
.container {
  text-align: center;
  padding-left: 50px;
  padding-right: 50px;
  font-family: Arial, sans-serif;
}

.heading {
  font-size: 2rem;
  margin-bottom: 20px;
  font-family: 'Zilla Slab', serif;
}
.searchBoxContainer {
  position: relative; /* Ensures the button is positioned relative to the container */
  display: flex;
  justify-content: center;
  max-width: 600px;
  margin: 20px auto;
}

.searchBox {
  width: 100%;
  padding: 10px 50px 10px 15px; /* Added padding to the right to make room for the button */
  font-size: 1rem;
  border-radius: 8px;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.searchBox::placeholder {
  color: lightgray;
}

.buttonSearch {
  position: absolute;
  right: 10px; /* Aligns the button to the right side inside the input */
  top: 50%;
  transform: translateY(-60%); /* Centers the button vertically */
  padding: 5px 15px;
  border-radius: 8px;
  background: var(--storywise-secondary-500, #1ceaa0);
  cursor: pointer;
  font-size: 0.9rem;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonSearch:hover {
  opacity: 0.8;
}

.buttonSearch:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.suggestions-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  max-width: 600px;
  margin: 0 auto;
}

.suggestion-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  border: 1px solid #ccc;
  background-color: white;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease;
}

.suggestion-button:hover {
  background-color: #f1f1f1;
}

.answerContainer {
  text-align: left;
}

.threadsContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  gap: 10px;
  overflow-y: scroll;
}

.singleThread {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  transition: font-weight 0.3s;
  /* background-color: #c2c2c2; */
  border-radius: 5px;
  padding: 5px;
  padding: 10px;
  font-size: 14px;
  font-family: 'Lato';
  flex-shrink: 0;
  position: relative;
}

.activeThread {
  background-color: #c2c2c2;
  padding-right: 40px;
}

.singleThread:hover {
  font-weight: bold;
}

.deleteIcon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 16px;
  color: gray;
}

.popup {
  position: absolute;
  right: 10px;
  top: 30px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.popup button {
  margin: 0 5px;
}

.cancelDelete {
  color: gray;
  cursor: pointer;
}

.confirmDelete {
  background-color: red;
  cursor: pointer;
}

.spinnerIcon {
  position: absolute;
  right: 10px;
  transform: translateY(-50%);
  cursor: none;
  font-size: 16px;
  color: gray;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

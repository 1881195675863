.container {
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  /* gap: 10px; */
  flex-direction: column;
  justify-content: flex-start;
  padding: 0px 0px 0px 0px;
  background-color: #f7f7f7;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.18);
  overflow-y: hidden;
  box-sizing: border-box;
  overflow-x: hidden;
}

.hedder {
  height: 7%;
  width: 100%;
  position: relative;
  gap: 8px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 26.2px 8px 24px;
  box-sizing: border-box;
  background-position: 50% 50%;
  background-size: cover;
  background-image: linear-gradient(110deg, #001c26 118%, #003242 348%);
  box-sizing: border-box;
}

.firstPart {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
}

.hedderTitleImg {
  width: 41px;
  height: 35px;
  position: relative;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 0px 3.38px 0px 0px;
  /* padding: 8.57px 11.6px 14.2px 11.6px; */
  box-sizing: border-box;
}

.myManuscripts {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding: 4px 0px; */
  gap: 8px;

  /* Storywise/header btn bg */

  background: linear-gradient(132.64deg, #001d26 30.86%, #013343 93.29%);
  /* Storywise/secondary-500 */

  border: 1px solid #1ceaa0;
  border-radius: 8px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.myM {
  position: relative;
  width: 20px;
  height: 20px;
  left: 0px;
  top: 0px;
}

.totleManu {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  /* Storywise/White */

  color: #ffffff;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.hedderTitle {
  min-width: 0px;
  min-height: 0px;
  position: relative;
  margin: 0px 40px 0px 0px;
  box-sizing: border-box;
  font-family: zilla slab;
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
  line-height: 40px;
  white-space: nowrap;
  box-sizing: border-box;
}

.uploadContainer {
  position: absolute;
  left: 1.5%;
  top: 9%;
  width: 97%;
  /* right: 2%; */
  bottom: 2%;
  background: #ffffff;
  border-radius: 12px;
  /* gap: 20px; */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
  box-sizing: border-box;
}

.AddLaftContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 28px;
  /* gap: 60px; */
  background: #f9f9f9;
  border-radius: 20px;
  flex: none;
  order: 0;
  align-self: stretch;
  width: 22%;
}

.textPart {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 30px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.listPart {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.uploadAddText {
  /* position: relative;
    align-self: stretch;
    margin: 0px 0px 12px 0px;
    color: #0a0a09;
    font-size: 32px;
    font-weight: 700;
    font-family: Zilla Slab;
    line-height: 40px;
    white-space: nowrap;
    box-sizing: border-box; */

  font-family: 'Zilla Slab';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #0a0b09;
}

.Step {
  position: relative;
  gap: 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
}

.UploadSteps {
  width: 52px;
  height: 52px;

  position: relative;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  padding: 14px;
  border-radius: 800px;
  box-sizing: border-box;
  background-color: #0a0a09;
}

.Step1 {
  width: 100%;
  height: 100%;
  position: relative;
  color: #ffffff;
  font-size: 24px;
  font-weight: 700;
  font-family: Zilla Slab;
  line-height: 24px;
  text-align: center;
  box-sizing: border-box;
}

.UploadFilesSteps {
  font-family: 'Zilla Slab';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height, or 140% */

  color: #000000;
}

.UploadStepswasUploaded {
  width: 52px;
  height: 52px;

  position: relative;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  padding: 14px;
  border-radius: 800px;
  box-sizing: border-box;
  background-color: #0a0a09;
}

.Step1wasUploaded {
  width: 24px;
  height: 24px;
  left: 0px;
  top: 0px;
}

.ReviewStepswasUploaded {
  width: 52px;
  height: 52px;

  position: relative;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  padding: 14px;
  border-radius: 800px;
  box-sizing: border-box;
  background-color: #0a0a09;
}

.Step2wasUploaded {
  font-family: 'Zilla Slab';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  /* identical to box height, or 100% */

  text-align: center;

  /* Storywise/White */

  color: #ffffff;
}

.UploadFile-dropzone {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
  border: 2px dashed #ccc;
  background-color: #fff;
  color: #555;
  font-size: 16px;
  padding: 20px;
  cursor: pointer;
}

.ReviewSteps {
  width: 52px;
  height: 52px;

  position: relative;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  padding: 13px;
  border-width: 1px;
  border-radius: 800px;
  border-style: solid;
  border-color: #dddee0;
  box-sizing: border-box;
  background-color: #ffffff;
}

.Step2 {
  width: 100%;
  height: 100%;
  position: relative;
  color: #0a0a09;
  font-size: 24px;
  font-weight: 700;
  font-family: Zilla Slab;
  line-height: 24px;
  text-align: center;
  box-sizing: border-box;
}

.image_man {
  /* width: 199px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 15.8px 0px 0px 0px;
  box-sizing: border-box;  */

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  align-self: flex-start;
  padding: 0px 20px 0px 0px;
  gap: 8px;
  box-sizing: border-box;
  order: 1;
}

/* .image_man_src {
    width: 100%;
    height: 100%;
} */

.uploadPart {
  width: 78%;
  position: relative;
  box-sizing: border-box;
  max-height: 100%;
  overflow-y: auto;
}

.settingsButton {
  margin-left: 10px;
}

.FrameRootRoot {
    width: 100%;
    position: relative;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    /* padding: 20px 20px 20px 20px; */
    height: 100%;
    /* Set container height to viewport height */
    overflow-y: auto;
    box-sizing: border-box;
    background-color: #ffffff;
    box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.08);
}

.SubmissionNotes {
    width: 100%;
    position: relative;
    top: 0;
    font-size: 24px;
    font-weight: 600;
    font-family: Zilla Slab;
    line-height: 32px;
    padding: 20px 20px 20px 20px;
    z-index: 1;
    box-sizing: border-box;
}

.Frame1 {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; 
    margin: 0px; 
    padding: 0px; 
    padding: 0px 0px 188px 0px;
    box-sizing: border-box;
    background-color: #ffffff;
    
}

.Notes {
width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 5px;
  border-radius: 8px;
  box-sizing: border-box;
  /* background-color: #; */
}

.note{
   
    position: relative;
   margin: 10px;
    box-sizing: border-box;
    
}


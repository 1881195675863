.PageRoot {
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  /* overflow: hidden; */
  overflow: auto;
}

.reviewsPageTitle {
  height: 50px;
  width: 100%;
  color: #0a0a09;
  font-size: 1.5em;
  font-weight: 600;
  font-family: Zilla Slab;
  /* height: 10vh; */
  box-shadow: 0px -3px 8px 0px rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.ReviwePageRoot {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  background-color: #f7f7f7;
  overflow-y: hidden;
}

.linktoManuscripts {
  margin-right: 10px;
}

a,
a:visited {
  color: black;
  text-decoration: none;
}

.hedderTitle {
  min-width: 0px;
  min-height: 0px;
  position: relative;
  margin: 0px 40px 0px 0px;
  box-sizing: border-box;
  font-family: zilla slab;
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
  line-height: 40px;
  white-space: nowrap;
  box-sizing: border-box;
}

.allmenuscriptPart {
  width: 79.5vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  background-color: #f7f7f7;
  overflow: hidden;
  padding-top: 20px;
}

.UpperBar {
  width: 78vw;
  height: 14vh;
  gap: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: #f7f7f7;
  box-sizing: border-box;
  order: 0;
  /* margin-right: 20px;
    margin-left: 20px; */
  position: relative;
  z-index: 20;
}

.UpperStstusBar {
  width: 100%;
  height: 14vh;
  gap: 16px;
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  align-items: center;
  justify-content: space-between;
  background-color: #f7f7f7;
  box-sizing: border-box;
  order: 0;
  position: relative;
  z-index: 20;
}

.ScoreCard {
  /* width: 19.5%; */
  height: 80%;
  width: 15%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-items: center;
  /* padding: 10px 10px 10px 10px; */
  border-radius: 12px;
  box-sizing: border-box;
  background-color: #ffffff;
  color: black;
  overflow-x: auto;
  /* padding: 20px 25px 20px 25px; */
  gap: 1vh;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.08);
}

.scoreRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1vh;
  box-sizing: border-box;
  width: 100%;
}

.TitleScore {
  color: var(--Storywise-White, #fff);
  font-family: Zilla Slab;
  font-size: 1.8vh;
  font-style: normal;
  font-weight: 700;
  line-height: 2.4vh;
  /* 133.333% */
  text-transform: capitalize;
}

.parameterItems {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1.5vh;
  box-sizing: border-box;
  width: 100%;
}

.parameterItem {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1vh;
  box-sizing: border-box;
  width: 100%;
}

.itemTitle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0vw;
  align-items: center;
  cursor: pointer;
  border-radius: 6px;
  box-sizing: border-box;
}

.itemkeytitle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0.3vw;
  align-items: center;
  cursor: pointer;
  border-radius: 6px;
  box-sizing: border-box;
}

.itemkey {
  font-family: Lato;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 125% */
}

.scoreIcon {
  width: 4.8vh;
  height: 2.8vh;
}

.expandIcon {
  width: 2vh;
  height: 2vh;
  justify-self: flex-end;
  display: none;
}
.expandIconclose {
  width: 2vh;
  height: 2vh;
  justify-self: flex-end;
  align-self: flex-end;
}
.ScoreCard:hover .expandIcon {
  width: 2.2vh;
  height: 2.2vh;
  justify-self: flex-end;
  display: block;
  /* or inline, inline-block, etc., depending on your layout */
}

.ScoreCard:hover {
  border: 1px solid #1ceaa0;
  border-radius: 8px;

  /* or inline, inline-block, etc., depending on your layout */
}

.content {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2vh;
  box-sizing: border-box;
  padding: 0.5vh 0.4vw 0.5vh 0.8vw;

  box-sizing: border-box;
}

.subItem {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 0.5vh;
  box-sizing: border-box;
  width: 100%;
  align-self: flex-end;
}

.moreInfoTitle {
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  /* line-height: 20px; */
  align-self: flex-end;
  /* 125% */
}

.TextLogLine {
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  /* line-height: 20px; */
  align-self: flex-end;

  /* 142.857% */
}

.allmenuscript {
  width: 78vw;
  height: 98%;
  order: 1;
  position: relative;
  gap: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin-left: 20px;
  border-radius: 12px;
  box-sizing: border-box;
  background-color: #ffffff;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.08);
  z-index: 1;
}

.descriptionContainer {
  padding-left: 20px;
  width: 79vw;
  height: 10vh;
  gap: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: #f7f7f7;
  box-sizing: border-box;

  position: relative;
  z-index: 20;
}

.decriptionbox {
  /* width: 19.5%; */
  height: 90%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-items: center;
  /* padding: 10px 10px 10px 10px; */
  border-radius: 12px;
  box-sizing: border-box;
  background-color: #ffffff;
  color: black;
  overflow-x: auto;
  /* padding: 20px 25px 20px 25px; */
  gap: 1vh;
  padding: 1vw 1vh 1vw 1vh;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.08);
}

.descriptionTitle {
  font-family: zilla slab;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.descriptionTitlerow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1vh;
  box-sizing: border-box;
  width: 100%;
}

.NotesPart {
  width: 20vw;
  margin-left: 18px;
  box-sizing: border-box;
  background-color: #ffffff;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.08);
}

.successMessage {
  z-index: 1000;
  width: 100%;
  user-select: none;
}

.successMessageLeft {
  /* position: absolute; */
  margin-left: 25%;
}

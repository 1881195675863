.NotesSectionRoot {
  position: relative;
  gap: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 10px;
  border-radius: 12px;
  box-sizing: border-box;
  background-color: #ffffff;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.18);
}

.NoteSection {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  /* padding: 7px 11px 91px 11px; */
  border-width: 1px;
  border-radius: 8px;
  border-style: solid;
  border-color: #dddee0;
  box-sizing: border-box;
  overflow-y: auto;
}

.NoteTitle {
  position: relative;
  color: #002734;
  font-size: 14px;
  font-weight: 500;
  font-family: Lato;
  line-height: 20px;
  white-space: pre-wrap;
  box-sizing: border-box;
  border: none;
  overflow-wrap: break-word;
  word-wrap: break-word;
  min-height: 130px;
  min-width: 260px;
  overflow-y: auto;
}

.NoteTitle:focus {
  outline: none;
}

.ButtonsSection {
  position: relative;
  gap: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  border-width: 0px;
  box-sizing: border-box;
  background: none;
  cursor: pointer;
}

.CancelButtonContainer {
  position: relative;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: flex-start;
  padding: 2px 6px;
  border-width: 2px;
  border-radius: 8px;
  border-style: solid;
  border-color: #bac6ca;
  box-sizing: border-box;
}

.CancelButtonContainer:hover {
  opacity: 70%;
}

.CancelButton {
  position: relative;
  font-size: 18px;
  font-weight: 600;
  font-family: Lato;
  line-height: 24px;
  text-align: center;
  box-sizing: border-box;
}

.SaveButtonContainer {
  position: relative;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: flex-start;
  padding: 2px 6px;
  border-width: 2px;
  border-radius: 8px;
  border-style: solid;
  border-color: #bac6ca;
  box-sizing: border-box;
  background-color: #0a0a09;
}

.SaveButtonContainer:hover {
  opacity: 70%;
}

.SaveButton {
  position: relative;
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  font-family: Lato;
  line-height: 24px;
  text-align: center;
  box-sizing: border-box;
}

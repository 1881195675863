.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 500;
  background: rgba(0, 0, 0, 0.75);
}


.container {

  position: fixed;
  border-radius: 12px;
  box-sizing: border-box;
  background-color: #ffffff;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.08);
  top: 10%;
  left: 30%;
  width: 40%;
  height: 80%;
  z-index: 100;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  }
  .TopSection {
    width: 100%;
    height: 10%;
    position: relative;
    display: flex;
    gap: 25px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* margin: 0px 0px 8px 0px; */
    padding: 0px 20.5px 0px 16px;
    border-width: 0px 0px 1px 0px;
    border-style: solid;
    border-color: #dddee0;
    box-sizing: border-box;
  }
  .titleTEXT {
    position: relative;
    margin: 1px 0px 0px 0px;
    color: #0a0a09;
    font-size: 32px;
    font-weight: 700;
    font-family: Zilla Slab;
    line-height: 40px;
    white-space: nowrap;
    box-sizing: border-box;
  }
  .buttonOut {
    width: 15.1px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;
    background-size: cover;
  }
  .xbutton {
    width: 15.1px;
    min-width: 0px;
    min-height: 0px;
    position: relative;
    box-sizing: border-box;
    background-color: white;
    border: 0px;
  }
  .MiddleSectionall{
    width: 100%;
    height: 80%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 0px 0px 0px 0px;
    box-sizing: border-box;
    padding: 10px 20px 0px 20px;
    overflow-y: auto;

  }
  .MiddleSection {
    width: 100%;
    position: relative;
    gap: 12px;
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    margin: 0px 0px 16px 0px;
    padding: 15px 0px 15px 0px;
    border-radius: 12px;
    box-sizing: border-box;
    background-color: #eaf1f4;
  }
  .ImageContainer {
    min-width: 0px;
    min-height: 0px;
    position: relative;
    margin: 0px 0px 0px 0px;
    box-sizing: border-box;
  }

  .Image{
    width: 80px;
    position: relative;
    box-sizing: border-box;
    background-size: cover;
  }
  .title {
    position: relative;
    color: #0a0a09;
    font-size: 24px;
    font-weight: 600;
    font-family: Zilla Slab;
    line-height: 20px;
    white-space: break-spaces;
    box-sizing: border-box;
    text-align: center;
  }

.outherName {
  position: relative;
  justify-content: flex-start;
  color: #0a0a09;
  font-family: Zilla Slab;
  line-height: 20px;
  white-space: nowrap;
  box-sizing: border-box;
}

.outher {
  display: contents;
  color: #0a0a09;
  font-weight: 600;
  font-family: Zilla Slab;
  line-height: 20px;
  white-space: pre-wrap;
}
.Confirmationsection{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px 0px 0px 0px;

}
.ConfirmationTitle {
  position: relative;
  color: #0a0a09;
  font-size: 2rem;
  font-weight: 600;
  font-family: Zilla Slab;
  line-height: 40px;
  text-align: center;
  box-sizing: border-box;
  margin-bottom: 10px;
}
.ConfirmationSubtitle {
  position: relative;
  margin: 0px 0px 16px 0px;
  color: #0a0a09;
  font-size: 1rem;
  font-family: Lato;
  line-height: 24px;
  text-align: center;
  box-sizing: border-box;
}

.ConfirmationSubtitle1{
  margin-top: 6vh;
  position: relative;
  margin: 0px 0px 16px 0px;
  color: #0a0a09;
  font-size: 2.5vh;
  font-family: Lato;
  line-height: 24px;
  text-align: center;
  box-sizing: border-box;
}

.emailpart{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  box-sizing: border-box;
  
  }
  
  .dropdownmail{
    width: 100%;
    display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  box-sizing: border-box;
  z-index: 10;
  }

.dropdownLabel{
  width: 100%;
  position: relative;
  color: #0a0a09;
  font-size: 16px;
}



.orLine {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 35px;


}

.Line {
  width: 46.28%;
  height: 1px;
  position: relative;
  border-width: 1px 0px 0px 0px;
  border-style: solid;
  border-color: black;
  box-sizing: border-box;
}

.orLineText {
  position: relative;
  font-size: 20px;
  font-weight: 700;
  font-family: Lato;
  line-height: 28px;
  text-align: center;
  box-sizing: border-box;
  color: black;
}

.enteremailto{
  position: relative;
 
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 0px 0px 0px 0px;
  width: 100%;
  
}

.emailText{
  width: 100%;
  position: relative;
  color: #0a0a09;
  font-size: 16px;
}

.emailInputContainer{
  width: 100%;
  position: relative;
  
  box-sizing: border-box;
  border-width: 8px;
  background: white;
  cursor: text;
}
.emailInput::placeholder {
  font-weight: normal; /* Set the desired font-weight */
}


.ButtonSection {
  width: 100%;
  height: 10%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 15px 16px;
  border-width: 0px;
  border-top-width: 1px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-style: solid;
  border-color: #dddee0;
  box-sizing: border-box;
  background: white;
  gap: 15px;

}
/* .ButtonSection:hover {
  opacity: 70%;
} */
.YesButtonContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px 0px 1px 0px;
  padding: 10px 18.5px;
  border-width: 2px;
  border-radius: 14px;
  border-style: solid;
  border-color: #f45370;
  box-sizing: border-box;
}

.YesButtonContainer:hover {
  opacity:0.8 ;
  cursor: pointer;

}

.NoButtonContainer:hover {
  opacity:0.8 ;
  cursor: pointer;

}
.yesButton {
  position: relative;
  color: #0a0a09;
  font-size: 1.2rem;
  font-weight: 600;
  font-family: Lato;
  line-height: 24px;
  text-align: center;
  white-space: nowrap;
  box-sizing: border-box;
}
.NoButtonContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px 16px;
  border-radius: 14px;
  box-sizing: border-box;
  background-color: #1ce99f;
}
.noButton {
  position: relative;
  color: #0a0a09;
  font-size: 1.2rem;
  font-weight: 600;
  font-family: Lato;
  line-height: 24px;
  text-align: center;
  white-space: nowrap;
  box-sizing: border-box;
}


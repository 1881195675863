.genre-card {
  display: flex;
  width: 15vh;
  height: 18vh; /* Set a fixed height */
  padding: 1.5vh 0.5vh 1.5vh 0.5vh;
  flex-direction: column;
  align-items: center;
  gap: 0.5vh;
  border-radius: 10px;
  border: 1px solid var(--storywise-gainsboro, #dddee0);
  background: var(--storywise-white, #fff);
  box-sizing: border-box;
}

.genre-card:hover {
  cursor: pointer;
  border: 1px solid var(--storywise-black, #000);
}

.selected {
  display: flex;
  width: 15vh;
  height: 18vh; /* Match the fixed height */
  padding: 1.5vh 1vh;
  flex-direction: column;
  align-items: center;
  gap: 0.5vh;
  border-radius: 10px;
  /* border: 1px solid var(--storywise-black, #000); */
  background: rgba(28, 234, 160, 0.5);
}

.imagegenre {
  border-radius: 6px;
  display: flex;
  width: 8vh;
  height: auto;
  padding: 0px 2px 5px 0px;
  flex-direction: column;
  align-items: center;
}

.genre-name {
  color: var(--gray-700, #344054);
  text-align: center;
  font-family: 'Lato';
  font-size: 1.4vh;
  font-style: normal;
  font-weight: 600;
  /* Ensure the text fits within the box */
  box-sizing: border-box;
  word-wrap: break-word;
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

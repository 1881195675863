.container {
  height: 100%;
  width: 100%;
  /* width: 78.3vw; */
  align-self: flex-start;
  /* position: fixed; */
  /* right: 0px;
    top: 0px; */
  box-sizing: border-box;
  overflow: auto;
}

/* Add these styles to your CSS file */
.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logosignincontainer {
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.signcontainer {
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1vh 3vw 1vh 3vw;
}

.hedder {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.signup {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5vw;
}

.backtologin {
  color: #000;
  font-family: 'Lato';
  font-size: 1.6vh;
  font-style: normal;
  font-weight: 500;
  line-height: 2vh;
  /* 125% */
}

.linkto {
  color: #2f80ed;
  font-family: 'Lato';
  font-size: 1.6vh;
  font-style: normal;
  font-weight: 600;
  line-height: 2vh;
  /* 125% */
  text-decoration-line: underline;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2vh;
  width: 100%;
  padding-top: 2vh;
  padding-bottom: 2vh;
}

.titlelogo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  /* gap: 0.5vw; */
}

.loginparttitle {
  display: flex;
  color: #0a0b09;
  text-align: left;
  font-family: 'Zilla Slab';
  font-size: 5vh;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.loginparttitle2 {
  display: flex;
  color: #0a0b09;
  text-align: left;
  font-family: 'Zilla Slab';
  font-size: 3vh;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.loginpartsubtitle {
  background: linear-gradient(180deg, #001d26 0%, #013343 100%);
  background-clip: text;
  -webkit-background-clip: text;
  font-family: 'Lato';
  font-size: 1.8vh;
  font-style: normal;
  font-weight: 500;
  line-height: 2.4vh;
  margin-bottom: 2vh;
}

.linktomail {
  color: #2f80ed;
  font-family: 'Lato';
  font-size: 1.8vh;
  font-style: normal;
  font-weight: 500;
  line-height: 2.4vh;
  text-decoration: none;
}

.genres_container {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  gap: 1.5vh;
}

.titles {
  color: var(--storywise-vampire-black, #0a0b09);
  font-family: 'Zilla Slab';
  font-size: 2vh;
  font-style: normal;
  font-weight: 700;
  line-height: 2.8vh;
  /* 140% */
  white-space: nowrap;
}

/* Input */
/* .input {
  display: flex;
  padding: 1vh;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  font-weight: 500;
  font-size: 2vh;
  border-radius: 16px;
  border: 1px solid var(--storywise-gainsboro, #dddee0);
  background: var(--storywise-white, #fff);
} */

.input {
  width: 98%;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* paddinxg: 18px 16px; */
  font-family: 'Inter';
  border: 1px solid #dddee0;
  border-radius: 8px;
  padding: 10px 16px;
  font-weight: normal; /* Set the desired font-weight */
  font-size: 14px;
  width: 35vw;
  box-sizing: border-box;
  text-align: left;
}

/* Error Message */
.error-message {
  color: var(--storywise-red, #ff0000);
  font-family: 'Lato';
  font-size: 1.8vh;
  font-style: normal;
  font-weight: 500;
  /* line-height: 2.2vh; */
  text-align: left;
  /* padding-left: 0.5vw; */
  /* 120% */
}

.label_tooltip {
  margin-top: 1.5vh;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1vw;

  box-sizing: border-box;
}

.input::placeholder {
  text-align: left;
  color: #a2a4a1;
}

/* Labels */
.label {
  color: #0a0b09;
  font-family: 'Lato';
  font-size: 1.8vh;
  font-style: normal;
  font-weight: 700;
  /* line-height: 2.2vh; */
  text-align: left;
  padding-left: 0.5vw;
  /* line-height: 3vh; */
  /* 142.857% */
}

.formpart {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1vh;
  width: 35vw;
  padding-bottom: 1vh;
}

.label_error {
  margin-top: 1.5vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5vw;
  width: 100%;
  box-sizing: border-box;
  color: var(--storywise-secondary-600, #16bb80);
  text-align: center;
  font-family: 'Lato';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 125% */
}

.buttonsend {
  margin-top: 1.5vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5vw;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  color: var(--storywise-secondary-600, #16bb80);
  text-align: center;
  font-family: 'Lato';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.fotter {
  /* height: 0px; */
  width: 100%;
  border-top: 1px solid #dce0e5;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 1vw;
  padding-top: 1vh;
  margin-top: 1vh;
}

.buttoncontainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1vw;
}

.buttoncontainerback {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-direction: row;
  cursor: pointer;
}

.button {
  display: flex;
  padding: 1.2vh 1.6vh;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 14px;
  background: var(--storywise-secondary-500, #1ceaa0);
  cursor: pointer;
}

.textbutton {
  color: var(--storywise-vampire-black, #0a0b09);
  text-align: center;
  font-family: 'Lato';
  font-size: 2vh;
  font-style: normal;
  font-weight: 600;
  line-height: 2.4vh;
  min-width: 12vw;
  /* 120% */
}

.userlistContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--storywise-vampire-black, #0a0b09);
  box-sizing: border-box;

  overflow-y: auto;
  width: 60vw;
}

.userlistContainer:hover {
  border: 1px solid var(--storywise-secondary-500, #1ceaa0);
}

/* .input:focus {
    outline: none;
    border: 1px solid var(--storywise-secondary-500, #1CEAA0);
}
 */
.userlist {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  justify-content: space-between;
  margin: 1vh 1vw;
  padding-bottom: 1vh;
}

.firstrow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1vw;
  width: 100%;
}

.element {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1vw;
}

.title {
  color: #000;
  font-family: 'Lato';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
}

.value {
  color: #000;
  font-family: 'Lato';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 200% */
}

.pagenumberfirst {
  color: var(--sass-gray-700, #454f5f);
  font-family: 'Zilla Slab';
  font-size: 1.6vh;
  font-style: normal;
  font-weight: 500;
}

.sendInvite {
  vertical-align: middle;
  padding-right: 10px;
}

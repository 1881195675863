
.container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 500;
    box-sizing: border-box;
    background: linear-gradient(110.45deg, rgba(0, 29, 38, 0.4) 43.18%, rgba(1, 51, 67, 0.4) 110.25%);
    backdrop-filter: blur(3px);

}

.deletebox {

    position: absolute;
    width: 40%;
    height: 60%;
    left: 30%;
    top: 20%;

    background: #FFFFFF;

    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
}

.boxsplit {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
}

.title {
    width: 100%;
    height: 15%;
    box-sizing: border-box;

    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px;


    border-bottom: 1px solid #DDDEE0;
    order: 0;
}

.titleText {
    font-family: 'Zilla Slab';
    font-style: normal;
    font-weight: 700;
    font-size: 3vh;
    color: #0A0B09;
    box-sizing: border-box;

    order: 0;
}

.buttonOut {

    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    justify-content: center;
    align-self: center;
    order: 1;
}


.xbutton {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    justify-content: center;
    align-self: center;
    order: 1;
    width: 24px;
    height: 24px;
    cursor: pointer;
    display: inline;
}

.deleteboxContent {
    width: 100%;
    height: 85%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;

    order: 1;
}

.editboxContentTextNimage {
    width: 90%;
    height: 82%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:flex-start;
    box-sizing: border-box;
    padding: 0px;
    order: 0;
    gap:0; 
    box-sizing: border-box;
}

.imageUploadPart {
    margin: 20px 0px 20px 0px;
    width: 88%;
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items:center;
    padding: 12px;
    /* gap: 12px; */

    background: #EAF2F4;
    border-radius: 12px;

    order: 0;
}

.book_image {
    /* width: 68px;
    height: 100px; */
    width: 13vw;
    border-radius: 6px;

    /* Inside auto layout */

   
    order: 0;
}

.book_name {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 96%;
    height: 100%;
    align-self: center;
    justify-self: center;
    font-family: 'Zilla Slab';
    font-style: normal;
    font-weight: 600;
    font-size: 2.5vh;
    line-height: 3vh;
    box-sizing: border-box;
    
    color: #0A0B09;
    margin-bottom: 1vh;
    
    }


.editboxContentTextP{
    width: 92%;
    align-self: center;

/* Inside auto layout */

order: 0;


}
.editboxContentTextTitle{
text-align: center;
font-family: 'Zilla Slab';
font-style: normal;
font-weight: 600;
font-size: 3.2vh;
line-height: 4vh;
/* or 125% */

text-align: center;
box-sizing: border-box;
/* Storywise/Vampire Black */

color: #0A0B09;


}


.buttons {
    width: 100%;
    height: 18%;
    box-sizing: border-box;
    justify-self: flex-end;

    /* Auto layout */
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    /* responsive padding */
    padding: 1vh 1vw 1vh 0vw;
    gap: 1vw;

    border-top: 1px solid #DDDEE0;

    /* Inside auto layout */

}

.cancelButtton {
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.6vh 1vw 0.6vh 1vw;
    gap: 8px;

    background: #FFFFFF;
    /* Storywise/Jet Stream */

    border: 2px solid #BAC6CA;
    border-radius: 14px;

    /* Inside auto layout */
    box-sizing: border-box;

    cursor: pointer;
    order: 0;
}

.cancelButtton:hover {
    opacity: 0.5;
}

.cancelButttonText {

    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 3vh;
    line-height: 3.4vh;
    /* identical to box height, or 120% */
    box-sizing: border-box;

    display: flex;
    align-items: center;
    text-align: center;

    /* Storywise/Vampire Black */

    color: #0A0B09;


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

.deleteButtton {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.8vh 1vw 0.8vh 1vw;
    gap: 8px;

    box-sizing: border-box;

    background: #1CEAA0;
    border-radius: 14px;

    /* Inside auto layout */
    cursor: pointer;

    order: 1;
}

.deleteButtton:hover {
    opacity: 0.8;
}

.deleteButttonImage {
    width: 20px;
    height: 20px;

    order: 0;
}

.deleteButttonText {

    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 3vh;
    line-height: 3.4vh;
    /* identical to box height, or 120% */
    box-sizing: border-box;

    display: flex;
    align-items: center;
    text-align: center;

    /* Storywise/White */

    color: #FFFFFF;


    order: 1;
}




.word-count-container{
    width: 100%;
    display: flex;
  padding-bottom: 0px;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5vw ;
  flex-direction: row;
  box-sizing: border-box;

  margin-top: 1vh;
  }
  
    /* Input */
    .input {
      width: 20vw;
      align-self: center;
      height: 4vh;
      display: flex;
      /* padding: 1.2vh; */
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      font-weight: 500;
      font-size: 2vh;
      border-radius: 16px;
      border: 1px solid var(--storywise-gainsboro, #DDDEE0);
      background: var(--storywise-white, #FFF);
    }
  
  .input::placeholder {
  
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 1.4vh;
    line-height: 2vh;
    text-align: left;
    color: #a2a4a1;
    align-self: center;
  
  }


  .editboxContentTextNimage {
    width: 92%;
    align-self: center;

/* Inside auto layout */

order: 0;

}

.titles{
    color: var(--storywise-vampire-black, #0A0B09);
  font-family: 'Zilla Slab';
  font-size: 2vh;
  font-style: normal;
  font-weight: 700;
  line-height: 2.8vh; /* 140% */
  padding: 0px; 


  }



  .checkboxContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 2vh;
    font-style: normal;
    font-weight: 500;
    line-height: 2vh;
    gap: 0.2vw;
    align-self: flex-start;
    color: var(--storywise-vampire-black, #0A0B09);
    font-family: 'Zilla Slab';
    font-size: 1.5vh;
    font-style: normal;
    font-weight: 700;
    line-height: 2.8vh; /* 140% */
    padding: 0px; 
    
}
  
.checkContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.2vh;
    font-style: normal;
    font-weight: 500;
    line-height: 2vh;
    gap: 0.2vw;
    align-self: flex-start;
    justify-self: flex-end;
    margin-bottom: 2vh;

    

}

.linktomail {
    color: #2F80ED;
    font-family: 'Lato';
    font-size: 1.8vh;
    font-style: normal;
    font-weight: 500;
    line-height: 2.4vh;
    text-decoration: none;
}

.privacyLink{
    
    color: #2F80ED;
    font-size: 1.2vh;
    font-style: normal;
    font-weight: 500;
    line-height: 2vh;
}

.outlook_image {
    width: 20vh;
    border-radius: 6px;
    order: 0;
}



.editboxContentTextNimage2 {
    width: 95%;
    height: 82%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:flex-start;
    box-sizing: border-box;
    padding: 0px;
    order: 0;
    gap:0; 
    box-sizing: border-box;
}
.backdrop {
    width: 100%;
    height: 100%;
    position: relative;
    gap: 1.2vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2vh 2vh 0vh 2vh;
    box-sizing: border-box;
    background-position: 50% 50%;
    background-size: cover;
    background-image: linear-gradient(110deg, #001c26 118%, #003242 348%);

}
.manualbackdrop{
  width: 100%;
  height: 100%;
  position: relative;
  gap: 1.2vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2vh 2vh 0vh 2vh;
  box-sizing: border-box;
  background-position: 50% 50%;
  background-size: cover;
  background-image: linear-gradient(110deg, #690b01 118%, #003242 348%);

}
/*   
  .container {
    
    } */
.TopSection {
    width: 100%; 
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content:center;
    /* align-self: stretch; */
    align-items: flex-end;
    gap : 1vh;
    /* margin: 0px 0px 0.8vh 0px; */

    box-sizing: border-box;
}

.buttonOut {
   
    position: relative;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: flex-end;
    box-sizing: border-box;
    background-size: cover;
    padding: 0vh 1vh ;
}

.xbutton {
    min-width: 0px;
    min-height: 0px;
    position: relative;
    box-sizing: border-box;
    border: 0px;
    color: #ffffff;
}

.MiddleSection {
    width: 100%;
    position: relative;
    gap: 1.2vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* margin: 0px 0px 20px 0px; */
    /* padding: 12px 210px; */
    padding: 1vh;
    border-radius: 12px;
    box-sizing: border-box;
    background-position: 50% 50%;
    background-size: cover;
    background-image: linear-gradient(245deg, #29383d -83%, #28373c 83%);

}

.ImageContainer {
    width: 5vw;
    position: relative;
    margin: 0px 0px 0px 0px;
    box-sizing: border-box;
}

.Image {
    width: 5vw;
    min-width: 0px;
    min-height: 0px;
    position: relative;
    box-sizing: border-box;
}

.title {
    position: relative;
  align-self: stretch;
  color: #ffffff;
  font-size: 2.4vh;
  font-weight: 600;
  font-family: Zilla Slab;
  line-height: 2vh;
  white-space: nowrap;
  box-sizing: border-box;
  text-align: center;
}

.outherName {
    position: relative;
    justify-content: flex-start;
    color: #ffffff;
    font-family: Zilla Slab;
    line-height: 2vh;
    white-space: nowrap;
    box-sizing: border-box;
}

.outher {
    display: contents;
    color: #ffffff;
    font-weight: 600;
    font-family: Zilla Slab;
    line-height: 2vh;
    white-space: pre-wrap;
}

.subMiddleSection{
    width: 100%;
    position: relative;
    gap: 1.2vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    padding: 1vh;
  
    box-sizing: border-box;
   }
.ConfirmationTitle {
    width: 100%;
    position: relative;
    color: #0a0a09;
    font-size: 3.2vh;
    font-weight: 600;
    font-family: Zilla Slab;
    line-height: 4vh;
    text-align: center;
    box-sizing: border-box;
    color : #ffffff;
}

.ConfirmationSubtitle {
    width: 96%;
    position: relative;
    color: #0a0a09;
    font-size:1.8vh;
    font-family: Lato;
    line-height:2.4vh;
    text-align: center;
    box-sizing: border-box;
    color : #ffffff;
}

.ConfirmationSubtitle1{
  margin-top: 2vh;
  width: 96%;
  position: relative;
  color: #0a0a09;
  font-size:2.5vh;
  font-family: Lato;
  line-height:2.4vh;
  text-align: center;
  box-sizing: border-box;
  color : #ffffff;
}


.emailpart{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2vh;
    box-sizing: border-box;
    margin-top: 3vh;
    
    }
    
    .dropdownmail{
      width: 100%;
      display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2vh;
    box-sizing: border-box;
    z-index: 10;
    }
  
  .dropdownLabel{
    width: 100%;
    position: relative;
    color: white;
    font-size: 2vh;
  }
  

  .customOption{
    width: 100%;
    position: relative;
    color: white;
    font-size: 2vh;
    font-family: Lato;
  }

  
  
  .orLine {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 3.5vh;
  
  
  }
  
  .Line {
    width: 46.28%;
    height: 1px;
    position: relative;
    border-width: 1px 0px 0px 0px;
    border-style: solid;
    border-color: white;
    box-sizing: border-box;
  }
  
  .orLineText {
    position: relative;
    font-size:2vh;
    font-weight: 700;
    font-family: Lato;
    line-height: 2.8vh;
    text-align: center;
    box-sizing: border-box;
    color: white;
  }
  
  .enteremailto{
    position: relative;
   
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2vh;
    margin: 0px 0px 0px 0px;
    width: 100%;
    
  }
  

  
  .emailText{
    width: 100%;
    position: relative;
    color:white;
    font-size: 2vh;
  }
  
  .emailInputContainer{
    width: 100%;
    position: relative;
    
    box-sizing: border-box;
    border-width: 8px;
    /* background: white; */
    cursor: text;
    background: transparent;
  }

  .emailInput {
    background-color: transparent; /* Set the background color to transparent */
    color: white; /* Set the text color to white */
    /* Add any additional styles you may require */
    border : 1px solid white;
}

.emailInput:focus{
    background-color: transparent; /* Set the background color to transparent */
    color: white; /* Set the text color to white */
    /* Add any additional styles you may require */
    border : 1px solid white;
    font-weight: normal; /* Set the desired font-weight */
}


.emailInput:hover{
    background-color: transparent; /* Set the background color to transparent */
    color: white; /* Set the text color to white */
    /* Add any additional styles you may require */
    border : 1px solid white;
    font-weight: normal; /* Set the desired font-weight */
    cursor: text;

}

  .emailInput::placeholder {
    font-weight: normal; /* Set the desired font-weight */

  }


.ButtonSection {
    width: 100%;
    position: relative;
   
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 2vh;
    padding: 1.5vh 0vh 1.5vh 0vh;
    border-width: 0px;
   
    box-sizing: border-box;
   
}


.YesButtonContainer {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 1.2vh 1.6vh 1.2vh 1.6vh;
    border-width: 2px;
    border-radius: 14px;
    border-style: solid;
    border-color: #f45370;
    box-sizing: border-box;
}

.YesButtonContainer:hover{
    opacity: 0.8;
    cursor: pointer;
}
.yesButton {
    position: relative;
    color:white;
    font-size: 2vh;
    font-weight: 600;
    font-family: Lato;
    line-height: 2.4vh;
    text-align: center;
    white-space: pre-wrap;
    box-sizing: border-box;
}

.NoButtonContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding:1.2vh 1.6vh 1.2vh 1.6vh;
    border-radius: 14px;
    box-sizing: border-box;
    background-color: #1ce99f;
}

.NoButtonContainer:hover{
    opacity: 0.8;
    cursor: pointer;
}

.noButton {
    position: relative;
    color: #0a0a09;
    font-size: 2vh;
    font-weight: 600;
    font-family: Lato;
    line-height: 2.4vh;
    text-align: center;
    white-space: nowrap;
    box-sizing: border-box;
}

.emailInput::placeholder{
  width: 100%;
  position: relative;
  color:white;
  font-size: 2vh;
}
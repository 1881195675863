.container {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    align-items: center;
    padding-left: 2vw;
}

.tableU {
    width: 98%;
    /* height: 90%; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    background: #FFFFFF;

    /* box-shadow: 2px 2px 8px rgba(200, 200, 200, 0.12); */
    border-radius: 12px;
    margin-bottom: 0%;
    margin: 2vh;
    overflow-y: auto;
  
    border-radius: 12px;
    box-sizing: border-box;
}

.buttonContainer {
    width: 98%;
    /* height: 90%; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    background: #FFFFFF;

    /* box-shadow: 2px 2px 8px rgba(200, 200, 200, 0.12); */
    border-radius: 12px;
    margin-bottom: 0%;
    margin: 1vh;
    overflow-y: auto;
    /* Enables scrolling */
    border-radius: 12px;
    box-sizing: border-box;


}


.buttonContainerend {
    /* height: 10%; */

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: space-between;
    gap: 1vw;
    box-sizing: border-box;


}

.buttonContainerend2 {
    /* height: 10%; */

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: space-between;
    gap: 1vw;
    box-sizing: border-box;


}


.buttonDiscard {
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1.2vh 1.6vw;
    gap: 0.8vh;

    background: #FFFFFF;

    border: 2px solid #DDDEE0;
    border-radius: 14px;

    /* flex: none; */
    /* order: 0; */
    /* flex-grow: 0; */
    cursor: pointer;
}

.buttonDiscardText {

    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 2vh;
    line-height: 2.4vh;
    /* identical to box height, or 120% */

    display: flex;
    align-items: center;
    text-align: center;

    /* Storywise/Vampire Black */

    color: #0A0B09;

}


.buttonDiscard :hover {
    opacity: 0.7;
}

.buttonSave:hover {
    opacity: 0.7;
}

.buttonSave {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1.2vh 1.6vw;
    gap: 0.8vh;

    background: #1CEAA0;
    border-radius: 14px;
    cursor: pointer;


}

.buttonSaveText {

    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 2vh; 
    line-height: 2.4vh;
    /* identical to box height, or 120% */

    display: flex;
    align-items: center;
    text-align: center;

    /* Storywise/Vampire Black */

    color: #0A0B09;


}


.buttonDownload {

    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1.2vh 1.6vw;
    gap: 0.8vh;

    background: #FFFFFF;


    border: 2px solid #BAC6CA;
    border-radius: 12px;
    /* min-width: 10vw; */

}

.downimg {
    position: relative;
    width: 20px;
    height: 20px;
    left: 0px;
    top: 0px;

}

.dwomtext {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 2vh;
    line-height: 
    2.4vh;


    display: flex;
    align-items: center;
    text-align: center;

    /* Storywise/Vampire Black */

    color: #0A0B09;


}
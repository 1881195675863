

.cardContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 8px;
  gap: 4px;
  width: 100%;
  height: 100%;
  background: #212D31;
  border-radius: 8px;
  box-sizing: border-box;
  /* background-color: #cb4747; */

  /* Inside auto layout */

  flex: 1;
}

.cardContainer:hover {
  opacity: 70%;
}

.machPercent {
  position: relative;
  box-sizing: border-box;

}

.cardInfo {
  /* flex:3; */
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  
  
}


.cardTitle {
  
font-family: 'Zilla Slab';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 20px;
/* identical to box height, or 143% */

text-transform: capitalize;

/* Storywise/White */

color: #FFFFFF;

}

.grade {
  position: relative;
 
font-family: 'Lato';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 16px;
/* identical to box height, or 133% */

text-transform: capitalize;

/* Storywise/White */

color: #FFFFFF;


}

.like{
  width : 30px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 11px 10px;
  margin: 0px;
  /* flex:1; */
  /* background-color: #cb4747; */
}
.containr {
  width: 100%;
  height: 100%;
  position: relative;
  gap: 2px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px 10px 10px 10px;
  border-width: 0px 1px 0px 0px;
  border-style: solid;
  border-color: #dddee0;
  box-sizing: border-box;
  /* height: 80vh; */
  overflow-y: auto;
  /* overflow-x: auto; */
  white-space: nowrap;
  overflow-x: visible;
  padding-top: 2vh;
}

.containr::-webkit-scrollbar {
  width: 2px;
  /* Adjust the scrollbar width as desired */
  /* background-color: #f2f2f2; */
  /* Set a background color for the scrollbar */
}

.container::-webkit-scrollbar-thumb {
  /* background-color: #888; */
  /* Set the color of the scrollbar thumb */
  border-radius: 3px;
  /* Round the corners of the thumb */
}
::-webkit-scrollbar-track {
  width: 1px;
 }

.title{
  color: black;
  position: relative;
  text-decoration: none;
  margin: 0px 0px 00px 0px;
  font-size: 16px;
  font-family: Zilla Slab;
    line-height: 28px;
  white-space: nowrap;
  box-sizing: border-box;
}


.linkChapter{
  color: black;
  position: relative;
  text-decoration: none;
  margin: 0px 0px 0px 0px;
  font-size: 16px;
  font-family: Zilla Slab;
  line-height: 28px;
  white-space: nowrap;
  box-sizing: border-box;
  overflow-y: auto;
  
}

.linkChapter:focus{
  position: relative;
  font-size: 20px;
  font-weight: 700;
  font-family: Zilla Slab;
  line-height: 28px;
  white-space: nowrap;
  box-sizing: border-box;
  color: #7f7f7f;
  text-decoration: none;
}

.linkChapter:visited{
  color: black;

}

.linkChapter:hover{
  position: relative;
  font-size: 20px;
  font-weight: 700;
  font-family: Zilla Slab;
  line-height: 28px;
  white-space: nowrap;
  box-sizing: border-box;
  color: #1CEAA0;
  text-decoration: none;

}

.linkChapter:active{
  position: relative;
  font-size: 16px;
  font-weight: 700;
  font-family: Zilla Slab;
  line-height: 28px;
  white-space: nowrap;
  box-sizing: border-box;
  color: #1CEAA0;
  text-decoration: none;
}



.contentList::-webkit-scrollbar {
    width: 4px; /* Scrollbar width */
    background-color: #f5f5f5; /* Scrollbar background color */
  }
  
  .contentList::-webkit-scrollbar-thumb {
    background-color: #888; /* Scrollbar thumb color */
    border-radius: 8px;
  
  }
  
  .contentList::-webkit-scrollbar-thumb:hover {
    background-color: #7f7f7f; /* Scrollbar thumb color on hover */
    border-radius: 8px;
  }


  .chaptercontainer{
    width: 100%;
    height: 100%;
    position: relative;
    white-space: nowrap;
    overflow-x: hidden;
    border-bottom: 1px solid #dddee0; 
  }


  .chaptercontainer:hover{
    width: 100%;
    height: 100%;
    position: relative;
    white-space: nowrap;
    overflow-x: visible;
  }

  .chaptercontainer:focus{
    width: 100%;
    height: 100%;
    position: relative;
    white-space: nowrap;
    overflow-x: visible;
  }



.chaptercontainer:hover,
.chaptercontainer:focus {
  width: fit-content;
  height: 100%;
  position: relative;
  white-space: nowrap;
  overflow-x: visible;
}

.chaptercontainer:hover .linkChapter,
.chaptercontainer:focus .linkChapter {
  overflow: visible;
  white-space: normal;
}
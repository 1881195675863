.container {
  /* position: absolute; */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: linear-gradient(
    110.45deg,
    rgba(0, 29, 38, 0.4) 43.18%,
    rgba(1, 51, 67, 0.4) 110.25%
  );
  backdrop-filter: blur(3px);
}

.editbox {
  position: absolute;
  width: 40%;
  height: 65%;
  left: 30%;
  top: 17.5%;

  background: #ffffff;

  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
}

.boxsplit {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
}

.title {
  width: 100%;
  height: 12%;
  box-sizing: border-box;

  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;

  border-bottom: 1px solid #dddee0;
  order: 0;
}

.titleText {
  font-family: 'Zilla Slab';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;

  color: #0a0b09;

  order: 0;
}

.buttonOut {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  justify-content: center;
  align-self: center;
  order: 1;
}
.xbutton {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  justify-content: center;
  align-self: center;
  order: 1;
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: inline;
}

.editboxContent {
  width: 100%;
  height: 78%;
  /* height: 100%; */

  position: relative;
  order: 1;
  box-sizing: border-box;
}

.editboxContentTextNimage {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  order: 0;
}

.imageUploadPart {
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  gap: 16px;

  order: 0;
}

.dragNdrop {
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.imageUploadPartText {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;

  align-self: center;
  color: #0a0b09;

  order: 1;
}

.sizeReccomend {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  color: #0a0b09;

  order: 1;
}

.editboxContentTextPart {
  width: 100%;
  height: 100%;
  padding: 10px;
  order: 1;
  z-index: 9999;

  box-sizing: border-box;
  /* overflow-y: auto; */
}

.editboxContentTextTitle {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px;
  /* gap: 8px; */
  /* flex : 1; */
}

.editboxContentTextTitleGenre {
  width: 100%;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px;
  /* gap: 8px; */
  z-index: 12;
}

.Select {
  width: 100%;
  height: 100%;
  border: 0px;
  border-radius: 16px;
}
.Select:focus {
  outline: none;
  border: 1px solid #0a0b09;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.lable {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 4px;
  order: 0;
}

.labelText {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;

  color: #0a0b09;
}

.input {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* paddinxg: 18px 16px; */

  border: 1px solid #dddee0;
  border-radius: 16px;
  padding: 10px 16px;
  font-weight: normal; /* Set the desired font-weight */
  font-size: 14px;
  box-sizing: border-box;
  min-height: 38px;
}

.input:focus {
  outline: none;
  font-weight: normal; /* Set the desired font-weight */
  cursor: text;
}

.input:hover {
  border: 1px solid #0a0b09;
  font-weight: normal; /* Set the desired font-weight */
  cursor: text;
  box-shadow: 0px 0px 4px rgba(10, 11, 9, 0.25);
}

.input::placeholder {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: left;

  color: lightgray;
}

.dropdownMenu {
  position: absolute;
  z-index: 9999; /* Adjust the z-index value as needed */
}

/* 
.editboxContentTextAuthor {
}

.editboxContentTextEmail {
}

.editboxContentTextStatus {
} */

.buttons {
  width: 100%;
  height: 10%;
  box-sizing: border-box;

  /* Auto layout */
  position: absolute;
  bottom: 0px;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 16px 16px 16px 0px;
  gap: 15px;

  border-top: 1px solid #dddee0;

  /* Inside auto layout */

  order: 2;
}

.cancelButtton {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;
  gap: 8px;

  background: #ffffff;
  /* Storywise/Jet Stream */
  cursor: pointer;

  border: 2px solid #bac6ca;
  border-radius: 14px;

  /* Inside auto layout */

  order: 0;
}
.cancelButtton:hover {
  opacity: 0.5;
}

.cancelButttonText {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  display: flex;
  align-items: center;
  text-align: center;

  /* Storywise/Vampire Black */

  color: #0a0b09;

  /* Inside auto layout */

  order: 1;
}

.saveButtton {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 10px;
  gap: 8px;

  background: #1ceaa0;
  border-radius: 14px;
  cursor: pointer;

  order: 1;
}
.saveButtton:hover {
  opacity: 0.8;
}
.saveButttonText {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;

  display: flex;
  align-items: center;
  text-align: center;

  /* Storywise/Vampire Black */

  color: #0a0b09;

  /* Inside auto layout */

  order: 1;
}
.buttoncontainerback {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;

  align-items: center;
  padding: 16px 16px 16px 0px;
  gap: 15px;
  order: 2;
}

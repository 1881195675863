.PageRoot {
  height: 100%;
  width: 100%;
  /* width: 78.3vw; */
  align-self: flex-start;
  /* position: fixed; */
  /* right: 0px;
    top: 0px; */
  box-sizing: border-box;
  overflow: auto;
}
.container {
  height: 100%;
  width: 100%;
  /* width: 78.3vw; */
  align-self: flex-start;
  /* position: fixed; */
  /* right: 0px;
  top: 0px; */
  box-sizing: border-box;
}

.logosignincontainer {
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.signcontainer {
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 2vh 3vw 2vh 3vw;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2vh;
  width: 100%;
  height: 75vh;
}

.errorMessage {
  color: #ff0000;
  font-family: 'Lato';
  font-size: 1.8vh;
  font-style: normal;
  font-weight: 500;
  line-height: 2.4vh;
  margin-top: 1vh;
  margin-bottom: 1vh;
}

.titlelogo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  /* gap: 0.5vw; */
}

.loginparttitle {
  display: flex;
  color: #0a0b09;
  text-align: left;
  font-family: 'Zilla Slab';
  font-size: 5vh;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.loginparttitlepart2 {
  display: flex;
  color: #0a0b09;
  text-align: left;
  font-family: 'Zilla Slab';
  font-size: 5vh;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.loginpartsubtitle {
  font-family: 'Lato';
  font-size: 1.8vh;
  font-style: normal;
  font-weight: 500;
  line-height: 2.4vh;
  margin-bottom: 2vh;
}

.linktomail {
  color: #2f80ed;
  font-family: 'Lato';
  font-size: 1.8vh;
  font-style: normal;
  font-weight: 500;
  line-height: 2.4vh;
  text-decoration: none;
}

.privacyLink {
  color: #2f80ed;
  font-size: 1.2vh;
  font-style: normal;
  font-weight: 500;
  line-height: 2vh;
}

.emailProviders {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: flex-start;
  gap: 3vw;
  margin-left: 2vw;
}

.icons2 {
  /* width: 8vh;
  margin-left: -2vw;
  margin-right: 1vw; */
  width: 37px;
}

.icons {
  width: 18px;
  margin-left: 6px;
}

.emailProviderin2 {
  width: 191px;
  height: 43px;
  background: #ffffff;
  /* border: 1px solid #DDDEE0; */
  font-size: 13.5px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.54); /* Use the correct syntax for RGBA color */
  /* Use the Roboto font that is loaded in the <head> */
  font-family: 'Roboto', sans-serif;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: -1vw;
  /* box-shadow: 0.1px 0.5px grey; */
  box-shadow: -0.1px 0 0 grey, /* Left shadow */ 0 0.4px 0 grey,
    /* Top shadow */ 0.1px 0 0 grey; /* Right shadow */

  border-radius: 1px;
  box-sizing: border-box;
  gap: 15px;
}

.clicked {
  background: var(--storywise-gainsboro, #dddee0);
  border: 1px solid var(--storywise-gainsboro, #dddee0);
  /* cursor: pointer; */
  display: flex;
}

.emailProviderin2:hover {
  background: var(--storywise-gainsboro, #dddee0);
  border: 1px solid var(--storywise-gainsboro, #dddee0);
  /* cursor: pointer; */
  display: flex;
}

/* ManuscriptsUploads.module.css */
.emailProviderGmail {
  display: flex;
  flex-direction: row;
  background: #ffffff;
  color: #444;
  border-radius: 5px;
  border: thin solid #888;
  box-shadow: 1px 1px 1px grey;
  white-space: nowrap;
  height: 40dp;
  padding-left: 8dp;
  padding-right: 8dp;
  gap: 24dp;
}
.emailProviderGmail:hover {
  cursor: pointer;
}

.emailProviderOutlook {
  /* Add your desired background color for Outlook */
  background: var(--storywise-gainsboro, #dddee0);
  border: 1px solid var(--storywise-gainsboro, #dddee0);
}

.emailProviderin2 {
  margin-left: -2vw;
}
.titles {
  font-family: 'Lato';
  font-size: 2vh;
  font-style: normal;
  line-height: 2.8vh; /* 140% */
  white-space: nowrap;
  padding: 0px;
}

.titlesN {
  color: var(--storywise-vampire-black, #0a0b09);
  font-family: 'Zilla Slab';
  font-size: 2.2vh;
  font-style: normal;
  font-weight: 700;
  margin-top: 1vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1vw;

  /* white-space: ;   */
}

.word-count-container {
  width: 100%;
  display: flex;
  padding-bottom: 0px;
  align-items: center;
  justify-content: flex-start;
  gap: 1vh;
  flex-direction: row;
  margin-top: 0.5vh;
}

/* Input */
.input {
  width: 15vw;
  align-self: center;
  height: 5vh;
  display: flex;
  /* padding: 1.2vh; */
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  font-weight: 500;
  font-size: 2vh;
  border-radius: 16px;
  border: 1px solid var(--storywise-gainsboro, #dddee0);
  background: var(--storywise-white, #fff);
}

.input::placeholder {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 1.4vh;
  line-height: 2vh;
  text-align: left;
  color: #a2a4a1;
  align-self: center;
}

.listofcard {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1vw;
  flex-wrap: wrap;
}

.listofcardA {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1vw;
  flex-wrap: wrap;
  height: 4.8vh;
}

.checkboxContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.2vh;
  font-style: normal;
  font-weight: 500;
  line-height: 2vh;
  gap: 0.2vw;
}

.fotter {
  /* height: 0px; */
  width: 100%;
  border-top: 1px solid #dce0e5;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 1vw;
  padding-top: 1vh;
  margin-top: 3vh;
}

.buttoncontainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1vw;
}

.buttoncontainerback {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-direction: row;
  cursor: pointer;
}

.button {
  display: flex;
  padding: 1.2vh 1.6vh;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 14px;
  background: var(--storywise-secondary-500, #1ceaa0);
  cursor: pointer;
  min-width: 10vw;
}

.textbutton {
  color: var(--storywise-vampire-black, #0a0b09);
  text-align: center;
  font-family: 'Lato';
  font-size: 2vh;
  font-style: normal;
  font-weight: 600;
  line-height: 2.4vh;
  /* 120% */
}

.MuiFormControlLabel-root {
  display: flex;
  align-items: center;
  gap: 15px; /* Adjust the gap between checkbox and label */
  font-size: 14px; /* Adjust the font size of the label */
  margin-right: 0px;
}

/* Additional styling for the label */
.formControlLabelLabel {
  font-size: inherit; /* Inherit font size from the root */
  line-height: 1.2; /* Adjust line height for better alignment */
}

/* Additional styling for the checkbox icon */
.MuiSvgIcon-root {
  font-size: 18px; /* Adjust the size of the checkbox icon */
}

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;

  /* //    width: 21.7vw; */
}

.logosignincontainer {
  height: 100vh;
  width: 78.3vw;

  position: fixed;
  right: 0px;
  top: 0px;
  box-sizing: border-box;
  overflow: auto;
}

.signcontainer {
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 4vh 3vw 2vh 3vw;
}

.hedder {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 2.5vh;
}

.steps {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1vw;
  /* margin-top: 5vh; */
}

.step1 {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1vw;
  /* margin-top: 5vh; */
}

.step1circle {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.5vh;
  background: linear-gradient(180deg, #001d26 0%, #013343 100%);
  overflow: hidden;
  background-size: cover;
  background-position: 50% 50%;
  width: 5.8vh;
  height: 5.8vh;
}

.step1text {
  color: var(--storywise-vampire-black, #0a0b09);
  font-family: 'Zilla Slab';
  font-size: 1.4vh;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6vh; /* 123.077% */
}

.step1text1 {
  color: var(--storywise-vampire-black, #0a0b09);
  font-family: 'Lato';
  font-size: 1.6vh;
  font-style: normal;
  font-weight: 700;
  line-height: 2.4vh; /* 150% */
}

.men {
  width: 2.4vh;
  height: 2.4vh;
}

.step2circle {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.5vh;
  background: linear-gradient(180deg, #001d26 0%, #013343 100%);
  overflow: hidden;
  background-size: cover;
  background-position: 50% 50%;
  width: 5.8vh;
  height: 5.8vh;
}

.menstep2 {
  width: 2.4vh;
  height: 2.4vh;
}

.step3circle {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.5vh;
  overflow: hidden;
  width: 5.8vh;
  height: 5.8vh;
  background: #ececec;
}

.menstep3 {
  width: 2.4vh;
  height: 2.4vh;
}

.signup {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5vw;
}

.backtologin {
  color: #000;
  font-family: 'Lato';
  font-size: 1.6vh;
  font-style: normal;
  font-weight: 500;
  line-height: 2vh;
  /* 125% */
}

.linkto {
  color: #2f80ed;
  font-family: 'Lato';
  font-size: 1.6vh;
  font-style: normal;
  font-weight: 600;
  line-height: 2vh;
  /* 125% */
  text-decoration-line: underline;
}


.textbuttonBack {
  color: var(--sass-gray-300, #95a1b2);
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}


.buttoncontainerback {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-direction: row;
  cursor: pointer;
}



.buttoncontainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1vw;
}

.pagenumber {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  color: var(--sass-gray-300, #95a1b2);
  font-family: 'Zilla Slab';
  font-size: 1.2vh;
  font-style: normal;
  font-weight: 700;
}



.container {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  /* //    width: 21.7vw; */
}

.logosignincontainer {
  height: 100vh;
  width: 78.3vw;

  position: fixed;
  right: 0px;
  top: 0px;
  box-sizing: border-box;
}

.signcontainer {
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 2vh 3vw 1vh 2vw;
  overflow-y: auto;
}

.hedder {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.steps {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1vw;
  margin-bottom: 5vh;
  margin-top: 1vh;
  /* margin-top: 5vh; */
}

.step1 {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1vw;
  /* margin-top: 5vh; */
}

.step1circle {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.5vh;
  background: linear-gradient(180deg, #001d26 0%, #013343 100%);
  overflow: hidden;
  background-size: cover;
  background-position: 50% 50%;
  width: 5.8vh;
  height: 5.8vh;
}

.step1text {
  color: var(--storywise-vampire-black, #0a0b09);
  font-family: 'Zilla Slab';
  font-size: 1.4vh;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6vh; /* 123.077% */
}

.step1text1 {
  color: var(--storywise-vampire-black, #0a0b09);
  font-family: 'Lato';
  font-size: 1.6vh;
  font-style: normal;
  font-weight: 700;
  line-height: 2.4vh; /* 150% */
}

.men {
  width: 2.4vh;
  height: 2.4vh;
}

.step2circle {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.5vh;
  overflow: hidden;
  width: 5.8vh;
  height: 5.8vh;
  background: #ececec;
}

.menstep2 {
  width: 2.4vh;
  height: 2.4vh;
}

.step3circle {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.5vh;
  overflow: hidden;
  width: 5.8vh;
  height: 5.8vh;
  background: #ececec;
}

.menstep3 {
  width: 2.4vh;
  height: 2.4vh;
}

.signup {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5vw;
}

.backtologin {
  color: #000;
  font-family: 'Lato';
  font-size: 1.6vh;
  font-style: normal;
  font-weight: 500;
  line-height: 2vh;
  /* 125% */
}

.linkto {
  color: #2f80ed;
  font-family: 'Lato';
  font-size: 1.6vh;
  font-style: normal;
  font-weight: 600;
  line-height: 2vh;
  cursor: pointer;
  /* 125% */
  text-decoration-line: underline;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1vh;
  /* width: 100%; */
  padding-top: 1vh;
  margin-bottom: 2vh;
}
.titlelogo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  /* gap: 0.5vw; */
}

.loginparttitle {
  display: flex;
  color: #0a0b09;
  text-align: left;
  font-family: 'Zilla Slab';
  font-size: 5vh;
  font-style: normal;
  font-weight: 700;
}

.loginparttitlepart2 {
  display: flex;
  color: #0a0b09;
  text-align: left;
  font-family: 'Zilla Slab';
  font-size: 5vh;
  font-style: normal;
  font-weight: 500;
}

.loginpartsubtitle {
  background: linear-gradient(180deg, #001d26 0%, #013343 100%);
  background-clip: text;
  -webkit-background-clip: text;
  font-family: 'Lato';
  font-size: 1.8vh;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5vh;
  /* margin-bottom: 2vh; */
  text-align: left;
  margin-bottom: 1vh;
}

.linktomail {
  color: #2f80ed;
  font-family: 'Lato';
  font-size: 1.8vh;
  font-style: normal;
  font-weight: 500;
  line-height: 2.4vh;
  text-decoration: none;
}

.placetoerror {
  height: 2vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1vw;
  box-sizing: border-box;
}

.label_error {
  margin-top: 1vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5vw;
  width: 100%;
  box-sizing: border-box;
}

/* Labels */
.label {
  color: #0a0b09;
  font-family: 'Lato';
  font-size: 1.8vh;
  font-style: normal;
  font-weight: 700;
  /* line-height: 2.2vh; */
  text-align: left;
  padding-left: 0.5vw;
  /* line-height: 3vh; */
  /* 142.857% */
}

.input::placeholder {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 1.4vh;
  line-height: 2vh;
  text-align: left;

  color: #a2a4a1;
}

.label_tooltip {
  margin-top: 0.5vh;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1vw;

  box-sizing: border-box;
}
.tooltipIcon {
  /* width: 30px;
  height: 30px; */
  width: 2vh;
  padding: 0px 0px 0px 0px;
  order: 0;

  /* align-self: flex-end; */
}

.tooltipIcon:hover {
  cursor: pointer;
}

.description {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px 0px 0px 0px;
}

.tooltip {
  position: absolute;
  /* left: 20%; */
  width: 200px;
  /* background-color: #ecf5ec; */
  background-color: #c1f2e0;
  border-radius: 12px;
  /* border: 1px solid #0A0B09; */
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  /* transition: opacity 0.3s ease, visibility 0.3s ease; */
}
.description:hover .tooltip {
  opacity: 1;
  visibility: visible;
}

.tooltipText {
  color: #333;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: #0a0b09;
}

.fotter {
  /* height: 0px; */
  width: 100%;
  border-top: 1px solid #dce0e5;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1vw;
  padding-top: 1vh;
  margin-top: 1vh;
}

/* Error Message */
.error-message {
  color: var(--storywise-red, #ff0000);
  font-family: 'Lato';
  font-size: 1.8vh;
  font-style: normal;
  font-weight: 500;
  /* line-height: 2.2vh; */
  text-align: left;
  padding-left: 0.5vw;
  /* 120% */
}

.error-messagegeneral {
  color: var(--storywise-red, #ff0000);
  font-family: 'Lato';
  font-size: 1.8vh;
  font-style: normal;
  font-weight: 500;
  /* line-height: 2.2vh; */
  text-align: left;
  padding-left: 0.5vw;
  /* 120% */
}

/* confirm part */

.logocontainer {
  height: 100vh;
  width: 78.3vw;

  position: fixed;
  right: 0px;
  top: 0px;
  margin-right: 5vw;
  /* //    width: 21.7vw; */
}

.logincontainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.linktosignup {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5vw;
  margin-top: 5vh;
}

.beforelink {
  color: #000;
  font-family: 'Lato';
  font-size: 1.6vh;
  font-style: normal;
  font-weight: 500;
  line-height: 2vh;
  /* 125% */
}

.linkto {
  color: #2f80ed;
  font-family: Lato;
  font-size: 1.6vh;
  font-style: normal;
  font-weight: 600;
  line-height: 2vh;
  /* 125% */
  text-decoration-line: underline;
}

.loginpart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: centexr;
  position: fixed;
  top: 20vh;
  /* right: 20%; */
  width: 25vw;
  height: 40vh;
  gap: 2vh;
  box-sizing: border-box;
  align-self: center;
}

.loginparttitle {
  color: var(--storywise-vampire-black, #0a0b09);
  text-align: center;
  font-family: 'Zilla Slab';
  font-size: 5.2vh;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.loginpartsubtitle {
  color: var(--storywise-vampire-black, #0a0b09);
  width: 90%;
  text-align: center;
  font-family: 'Lato';
  font-size: 1.8vh;
  font-style: normal;
  font-weight: 500;
  /* line-height: 24px;  */
}

.formpart {
  padding-bottom: 0vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1vh;
  width: 120%;
  /* height: 100%; */
  /* padding: 1vh; */
}

.input {
  display: flex;
  padding: 1vh;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  font-weight: 500;
  font-size: 2vh;
  border-radius: 16px;
  border: 1px solid var(--storywise-gainsboro, #dddee0);
  background: var(--storywise-white, #fff);
}

/* Valid input style */
.input.valid {
  border: 1px solid var(--storywise-gainsboro, #dddee0);
}

/* Invalid input style */
.input.invalid {
  border: 1px solid var(--storywise-red, #ff0000);
}

/* Invalid input focus style */
.input.invalid:focus {
  border: 1px solid var(--storywise-red, #ff0000);
  outline: none; /* Remove the default focus outline */
}

/* Invalid input hover style */
.input.invalid:hover {
  border: 1px solid var(--storywise-red, #ff0000);
}

/* style for tha placeholder of the input */
.input::placeholder {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 2vh;
  text-align: left;

  color: #a2a4a1;
}

.lastrow {
  /* margin-top: 0.5vh; */
  box-sizing: border-box;
  width: 100%;
}

.loginbutton {
  border-radius: 14px;
  background: var(--storywise-secondary-500, #1ceaa0);
  display: flex;
  padding: 1.2vh 1.6vh;
  /* margin: 1.2vh 0vh ; */
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  min-width: 20vw;
  /* width: 100%; */
}

.loginbuttontext {
  color: var(--storywise-vampire-black, #0a0b09);
  text-align: center;
  font-family: 'Lato';
  font-size: 2vh;
  font-style: normal;
  font-weight: 600;
  line-height: 2.4vh; /* 120% */
  cursor: pointer;
}

.loginbutton:hover {
  opacity: 0.8;
}

.button {
  display: flex;
  padding: 1.2vh 1.6vh;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 14px;
  background: var(--storywise-secondary-500, #1ceaa0);
  cursor: pointer;
  min-width: 10vw;
}

.textbutton {
  color: var(--storywise-vampire-black, #0a0b09);
  text-align: center;
  font-family: 'Lato';
  font-size: 2vh;
  font-style: normal;
  font-weight: 600;
  line-height: 2.4vh;
  /* 120% */
}

.pagenumber {
  color: var(--sass-gray-300, #95a1b2);
  font-family: 'Zilla Slab';
  font-size: 1.2vh;
  font-style: normal;
  font-weight: 700;
  line-height: 1.6vh;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  color: var(--sass-gray-300, #95a1b2);
  font-family: 'Zilla Slab';
  font-size: 1.2vh;
  font-style: normal;
  font-weight: 700;
}

/* 
.input:focus {
  outline: none;
  border: 1px solid var(--storywise-secondary-500, #1CEAA0);
} */
.pagenumberfirst {
  color: var(--sass-gray-700, #454f5f);
  font-family: 'Zilla Slab';
  font-size: 1.6vh;
  font-style: normal;
  font-weight: 500;
}

.passwordError {
  max-width: 260px;
}

/* Root Container */

.moreInfoPart2 {
    width: 100%;
    /* height: 62%; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 1vh;
    /* margin: 1.5% 0px 1.5% 0px; */
    gap: 10px;
  
    box-sizing: border-box;
  
    background: #29383d;
    border-radius: 12px;
    /* overflow-y: auto;
      overflow-x: auto; */
    overflow-x: auto;
  }
  
  .NoNotesText{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    user-select: none;
    white-space: nowrap;
  }
  
  .firstTitle {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-self: stretch;
    align-items: center;
    box-sizing: border-box;
  }
  

  .moreInfoTitle {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    user-select: none;
    white-space: nowrap;
  }
  
  
  /* Notes Section */
  .NotesSectionRoot {
    width: 98%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0.7rem;
    background-color: #1c2e33;
    border: 1px solid #71858b;
    border-radius: 12px;
    box-sizing: border-box;
  }
  .addNotesSectionRoot {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 5px;
    /* padding: 0.7rem; */
    box-sizing: border-box;
  }
  /* Note Input */
  .NoteTitle {
    width: 100%;
    font-family: 'Lato', sans-serif;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    color: #ffffff;
    background: lightgray;
    border: 1px solid #71858b;
    border-radius: 8px;
    padding: 0.7rem;
    box-sizing: border-box;
    transition: border-color 0.3s ease, background-color 0.3s ease;
  }
  
  .NoteTitle:focus {
    outline: none;
    border-color: #1ceaa0;
    background-color: #002734;
  }

  .ButtonContainer{
    display: flex;
    justify-content: space-between;
    gap: 1vh;
  }
  .NoteFooter{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 1vh;
  }
  .SaveButtonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.9vh 1.2vh;
    gap: 0.8vh;
    align-self: center;

    background: #1ceaa0;
    border-radius: 14px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 2vh;
    color: #0a0b09;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    transition: transform 0.3s ease, background-color 0.3s ease;
}

.SaveButtonContainer:hover {
    background-color: #16c38d;
    transform: scale(1.05);
}
  /* Reader View Notes List */
  .readerViewNotesList {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    
  }
  .CancelButtonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.6vh 1.2vh;
    gap: 0.8vh;
    align-self: center;

    border: 2px solid #71858b;
    border-radius: 14px;
    background:transparent;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 2vh;
    color: #ffffff;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    transition: opacity 0.3s ease, border-color 0.3s ease;
}
.ButtonContainer {
    display: flex;
    justify-content: space-between;
    gap: 1vh;
}

.CancelButtonContainer:hover {
    opacity: 0.7;
    border-color: #1ceaa0;
}
.addreaderViewNoteItem {
  width: 100%;
  height: 4%;
  margin-left: 1%;
  margin-right: 1%;
  background-color: black;
  border: 1px solid #71858b;
  border-radius: 5px;
  box-sizing: border-box;
  text-align: left;
  padding: 0.4rem; /* Add padding for inner spacing */
  font-size: 0.8rem; /* Adjust font size if needed */
  line-height:1.1rem;

  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  word-break: break-word; /* Ensure text wraps properly */
  align-self: center;
}

.addreaderViewNoteItem:focus {
  outline: none;
  border-color: #1ceaa0;
}
.addreaderViewNoteItem::placeholder {
 

  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  word-break: break-word; /* Ensure text wraps properly */
}

.addreaderViewNoteItem::content {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  word-break: break-word; /* Ensure text wraps properly */
}

  /* Note Item */
  .readerViewNoteItem {
    width: 98%;
    margin-left: 1%;
    margin-right: 1%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: #212d31;
    border: 1px solid #71858b;
    border-radius: 12px;
    padding: 0.7rem;
    box-sizing: border-box;
    transition: transform 0.2s ease, box-shadow 0.3s ease;
  }
  
  .readerViewNoteItem:hover {
    transform: scale(1.02);
    box-shadow: 0px 4px 12px rgba(28, 234, 160, 0.2);
    margin-left: 1%;
    margin-right: 1%;
    
  }
  
  /* Note Header */
  .NoteHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    padding-bottom: 0.8rem;
    border-bottom: 1px solid #71858b;
    position: relative;
  }

  .NoteId{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

  }
  
  
/* Avatar */
.Avatar {
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
    color: #ffffff;
    background-color: #1ceaa0;
    border-radius: 50%;
}
  
.NoteMetaInfo {
    display: flex;
    flex-direction: column;
    gap: 2px;
}
  
.UserName {
    font-size: 12px;
    font-weight: 600;
    color: #ffffff;
}
  
  .UpdatedTimestamp {
    font-size: 12px;
    font-weight: 400;
    color: #bac6ca;
  }
  
  /* Note Content */
  .NoteSection {
    width: 100%;
    box-sizing: border-box;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    word-break: break-word; /* Ensure text wraps properly */
    flex: 1; /* Let the text take the remaining space */
  }
  
  /* Action Icons */
  .readerViewNoteActions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  
/* Icons in the top-right corner */
.NoteActions {
  display: flex;
  gap: 10px;
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
}

.Timestamps {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 12px;
    color: #bac6ca;
}

/* Created Timestamp */
.CreatedTimestamp {
    display: flex;
    align-items: center;
    gap: 5px;
}

/* Clock Icon */
.ClockIcon {
    font-size: 12px;
    color: #bac6ca;
}

/* Edited Status */
.EditedStatus {
    font-size: 12px;
    /* color: #f39c12; */
    font-weight: 500;
}

/* Note Actions */
.NoteActions {
    display: flex;
    gap: 10px;
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
}
  
  .editIcon,
  .deleteIcon {
    font-size: 12px;
    cursor: pointer;
    transition: color 0.3s ease, transform 0.2s ease;
  }
  

  
  .editIcon {
    color: #1ceaa0;
  }
  
  .editIcon:hover {
    color: #1ceaa0;
    transform: scale(1.1);
  }
  
  .deleteIcon {
    color: white;
  }
  
  .deleteIcon:hover {
    color: white;
    transform: scale(1.1);
  }


  .readerViewNoteContent {
    font-size: 12px;
    font-weight: 400;
    font-family: 'Lato', sans-serif;
    line-height: 18px;
    color: #ffffff;
    word-wrap: break-word; /* Break long words to avoid overflow */
    overflow-wrap: break-word; /* Ensure long words break within the container */
    overflow: hidden; /* Hide content that exceeds parent container */
    text-overflow: ellipsis; /* Add ellipsis (...) for overflowed content */
    padding: 1rem; /* Add padding for consistent spacing */
    box-sizing: border-box; /* Include padding in width/height calculations */
    background-color: transparent; /* Match the parent container background */
    border-radius: 8px; /* Smooth the edges for a polished look */
  }

  .toggleAddButton {
    position: relative;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: flex-start;
    border-width: 0px;
    box-sizing: border-box;
    background: none;
    cursor: pointer;
    font-size: 1.6vh;
  }
  
  .toggleAddButton:hover {
    opacity: 0.7;
    cursor: pointer;
  }


.toggleAddButtonText {
    position: relative;
    color: #dddee0;
    font-weight: 600;
    font-family: Lato;
    /* line-height: 20px; */
    font-size: 1.6vh;
    text-align: center;
    white-space: nowrap;
    box-sizing: border-box;
  }
  .toggleAddButtonText:hover {
    cursor: pointer;
    color: #1ceaa0;
  }
  /* Note Details */
.NoteDetails {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}


.addNoteButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.9vh 1.2vh;
  background-color: #1ceaa0;
  color: #0a0b09;


  border-radius: 14px;
  cursor: pointer;

  align-self: center;

  font-family: 'Lato';
  font-size: 2vh;
  color: #0a0b09;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.addNoteButton:hover {
  background-color: #16c38d;
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(28, 234, 160, 0.3);
}

.addNoteButton:disabled {
  background-color: #71858b;
  color: #bac6ca;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}




.editreaderViewNoteItem {
  width: 99%;
  margin-left: 1%;
  margin-right: 1%;
  background-color: #374c54;
  border: 1px solid #ffffff;
  border-radius: 5px;
  box-sizing: border-box;
  text-align: left;
  padding: 0.4rem; /* Add padding for inner spacing */
  font-size: 14px; /* Adjust font size if needed */
  line-height: 1.4rem;
  font-family: 'Lato';
  color: #ffffff;
  word-break: break-word; /* Ensure text wraps properly */
  resize: none; /* Disable manual resizing */
  overflow: hidden; /* Hide scrollbar */
}

.editreaderViewNoteItem::placeholder {
 

  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  word-break: break-word; /* Ensure text wraps properly */
}

.editreaderViewNoteItem::content {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  word-break: break-word; /* Ensure text wraps properly */
}


.Post {
  /* height: 3.5vh; */

  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: row;
  align-items: center;
  padding: 0.4vh 0.5vw;
  gap: 0.8vh;
  align-self: center;
  cursor: pointer;

justify-content: center;
  border: 2px solid #1ceaa0;
  border-radius: 10px;
  /* background: rgba(28, 234, 160, 0.2); */
}

.Post:hover {
  cursor: pointer;
}
  


.Postext {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 0.8rem;
  line-height: 1rem;
  /* line-height: 24px; */

  color: #ffffff;
}

.part2{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap : 10px;
  /* gap: 10px; */
 
}

.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 500;
  background: linear-gradient(110.45deg, rgba(0, 29, 38, 0.4) 43.18%, rgba(1, 51, 67, 0.4) 110.25%);
  backdrop-filter: blur(3px);
}

.editbox {
  position: absolute;
  width: 50%;
  height: 80%;
  left: 25%;
  top: 10%;
  box-sizing: border-box;
  background: #FFFFFF;

  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
}


.boxsplit {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
}


.title {
  width: 100%;
  height: 10%;
  box-sizing: border-box;

  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;


  border-bottom: 1px solid #DDDEE0;
  order: 0;

}

.titleText {
  font-family: 'Zilla Slab';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;

  color: #0A0B09;

  order: 0;
}


.buttonOut {

  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  justify-content: center;
  align-self: center;
  order: 1;
}

.xbutton {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  justify-content: center;
  align-self: center;
  order: 1;
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: inline;
}

.boxsplitfirstPart{
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  order: 1;
  box-sizing: border-box;
}

.editboxContent {
  width: 100%;
  /* the widt with the dynamic colomn should be 40% */
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  box-sizing: border-box;

}

.DynamicColumnCreator{
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  overflow: auto;
  box-sizing: border-box;
}

.columnOrderContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px;
  border-width: 0px 1px 0px 0px;
  border-style: solid;
  border-color: #dddee0;
  box-sizing: border-box;
  gap: 10px;


}

.secondColumnOrderHeader{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px;
  border-width: 0px 1px 0px 0px;
  border-style: solid;
  border-color: #dddee0;
  box-sizing: border-box;
  gap: 10px;
  overflow-y: auto;
  
}

.firstColumnOrderHeader{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 0px 15px 0px;
  margin-left: 10px;
  box-sizing: border-box;
}

.columnOrderHeader {
  
  align-self: flex-start;
  color: #0a0a09;
  font-size: 20px;
  font-family: Lato;
  line-height: 28px;
  /* text-align: center; */
  /* white-space: nowrap; */
  box-sizing: border-box;
}

.authorFieldName {
  width: 50px;
  flex-shrink: 0;
  font-family: Lato;
  line-height: 20px;
  box-sizing: border-box;
}

.genreFieldName {
  width: 43px;
  flex-shrink: 0;
  margin: 0px 140px 0px 0px;
  font-family: Lato;
  line-height: 20px;
  box-sizing: border-box;
}

.wordCountFieldName {
  width: 87px;
  flex-shrink: 0;
  margin: 0px 96px 0px 0px;
  font-family: Lato;
  line-height: 20px;
  white-space: nowrap;
  box-sizing: border-box;
}

.dateUploadedFieldName {
  width: 104px;
  flex-shrink: 0;
  margin: 0px 79px 0px 0px;
  font-family: Lato;
  line-height: 20px;
  white-space: nowrap;
  box-sizing: border-box;
}

.recommendationFieldName {
  width: 50.62%;
  margin: 0px 60px 0px 0px;
  font-family: Lato;
  line-height: 20px;
  box-sizing: border-box;
}

.assessmentFieldName {
  width: 84px;
  flex-shrink: 0;
  margin: 0px 99px 0px 0px;
  font-family: Lato;
  line-height: 20px;
  box-sizing: border-box;
}

.statusFieldName {
  width: 44px;
  flex-shrink: 0;
  margin: 0px 139px 0px 0px;
  font-family: Lato;
  line-height: 20px;
  box-sizing: border-box;
}

.addColumnContainer {
  width: 56.54%;
  height: 268px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: flex-start;
  margin: 24px 0px 0px 0px;
  box-sizing: border-box;
}

.addColumnHeader {
  width: 148px;
  align-self: flex-start;
  margin: 0px 0px 16px 0px;
  color: #0a0a09;
  font-size: 20px;
  font-family: Lato;
  line-height: 28px;
  text-align: center;
  white-space: nowrap;
  box-sizing: border-box;
}

.addColumnFormName {
  height: 56px;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0px 0px 16px 0px;
  padding: 0px 0px 0px 15px;
  border-width: 1px;
  border-radius: 16px;
  border-style: solid;
  border-color: #c3cad4;
  box-sizing: border-box;
  background-color: #ffffff;
}

.addColumnFormInput {
  width: 118px;
  color: #71858b;
  font-weight: 500;
  font-family: Lato;
  line-height: 24px;
  white-space: nowrap;
  box-sizing: border-box;
}

.addColumnFormType {
  height: 56px;
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px;
  border-width: 1px;
  border-radius: 16px;
  border-style: solid;
  border-color: #c3cad4;
  box-sizing: border-box;
  background-color: #ffffff;
}

.addColumnFormSelect {
  width: 119px;
  flex-shrink: 0;
  color: #71858b;
  font-weight: 500;
  font-family: Lato;
  line-height: 24px;
  white-space: nowrap;
  box-sizing: border-box;
}

.addColumnIcon {
  width: 24px;
  height: 24px;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 4px 0px;
  border-radius: 99px;
  box-sizing: border-box;
  background-color: #f3f4f6;
}

.addColumnExpandIcon {
  width: 16px;
  min-width: 0px;
  min-height: 0px;
  box-sizing: border-box;
}



.buttons {
  width: 100%;
  height: 10%;
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 16px 16px 16px 0px;
  gap: 15px;

  border-top: 1px solid #DDDEE0;

  /* Inside auto layout */

  order: 2;

}

.cancelButtton {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;
  gap: 8px;

  background: #FFFFFF;
  /* Storywise/Jet Stream */
  cursor: pointer;

  border: 2px solid #BAC6CA;
  border-radius: 14px;

  /* Inside auto layout */

  order: 0;
}

.cancelButtton:hover {
  opacity: 0.5;
}

.cancelButttonText {

  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  display: flex;
  align-items: center;
  text-align: center;

  /* Storywise/Vampire Black */

  color: #0A0B09;


  /* Inside auto layout */

  order: 1;
}

.saveButtton {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 10px;
  gap: 8px;

  background: #1CEAA0;
  border-radius: 14px;
  cursor: pointer;


  order: 1;
}

.saveButtton:hover {
  opacity: 0.8;
}

.saveButttonText {

  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;

  display: flex;
  align-items: center;
  text-align: center;

  /* Storywise/Vampire Black */

  color: #0A0B09;


  /* Inside auto layout */

  order: 1;
}

/* 

.genre{
  border-radius: 8px;
} */
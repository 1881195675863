.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;

  /* //    width: 21.7vw; */
}

.logosignincontainer {
  height: 100vh;
  width: 78.3vw;

  position: fixed;
  right: 0px;
  top: 0px;
  box-sizing: border-box;
  overflow: auto;
}

.signcontainer {
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 4vh 3vw 2vh 3vw;
}

.hedder {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.steps {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1vw;
  /* margin-top: 5vh; */
}

.step1 {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1vw;
  /* margin-top: 5vh; */
}

.step1circle {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.5vh;
  background: linear-gradient(180deg, #001d26 0%, #013343 100%);
  overflow: hidden;
  background-size: cover;
  background-position: 50% 50%;
  width: 5.8vh;
  height: 5.8vh;
}

.step1text {
  color: var(--storywise-vampire-black, #0a0b09);
  font-family: 'Zilla Slab';
  font-size: 1.4vh;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6vh; /* 123.077% */
}

.step1text1 {
  color: var(--storywise-vampire-black, #0a0b09);
  font-family: 'Lato';
  font-size: 1.6vh;
  font-style: normal;
  font-weight: 700;
  line-height: 2.4vh; /* 150% */
}

.men {
  width: 2.4vh;
  height: 2.4vh;
}

.step2circle {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.5vh;
  background: linear-gradient(180deg, #001d26 0%, #013343 100%);
  overflow: hidden;
  background-size: cover;
  background-position: 50% 50%;
  width: 5.8vh;
  height: 5.8vh;
}

.menstep2 {
  width: 2.4vh;
  height: 2.4vh;
}

.step3circle {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.5vh;
  overflow: hidden;
  width: 5.8vh;
  height: 5.8vh;
  background: #ececec;
}

.menstep3 {
  width: 2.4vh;
  height: 2.4vh;
}

.signup {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5vw;
}

.backtologin {
  color: #000;
  font-family: 'Lato';
  font-size: 1.6vh;
  font-style: normal;
  font-weight: 500;
  line-height: 2vh;
  /* 125% */
}

.linkto {
  color: #2f80ed;
  font-family: 'Lato';
  font-size: 1.6vh;
  font-style: normal;
  font-weight: 600;
  line-height: 2vh;
  /* 125% */
  text-decoration-line: underline;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1.5vh;
  width: 100%;
  padding-top: 4.5vh;
  flex: 1;
}

.titlelogo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  /* gap: 0.5vw; */
}

.loginparttitle {
  display: flex;
  color: #0a0b09;
  text-align: left;
  font-family: 'Zilla Slab';
  font-size: 5vh;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.loginparttitlepart2 {
  display: flex;
  color: #0a0b09;
  text-align: left;
  font-family: 'Zilla Slab';
  font-size: 5vh;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.loginpartsubtitle {
  background: linear-gradient(180deg, #001d26 0%, #013343 100%);
  background-clip: text;
  -webkit-background-clip: text;
  font-family: 'Lato';
  font-size: 1.8vh;
  font-style: normal;
  font-weight: 500;
  line-height: 2.4vh;
  margin-bottom: 3.8vh;
}

.linktomail {
  color: #2f80ed;
  font-family: 'Lato';
  font-size: 1.8vh;
  font-style: normal;
  font-weight: 500;
  line-height: 2.4vh;
  text-decoration: none;
}

.genres_container {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  gap: 2vh;
  flex-wrap: wrap;
}

.titles {
  color: var(--storywise-vampire-black, #0a0b09);
  font-family: 'Zilla Slab';
  font-size: 2vh;
  font-style: normal;
  font-weight: 700;
  line-height: 2.8vh; /* 140% */
  white-space: nowrap;
  margin-top: 1vh;
}

.description {
  color: var(--storywise-vampire-black, #0a0b09);
  font-family: 'Zilla Slab';
  font-size: 1.5vh;
  font-style: normal;
  font-weight: 700;
  white-space: nowrap;
  margin-top: -1vh;
}

.titlesword {
  color: var(--storywise-vampire-black, #0a0b09);
  font-family: 'Zilla Slab';
  font-size: 2vh;
  font-style: normal;
  font-weight: 700;
  line-height: 2.8vh; /* 140% */
  white-space: nowrap;
}

.rangeIndicator {
  color: var(--storywise-vampire-black, #0a0b09);
  font-family: 'Zilla Slab';
  font-size: 2vh;
  font-style: normal;
  font-weight: 700;
  line-height: 2.8vh; /* 140% */
  white-space: nowrap;
  /* Styling for the range indicator */
  font-size: 2vh;
  font-weight: bold;
  /* Add more styling as needed */
}

.wordCountMin {
  margin-top: 2.5vh;
}

.word-count-container {
  width: 100%;
  display: flex;
  padding-bottom: 0px;
  align-items: center;
  justify-content: flex-start;
  gap: 2.8vh;
  flex-direction: row;
  margin-top: 0.5vh;
}

/* Input */
.input {
  width: 12vw;
  align-self: center;
  height: 5vh;
  display: flex;
  /* padding: 1.2vh; */
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  font-weight: 500;
  font-size: 2vh;
  border-radius: 16px;
  border: 1px solid var(--storywise-gainsboro, #dddee0);
  background: var(--storywise-white, #fff);
}

.input::placeholder {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 1.4vh;
  line-height: 2vh;
  text-align: left;
  color: #a2a4a1;
  align-self: center;
}

.listofcard {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1vw;
}
.listofcardA {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1vw;
  height: auto; /* Changed from 4.8vh to auto to accommodate multiple lines */
  flex-wrap: wrap; /* This allows items to wrap onto the next line */
}

.fotter {
  /* height: 0px; */
  width: 100%;
  border-top: 1px solid #dce0e5;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1vw;
  padding-top: 1vh;
  margin-top: 3vh;
  padding-bottom: 1vh;
  margin-bottom: 1vh;
}

.buttoncontainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1vw;
}

.buttoncontainerback {
  display: flex;
  margin-bottom: 15px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-direction: row;
  cursor: pointer;
}

.buttonBack {
  /* display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-direction: row; */
}

.textbuttonBack {
  color: var(--sass-gray-300, #95a1b2);
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 120% */
}
.button {
  display: flex;
  padding: 1.2vh 1.6vh;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 14px;
  background: var(--storywise-secondary-500, #1ceaa0);
  cursor: pointer;
  min-width: 10vw;
  margin-bottom: 2vh;
}

.textbutton {
  color: var(--storywise-vampire-black, #0a0b09);
  text-align: center;
  font-family: 'Lato';
  font-size: 2vh;
  font-style: normal;
  font-weight: 600;
  line-height: 2.4vh;
  /* 120% */
}

.pagenumber {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  color: var(--sass-gray-300, #95a1b2);
  font-family: 'Zilla Slab';
  font-size: 1.2vh;
  font-style: normal;
  font-weight: 700;
}

.Select {
  width: 100%;
  height: 100%;
  border: 0px;
  border-radius: 16px;
}
.Select:focus {
  outline: none;
  border: 1px solid #0a0b09;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.selectrow {
  width: 10vw;
}

.input:focus {
  outline: none;
  border: 1px solid var(--storywise-secondary-500, #1ceaa0);
}

.pagenumberfirst {
  color: var(--sass-gray-700, #454f5f);
  font-family: 'Zilla Slab';
  font-size: 1.6vh;
  font-style: normal;
  font-weight: 500;
}

.description {
  color: var(--storywise-vampire-black, #0a0b09);
  font-family: 'Zilla Slab';
  font-size: 1.8vh;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 1.5vh;
}

.maindescription {
  color: var(--storywise-vampire-black, #0a0b09);
  font-family: 'Zilla Slab';
  font-size: 2.4vh;
  font-style: normal;
  font-weight: 700;
  /* margin-bottom: 0.5vh; */
}

.Qualification_Categories {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  /* gap: 1vw; */
  flex-wrap: wrap;
}

.categoriesCard1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1vw;
  /* flex-wrap: wrap; */
}

.cat_title {
  display: flex;
  color: #0a0b09;
  text-align: left;
  font-family: 'Zilla Slab';
  font-size: 3vh;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.category_content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1.5vh;
  margin-top: 1.5vh;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100vh;
    width: 21.7vw;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, #001D26 0%, #013343 100%);
}

/* UncoverLeft.module.css */



.left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 6vh;
    margin-right: 2vw;
}

.logo img {
    width: 15vw;
    /* Set the width of the logo image as needed */
    height: auto;
    /* Set the height of the logo image as needed */
}

.text {
    color: #FFFFFF;
    font-family: 'Zilla Slab';
    font-size: 2.5vw;
    font-style: normal;
    font-weight: 400;
    /* line-height: 5vh; */
    letter-spacing: 0em;
    text-align: center;
    margin-top: 10vh;
}


.imageLeft{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5vh;
    /* margin-right: 2vw; */
}
.imageLeft img {

    width: 21.7vw;
    /* Set the width of the left image as needed */
    height: auto;
    /* Let the height adjust proportionally to the width */
}
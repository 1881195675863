.hedder {
  gap: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* align-items: center; */
  box-sizing: border-box;
  background-position: 50% 50%;
  background-size: cover;
  background-image: linear-gradient(110deg, #001c26 118%, #003242 348%);
  height: 6vh;
  width: 100%;
}



.manualHeader{
  gap: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* align-items: center; */
  box-sizing: border-box;
  background-position: 50% 50%;
  background-size: cover;
  background-image: linear-gradient(110deg, #FF0000 118%, #42000e 348%);
  height: 6vh;
  width: 100%;
}

.manualmyManuscripts {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding: 4px 0px; */
  gap: 8px;

  /* Storywise/header btn bg */

  background: linear-gradient(132.64deg, #FF0000 30.86%, #42000e 93.29%);
  /* Storywise/secondary-500 */

  border: 1px solid #FF0000;
  border-radius: 8px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}



.firstPart {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  margin-left: 20px;
  cursor: pointer;
}

.secondPart {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  gap: 1.4vw;
  /* padding-right: 1.4vw; */
}

.secondPartinsidename {
  /* position: relative; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  align-self: flex-start;
  gap: 0;

  /* margin-right: 1.4vw; */
}

.secondPartinsidename:hover .secondPartTexnamet1 {
  color: #1ceaa0;
}

.secondPartTexnamet {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 0.4vw;
  align-items: center;
  color: var(--storywise-white, #fff);
  font-family: 'Lato';
  font-size: 1.8vh;
  font-style: normal;
  font-weight: 400;
  line-height: 2vh; /* 125% */
  cursor: pointer;
  /* padding: 0.50vh 0 0.80vh 0; */
}

.secondPartTexnamet1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 0.4vw;
  align-items: center;
  color: var(--storywise-white, #fff);
  font-family: 'Lato';
  font-size: 1.8vh;
  font-style: normal;
  font-weight: 400;
  cursor: pointer;
  /* padding: 1vh 0.2vw 1.6vh 0.2vw; */
  /* background: linear-gradient(110deg, #001c26 118%, #003242 348%); */
  width: 100%; /* Make it take the full width */
  height: 6vh;
  box-sizing: border-box;
  margin: 0;
  padding-left: 1vw;
  padding-right: 1vw;

  /* height: 6vh; */
}

.deff {
  cursor: pointer;
  color: white; /* Default color */
}

.deff:hover {
  color: #1ceaa0; /* Change color on hover */
  background-color: gray;
}

.logoutOptions {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  background: linear-gradient(
    110deg,
    rgba(0, 28, 38, 1) 118%,
    rgba(0, 50, 66, 1) 348%
  );
  width: 100%; /* Make it take the full width */
  padding: 10px 0;
  border-top: 1px solid #1ceaa0;
  z-index: 100;
  cursor: pointer;
  /* padding: 1vh 0.2vw 1.6vh 0.2vw; */
  z-index: 400;
  color: var(--storywise-white, #fff);
  font-family: 'Lato';
  font-size: 1.8vh;
  font-style: normal;
  font-weight: 400;
  cursor: pointer;
  height: 6vh;
  padding-left: 1vw;
  padding-right: 1vw;
}

.expenduser {
  margin-top: 4%;
}

.hedderTitleImg {
  width: 41px;
  height: 35px;
  position: relative;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 0px 3.38px 0px 0px;
  /* padding: 8.57px 11.6px 14.2px 11.6px; */
  box-sizing: border-box;
}

.myManuscripts {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding: 4px 0px; */
  gap: 8px;

  /* Storywise/header btn bg */

  background: linear-gradient(132.64deg, #001d26 30.86%, #013343 93.29%);
  /* Storywise/secondary-500 */

  border: 1px solid #1ceaa0;
  border-radius: 8px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.myM {
  position: relative;
  width: 20px;
  height: 20px;
  left: 0px;
  top: 0px;
}

.tomyManuscripts {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding: 4px 0px; */
  gap: 8px;

  /* Storywise/header btn bg */

  background: linear-gradient(132.64deg, #001d26 30.86%, #013343 93.29%);
  /* Storywise/secondary-500 */

  border: 1px solid #71858b;
  border-radius: 8px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.tomyManuscripts:hover {
  border: 1px solid #1ceaa0;
  border-radius: 8px;
}

.settingsButton {
  margin-left: 10px;
}

.hedderTitle {
  min-width: 0px;
  min-height: 0px;
  position: relative;
  margin: 0px 40px 0px 0px;
  box-sizing: border-box;
  font-family: zilla slab;
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
  line-height: 40px;
  white-space: nowrap;
  box-sizing: border-box;
}

.totleManu {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  /* Storywise/White */

  color: #ffffff;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

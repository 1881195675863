.keywordContainer {
  margin-left: -4px; /* removed margin to the left becuase the keywords in the grid have a margin so they start 4px to the right*/
}

.keyword {
  padding: 10px 12px;
  margin: 4px;
  display: inline-block;
  background-color: #e9e7e7;
  border-radius: 10px;
  font-family: 'Lato';
  font-size: 12px;
  cursor: pointer;
}

.regenerate {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.regenerateIcon {
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  margin-right: 5px;
  cursor: pointer;
}

.regenerateText {
  font-family: 'Lato';
  font-size: 12px;
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
}

input {
  height: 12px;
  padding: 10px;
  border: solid 1px #e9e7e7;
  border-radius: 10px;
  font-family: 'Lato';
  font-size: 12px;
}

.noneToSuggest {
  font-family: 'Lato';
  font-size: 16px;
  font-style: italic;
  color: #a2a4a1;
  margin: 0 5px 10px;
}

.ownKeywordInput {
  border: solid 1px #1ceaa0;
  outline: none;
}

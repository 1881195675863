/* .FrameRootRoot {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 28px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.chapterTitle {
  position: relative;
  align-self: flex-start;
  margin: 0px 0px 0px 0px;
  font-size: 24px;
  font-weight: 600;
  font-family: Zilla Slab;
  line-height: 32px;
  white-space: nowrap;
  box-sizing: border-box;
}

.ChapterText {
  border: 0px;
  width: 100%;
  position: relative;
  font-family: Lato;
  line-height: 24px;
  text-align: justify;
  box-sizing: border-box;
  overflow: auto;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
  cursor: default;
  user-select: text;
  
}
.ChapterText:focus {
  outline: none;
}
.textEditor {
  border: 1px solid black;
  min-height: 100px;
  padding: 10px;
  background-color: #f2f2f2;
  cursor: default;
  user-select: text;
}

.highlightedText {
  border: 1px solid black;
  min-height: 100px;
  padding: 10px;
  background-color: rgba(99, 217, 255, 0.2);
  cursor: default;
  user-select: text;
}

.contextMenu {
  position: fixed;
  background-color: white;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  padding: 5px;
}


.buttonManu1{
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-width: 0px 0px 0px 1px;
  border-radius: 8px 0px 0px 8px;
  border-style: solid;
  border-color: #71858b;
  box-sizing: border-box;
  background-color: #0a0a09;
  padding: 10px 10px 10px 10px; 
  cursor: pointer;

}

.buttonManu2{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-width: 0px 0px 0px 1px;
  border-style: solid;
  border-color: #71858b;
  box-sizing: border-box;
  background-color: #0a0a09;
  padding: 10px 10px 10px 10px; 
  cursor: pointer;


}

.buttonManu3{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-width: 0px 0px 0px 1px;
  border-radius: 0px 6px 6px 0px;
  border-style: solid;
  border-color: #71858b;
  box-sizing: border-box;
  background-color: #0a0a09;
  padding: 10px 10px 10px 10px; 
  cursor: pointer;

}

.buttonManutext{
  position: relative;
  color: #ffffff;
  font-weight: 500;
  font-family: Lato;
  line-height: 28px;
  white-space: nowrap;
  box-sizing: border-box;
}

.buttonManu1:hover{
  opacity: 0.9;
}

.buttonManu2:hover{
  opacity: 0.9;
}
.buttonManu3:hover{
  opacity: 0.9;
} */


.FrameRootRoot {
  position: relative;
  /* gap: 5px; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 28px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.chapterTitle {
  position: relative;
  align-self: flex-start;
  margin: 0px 0px 0px 0px;
  font-size: 24px;
  font-weight: 600;
  font-family: Zilla Slab;
  line-height: 32px;
  box-sizing: border-box;
}

.ChapterText {
  border: 0px;
  width: 100%;
  position: relative;
  font-family: Lato;
  line-height: 24px;
  text-align: justify;
  box-sizing: border-box;
  overflow: auto;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
  cursor: default;
  user-select: text;
  
}
.ChapterText:focus {
  outline: none;
}

.contextMenu {
  position: fixed;
  background-color: white;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  padding: 5px;
}


.buttonManu1{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-width: 0px 0px 0px 1px;
  border-style: solid;
  border-color: #71858b;
  box-sizing: border-box;
  background-color: #0a0a09;
  padding: 10px 10px 10px 10px; 
  cursor: pointer;
  border-radius:8px 0px 0px 8px;


}

.buttonManu1:hover{
  opacity: 0.95;
}


.buttonManu2{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-width: 0px 0px 0px 1px;
  border-style: solid;
  border-color: #71858b;
  box-sizing: border-box;
  background-color: #0a0a09;
  padding: 10px 10px 10px 10px; 
  cursor: pointer;
 
}

.buttonManu2:hover{
  opacity: 0.95;
}

.buttonManu3{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-width: 0px 0px 0px 1px;
  border-style: solid;
  border-color: #71858b;
  box-sizing: border-box;
  background-color: #0a0a09;
  padding: 10px 10px 10px 10px; 
  cursor: pointer;
  border-radius:0px 8px 8px 0px;
 


}

.buttonManu3:hover{
  opacity: 0.95;
}

.buttonManutext{
  position: relative;
  color: #ffffff;
  font-weight: 500;
  font-family: Lato;
  line-height: 28px;
  white-space: nowrap;
  box-sizing: border-box;
}

.cardContainer {
  overflow: auto;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin: 5px 2px 5px 2px;
  border-radius: 8px;
  box-sizing: border-box;
  background-color: #f0f4f5;
  box-shadow: 2px 2px 8px 0px rgba(200, 200, 200, 0.12);
  /* height: 100px; */
  }
  
  .genre {
    position: relative;
    font-size: 18px;
    font-weight: 500;
    font-family: Lato;
    line-height: 20px;
    box-sizing: border-box;
  }
  
  .deleteButton {
    border-color: none;
    border-width: 0px;
    position: relative;
    flex-shrink: 0;
    box-sizing: border-box;
  }

  .deletexmark{
    width: 25.2px;
    min-width: 0px;
    min-height: 0px;
    position: relative;
    flex-shrink: 0;
    box-sizing: border-box;
  }
  
.cardContainer {
  width: 100%;
  overflow: auto;
  position: relative;
  /* gap:px; */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: start;
  padding: 10px;
  gap: 8px;
  border-width: 1px;
  border-radius: 8px;
  border-style: solid;
  border-color: #dddee0;
  box-sizing: border-box;
}

.cardContainer:hover {
  background-color: #f2f2f2;
  cursor: pointer;
  border: 1px solid #1ceaa0;
  border-radius: 8px;
}

.cardImage {
  width: 90px;
  min-width: 0px;
  min-height: 0px;
  position: relative;
  flex-shrink: 0;
  margin: 0px 15px 0px 0px;
  box-sizing: border-box;
  padding-top: 8px;
}

.cardInfo {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  border: 10px;
  padding-top: 8px;
  padding-bottom: 2px;
  /* flex:5; */
}

.cardTitle {
  width: 100%;
  position: relative;
  gap: 6px;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  border: 1px;
  flex-wrap: wrap;
}

.BookName {
  position: relative;
  color: #0a0a09;
  font-weight: 600;
  font-family: Zilla Slab;
  font-size: large;
  line-height: 20px;

  box-sizing: border-box;
  /* white-space: break-spaces; */
}

.BookAuthor {
  position: relative;
  align-self: flex-end;
  color: #0a0a09;
  font-size: 12px;
  font-family: Zilla Slab;
  line-height: 16px;
  /* white-space: nowrap; */
  box-sizing: border-box;
}
.isbntext {
  position: relative;
  color: #0a0a09;
  font-size: 12px;
  font-family: Zilla Slab;
  line-height: 16px;
  /* white-space: nowrap; */
  box-sizing: border-box;
  font-weight: 300;
  /* inline-block */
  display: inline-block;
  gap: 5px;
}

.isbn {
  position: relative;
  justify-content: flex-start;
  margin: 0px 0px 8px 0px;
  color: #0a0a09;
  font-size: 12px;
  font-weight: 600;
  font-family: Zilla Slab;
  line-height: 16px;
  white-space: nowrap;
  box-sizing: border-box;
}

.bookGenres {
  width: 100%;
  position: relative;
  display: inline-block;
  flex-direction: row;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  margin: 0px 0px 12px 0px;
  box-sizing: border-box;
}

.genre {
  position: relative;
  display: inline-block;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: flex-start;
  padding: 2px 6px;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #d4efff;
  margin-right: 10px;
  margin-bottom: 10px;
}

.genreName {
  position: relative;
  color: #0a0a09;
  font-size: 14px;
  font-family: Lato;
  line-height: 16px;
  box-sizing: border-box;
}

.linkToBuy {
  position: relative;

  text-align: center;
  white-space: nowrap;
  box-sizing: border-box;
  /* flex:1; */
}

.machPrecent {
  width: 20%;
  flex: 1; /* Take remaining space */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  align-items: flex-end; /* Align to the right */
  padding: 0px 0px 0px 0px;
  box-sizing: border-box;
  /* flex:3; */
}

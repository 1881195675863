.container {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  /* //    width: 21.7vw; */

}
  /* Error Message */
  .error-message {

    color: var(--storywise-red, #FF0000);
    font-family: 'Lato';
    font-size: 1.8vh;
    font-style: normal;
    font-weight: 500;
    line-height: 2.4vh;
    text-align: center;
    /* 120% */
  }


.logocontainer {
 
  height: 100vh;
  width: 78.3vw;
  
  position: fixed;
  right: 0px;
  top: 0px;
  margin-right: 5vw;
  box-sizing: border-box;
  position: relative;
  /* //    width: 21.7vw; */

}

.logincontainer{
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-start;
}

.linktosignup {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 5vh;
  right: 30%;
  width: 30vw;
  gap: 2vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5vw;


}

.beforelink {
  color: #000;
  font-family: 'Lato';
  font-size: 1.6vh;
  font-style: normal;
  font-weight: 500;
  line-height: 2vh;
  /* 125% */
}

.linkto {
  color: #2F80ED;
  font-family: Lato;
  font-size: 1.6vh;
  font-style: normal;
  font-weight: 600;
  line-height: 2vh;
  /* 125% */
  text-decoration-line: underline;

}

.loginpart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 25vh;
  right: 30%;
  width: 30vw;
  height: 45vh;
  gap: 2vh;
  box-sizing: border-box;

}


.loginparttitle{
  color: var(--storywise-vampire-black, #0A0B09);
text-align: center;
font-family: 'Zilla Slab';
font-size: 5.2vh;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.loginpartsubtitle{
  color: var(--storywise-vampire-black, #0A0B09);
  width: 95%;
  text-align: center;
font-family: 'Lato';
font-size: 1.6vh;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 133.333% */
}

.formpart{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content:center;
  gap: 1vh;
  width: 100%;
  /* height: 100%; */
  padding: 1vh;


}

  /* Labels */
  .label {
    margin-top: 1vh;
    color: #0A0B09 ;
    font-family: 'Lato';
    font-size: 1.8vh;
    font-style: normal;
    font-weight: 700;
    /* line-height: 2.2vh; */
    text-align: left;
    padding-left: 0.5vw;
    /* line-height: 3vh; */
    /* 142.857% */
  }
  
  /* Input */
  .input {
    display: flex;
    padding: 1.2vh;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    font-weight: 500;
    font-size: 1.8vh;
    border-radius: 16px;
    border: 1px solid var(--storywise-gainsboro, #DDDEE0);
    background: var(--storywise-white, #FFF);
  }


/* Valid input style */
.input.valid {
  border: 1px solid var(--storywise-gainsboro, #DDDEE0);
}

/* Invalid input style */
.input.invalid {
  border: 1px solid var(--storywise-red, #FF0000);
}

/* Invalid input focus style */
.input.invalid:focus {
  border: 1px solid var(--storywise-red, #FF0000);
  outline: none; /* Remove the default focus outline */
}

/* Invalid input hover style */
.input.invalid:hover {
  border: 1px solid var(--storywise-red, #FF0000);
}

/* style for tha placeholder of the input */
.input::placeholder {

  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 1.4vh;
  line-height: 2vh;
  text-align: left;


  color: #a2a4a1;

}



.lastrow{
  /* margin-top: 0.5vh; */
  box-sizing: border-box;
  width: 100%;


}

.loginbutton{
  border-radius: 14px;
background: var(--storywise-secondary-500, #1CEAA0);
display: flex;
padding: 1.2vh 1.6vh;
margin: 1.2vh 0vh ;
justify-content: center;
align-items: center;
gap: 8px;
/* width: 100%; */

}

.loginbuttontext{
  color: var(--storywise-vampire-black, #0A0B09);
text-align: center;
font-family: 'Lato';
font-size: 2vh;
font-style: normal;
font-weight: 600;
line-height: 2.4vh; /* 120% */
cursor: pointer;
}

  .loginbutton:hover{
opacity: 0.8;    }




.input:focus {
  outline: none;
  border: 1px solid var(--storywise-secondary-500, #1CEAA0);
}



  
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 500;
    background: rgba(0, 0, 0, 0.75);
  }
  
  
  .container {
  
    position: fixed;
    border-radius: 12px;
    box-sizing: border-box;
    background-color: #ffffff;
    box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.08);
    top: 35%;
    left: 35%;
    width: 30%;
    height: 30%;
    z-index: 100;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;
    }
    .TopSection {
      width: 100%;
      height: 20%;
      position: relative;
      display: flex;
      gap: 25px;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      /* margin: 0px 0px 8px 0px; */
      padding: 0px 20.5px 0px 16px;
      border-width: 0px 0px 1px 0px;
      border-style: solid;
      border-color: #dddee0;
      box-sizing: border-box;
    }
    .titleTEXT {
      position: relative;
      margin: 1px 0px 0px 0px;
      color: #0a0a09;
      font-size: 3.2vh;
      font-weight: 700;
      font-family: Zilla Slab;
      line-height: 40px;
      white-space: nowrap;
      box-sizing: border-box;
    }
    .buttonOut {
      width: 15.1px;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      box-sizing: border-box;
      background-size: cover;
    }
    .xbutton {
      width: 15.1px;
      min-width: 0px;
      min-height: 0px;
      position: relative;
      box-sizing: border-box;
      background-color: white;
      border: 0px;
    }
    .MiddleSectionall{
      width: 80%;
      height: 60%;
      position: relative;
      
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0px 0px 0px 0px;
      box-sizing: border-box;
      /* padding: 10px 20px 0px 20px; */
      padding: 2vh 1vw 2vh 1vw;
      overflow-y: auto;

      color: #0a0a09;
      font-size: 2.4vh;
      font-weight: 600;
      font-family: Zilla Slab;
      line-height: 3vh;
      box-sizing: border-box;
      text-align: center;
  
    }




.ButtonSection {
    width: 100%;
    height: 20%;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 15px 16px;
    border-width: 0px;
    border-top-width: 1px;
    border-right-width: 0px;
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-style: solid;
    border-color: #dddee0;
    box-sizing: border-box;
    background: white;
    gap: 15px;
  
  }
  /* .ButtonSection:hover {
    opacity: 70%;
  } */
  .YesButtonContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0px 0px 1px 0px;
    padding: 0.6vh 1.4vh;
    border-width: 2px;
    border-radius: 14px;
    border-style: solid;
    border-color: #f45370;
    box-sizing: border-box;
  }
  
  .YesButtonContainer:hover {
    opacity:0.8 ;
    cursor: pointer;
  
  }
  
  .NoButtonContainer:hover {
    opacity:0.8 ;
    cursor: pointer;
  
  }
  .yesButton {
    position: relative;
    color: #0a0a09;
    font-size: 1.2rem;
    font-weight: 600;
    font-family: Lato;
    text-align: center;
    white-space: nowrap;
    box-sizing: border-box;
  }
  .NoButtonContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1vh 1.5vh;
    border-radius: 14px;
    box-sizing: border-box;
    background-color: #1ce99f;
  }
  .noButton {
    position: relative;
    color: #0a0a09;
    font-size: 1.2rem;
    font-weight: 600;
    font-family: Lato;
    text-align: center;
    white-space: nowrap;
    box-sizing: border-box;
  }
  
  
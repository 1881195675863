/* ComparisonItems.module.css */
.container {
  position: relative;
  /* gap: 12px; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 10px 10px 10px;
  border-width: 0px 0px 0px 0px;
  border-style: solid;
  border-color: #dddee0;
  height: 100%;
  width: 100%;
  overflow-y: hidden;
  box-sizing: border-box;
}

.titleWrapper {
  position: sticky;
  top: 0;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-top: 24px;
  /* padding-bottom: 14px; */
  background-color: #fff;
 }


.title {
  align-self: flex-start;
  margin: 0px 0px 12px 0px;
  color: #0a0a09;
  font-size: 24px;
  font-weight: 600;
  font-family: Zilla Slab;
  line-height: 32px;
  white-space: nowrap;
  margin-left: 6px;
  box-sizing: border-box;
}

.compareButton {
  font-size: 16px;
  font-weight: 600;
  padding: 8px 8px;
  font-family: "Lato";
  color: gray;
  border-width: 1px;
  border-style: solid;
  border-color: gray;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 5px;
  box-sizing: border-box;
}

.compareButton:hover {
  color: black;
  border-color: black;
  opacity: 90%;
}

.groups {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 12px;
  box-sizing: border-box;
  gap: 5px;
  overflow-x: auto;
}

.group {
  position: relative;
  width: 50%;
  box-sizing: border-box;
}

.Buttons {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0px;
  gap: 16px;
  box-sizing: border-box;
  align-items: center;
}

.resetButton:hover {
  color: black;
  border-color: black;
  opacity: 90%;
}

.resetButton {
  box-sizing: border-box;

  /* Auto layout */
  
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 6px;
  gap: 8px;
  
  
  background: #FFFFFF;
  /* Storywise/Jet Stream */
  
  border: 2px solid #BAC6CA;
  border-radius: 10px;

}

.reset {
  width: 20px;
  height: 20px;
}


.compareButton{

  box-sizing: border-box;

  /* Auto layout */
  
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;
  border-width: 0px;
  border-radius: 10px;
  box-sizing: border-box;
  background-color: #1ce99f;
  cursor: pointer;
}

.compareButton:hover{
  box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);

}
.compareText{
 
  position: relative;
  color: #0a0a09;
  font-weight: 600;
  font-family: Lato;
  line-height: 20px;
  text-align: center;
  box-sizing: border-box;

color: #0A0B09;

}
.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;

  }
  
  .heading {
    font-size: 24px;
    margin-bottom: 10px;
    align-self: center;
    text-align: center;
  }
  
  .subHeading {
    font-size: 20px;
    margin-top: 20px;
  }
  
  .paragraph {
    font-size: 16px;
    margin-top: 10px;
  }
  
  /* Define styles for other elements similarly */
  
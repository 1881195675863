.card {
  display: flex;
  padding: 12px 8px;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 8px;
  background: #1C2E33;
  gap: 8px;
  box-sizing: border-box;
  cursor: pointer;
}


.icon {
  max-width: 20px;
  max-height: 20px;
  /* overflow: hidden; */
}

.platform {
  color: var(--storywise-gainsboro, #DDDEE0);
  font-family: Zilla Slab;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
}

.vi {
  display: flex;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
}

.info {
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
}
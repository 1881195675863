.container {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    overflow: auto;

    /* //    width: 21.7vw; */

}


.logocontainer {
   
    height: 100vh;
    width: 78.3vw;
    
    position: fixed;
    right: 0px;
    top: 0px;
    margin-right: 5vw;
    /* //    width: 21.7vw; */

}

.logincontainer{
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-start;
}

.linktosignup {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.5vw;
    margin-top: 5vh;


}

.beforelink {
    color: #000;
    font-family: 'Lato';
    font-size: 1.6vh;
    font-style: normal;
    font-weight: 500;
    line-height: 2vh;
    /* 125% */
}

.linkto {
    color: #2F80ED;
    font-family: Lato;
    font-size: 1.6vh;
    font-style: normal;
    font-weight: 600;
    line-height: 2vh;
    /* 125% */
    text-decoration-line: underline;

}

.loginpart {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 25vh;
    width: 28vw;
    height: 45vh;
    gap: 2vh;
  
}

.titlelogo {
    
    /* width: 100%; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.5vw;
    /* gap: 10vw; */
    /* margin-top: 5vh; */
    /* height: 10vh;
    width: 100vw; */
    /* //    width: 21.7vw; */

}

.loginparttitle {
    display: inline;
    color: #0A0B09;
    text-align: center;
    font-family: 'Zilla Slab';
    font-size: 5vh;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-right: 1vw;
}

.loginparttitlepart2 {
    display: inline;

    color: #0A0B09;
    font-family: 'Zilla Slab';
    font-size: 5vh;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}



.loginpartsubtitle{
    background: linear-gradient(180deg, #001D26 0%, #013343 100%);
    background-clip: text;
-webkit-background-clip: text;
font-family: 'Lato';
font-size: 1.8vh;
font-style: normal;
font-weight: 500;
line-height: 2.4vh;
}


.linktomail {
    color: #2F80ED;
    font-family: 'Lato';
    font-size: 1.8vh;
    font-style: normal;
    font-weight: 500;
    line-height: 2.4vh;
    text-decoration: none;
  }
  

  .formpart{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content:center;
    gap: 1vh;
    width: 100%;
    height: 100%;
    padding: 1vh;
  

  }

  /* Labels */
.label {
    margin-top: 1.5vh;
    color: #0A0B09 ;
    font-family: 'Lato';
    font-size: 1.8vh;
    font-style: normal;
    font-weight: 700;
    /* line-height: 2.2vh; */
    text-align: left;
    padding-left: 0.5vw;
    /* line-height: 3vh; */
    /* 142.857% */
  }
  
  /* Input */
  .input {
    display: flex;
    padding: 1.2vh;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 16px;
    font-weight: 500;
    font-size: 2vh;

    border: 1px solid var(--storywise-gainsboro, #DDDEE0);
    background: var(--storywise-white, #FFF);
  }
  
    /* .input:focus-within {
        border: 1px solid var(--storywise-blue, #2F80ED);
    } */

    .lastrow{
        /* margin-top: 0.5vh; */
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: 1vw;
    }
  /* Forgot Password */
  .forgot-password {
    color: var(--storywise-vampire-black, #0A0B09);
    text-align: center;
    font-family: 'Lato';
    font-size: 2vh;
    font-style: normal;
    font-weight: 500;
    line-height: 2.4vh; /* 120% */
    text-decoration: none;
    cursor: pointer;
    /* 120% */
  }
  
  /* Error Message */
  .error-message {

    color: var(--storywise-red, #FF0000);
    font-family: 'Lato';
    font-size: 1.8vh;
    font-style: normal;
    font-weight: 500;
    line-height: 2.4vh;
    text-align: center;
    /* 120% */
  }

  .loginbutton{
    border-radius: 14px;
background: var(--storywise-secondary-500, #1CEAA0);
display: flex;
padding: 1.2vh 1.6vh;
justify-content: center;
align-items: center;
gap: 8px;
min-width: 10vw;
  }

  .loginbuttontext{
    color: var(--storywise-vampire-black, #0A0B09);
text-align: center;
font-family: 'Lato';
font-size: 2vh;
font-style: normal;
font-weight: 600;
line-height: 2.4vh; /* 120% */
cursor: pointer;
  }

    .loginbutton:hover{
opacity: 0.8;    }

/* style for tha placeholder of the input */
.input::placeholder {

    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 1.4vh;
    line-height: 2vh;
    text-align: left;
  
  
    color: #a2a4a1;
  
  }

  .input:focus {
    outline: none;
    border: 1px solid var(--storywise-secondary-500, #1CEAA0);
}


.privacyLink{
    
  color: #2F80ED;
  font-size: 1.2vh;
  font-style: normal;
  font-weight: 500;
  line-height: 2vh;
}

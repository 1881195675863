
.allcontainer{
  
  height : 100%;
  width : 100%;
  position: relative;
  box-sizing: border-box
}
.container {
  position: relative;
  gap: 12px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 10px 10px 10px;
  border-width: 0px 0px 0px 1px;
  border-style: solid;
  border-color: #dddee0;
  height: 100%;
  /* Set container height to viewport height */
  overflow-y: auto;
  /* Enable vertical scrolling */
  box-sizing: border-box;
}
/* 
.container::-webkit-scrollbar {
  width: 4px;
  background-color: #f2f2f2;
}

.container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 3px;
} */
.loadingContainer{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 20px 10px 10px 10px;
  align-items: center;
  gap : 8px;
}
.titleWrapperloading{
 display: flex;
  flex-direction: column;
  justify-content:flex-start;
  width: 98%;

  background-color: #fff;

  gap : 8px;
}

.text{
 
  /* border-radius: 12px; */
  overflow: hidden;

  gap : 8px;
}

.MuiSkeleton-rectangular {
  border-radius: 12px;
  background: linear-gradient(104deg, rgba(240, 244, 245, 0.60) 0%, rgba(221, 222, 224, 0.00) 100%);
}

.titleWrapper {
  position: sticky;
  top: 0;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-top: 24px;
  padding-bottom: 5px;
  background-color: #fff;
  /* Set your preferred background color */
  /* border-width: 0px 0px 1px 0px;
  border-style: solid;
  border-color: #dddee0; */
  z-index: 100;
}

.title {
  align-self: flex-start;
  margin: 0px 0px 12px 0px;
  color: #0a0a09;
  font-size: 24px;
  font-weight: 600;
  font-family: Zilla Slab;
  line-height: 32px;
  white-space: nowrap;
  box-sizing: border-box;
  
}

.saveButton {
  font-size: 16px;
  font-weight: 600;
  font-family: "Lato";
  padding: 8px 16px;
  background-color: #0a0a09;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  box-sizing: border-box;
}

.saveButton:hover {
  background-color: #1c1c1c;
  opacity: 70%;
}

.genres {
  width: 100%;
}

.noBooks{
  padding: 2vh;
  align-self: center;
  justify-content: center;
  margin: 0px 0px 12px 0px;
  color: #0a0a09;
  font-size: 24px;
  font-weight: 600;
  font-family: Zilla Slab;
  line-height: 32px;
  box-sizing: border-box;
  text-align: center;
  /* margin-top: 30%; */
  ;
}

.noBookscard{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 4px 8px;
  border-radius: 8px;
  box-sizing: border-box;
  background-color: #d2ffd2;
  margin: 5vh;

}
.container {
  width:100%;
  margin: 0 auto;
  padding: 10px;
  /* gap : 20px; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  justify-content: flex-start;
}

.title {
  align-self: flex-start;
  color: #0a0a09;
  font-size: 26px;
  font-family: Lato;
  white-space: nowrap;
  box-sizing: border-box;
  padding: 20px 0px 0px 0px;
}


.subtitle{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0px 20px 0px;
  box-sizing: border-box;
  position: relative;
}
.lable {
  width: 100%;
  position: relative;
  padding: 8px 4px;
  order: 0;
  
}

.tooltipIcon{
  /* width: 30px;
  height: 30px; */
  width: 20px;
 position: relative;
  padding: 0px 0px 0px 0px;
  order: 0;
  /* align-self: flex-end; */
}

.tooltipIcon:hover{
  cursor: pointer;
}
.labelText {

  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;

  color: #0A0B09;
}


.body{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px 0px 0px 0px;
}

.input {
  width: 98%;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* paddinxg: 18px 16px; */

  border: 1px solid #DDDEE0;
  border-radius: 16px;
  padding: 10px 16px;
  font-weight: normal; /* Set the desired font-weight */
  font-size: 14px;

}


.input:focus {
  outline: none;
  font-weight: normal; /* Set the desired font-weight */
  cursor: text;
}

.input:hover {
  border: 1px solid #0A0B09;
  font-weight: normal; /* Set the desired font-weight */
  cursor: text;
  box-shadow: 0px 0px 4px rgba(10, 11, 9, 0.25);
}

.input::placeholder {

  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: left;


  color: #0A0B09;

}

.description{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px 0px 0px 0px;
}

.tooltip{
  position: absolute;
  left: 20%;
  width:60%;
  background-color: #ecf5ec;
  border-radius: 12px;
  border: 1px solid #DDDEE0;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  /* transition: opacity 0.3s ease, visibility 0.3s ease; */
}
.description:hover .tooltip{
  opacity: 1;
  visibility: visible;
}


.tooltipText{
  color: #333;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: #0A0B09;
}
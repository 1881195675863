.containr {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 12px;
  box-sizing: border-box;
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.menu_conten {
  width: 100%;
  height: 91%;
  display: flex;
  box-sizing: border-box;
  overflow: auto;
}

.content {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  height: 100%;
  box-sizing: border-box;
  box-sizing: border-box;
}

.manuscript {
  flex: 6;
  overflow-y: auto;
  padding: 10px;
  height: 100%;
  box-sizing: border-box;
  box-sizing: border-box;
  overflow: auto;
}

.button {
  width: 100%;
  height: 9%;
  position: absolute;
  bottom: 0;

  margin: 0px 0px 1px 0px;
  background: none;
  flex: 1;
  border-radius: 0 0 12px 12px;
}

.MuiDataGrid-cell:focus,
.MuiDataGrid-cell:focus-within,
.MuiDataGrid-columnHeader:focus,
.MuiDataGrid-columnHeader:focus-within {
  outline: none !important;
}

.Mui-disabled-row {
  opacity: 0.5; /* Reduce opacity to visually indicate the row is disabled */
}

.MuiDataGrid-cell[data-field='manuscript_name'] {
  cursor: pointer;
}

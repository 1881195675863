.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 300;
  background: rgba(0, 0, 0, 0.75);
}
.manualbackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 300;
  background: rgba(249, 138, 138, 0.75);
}
.container {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: linear-gradient(110.45deg, #001d26 43.18%, #013343 110.25%);
  box-sizing: border-box;
  z-index: 100;
  /* top: 13vh; */
  right: 0%;
  bottom: 0%;
  width: 50%;
  height: 94.5vh;
}

.manualcontainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: linear-gradient(110.45deg, #9d0202 43.18%, #013343 110.25%);
  box-sizing: border-box;
  z-index: 100;
  /* top: 13vh; */
  right: 0%;
  bottom: 0%;
  width: 50%;
  height: 94.5vh;
}

.header {
  flex: 0 1 auto; /* Take the size of its content */
}

.body {
  flex: 1 1 auto; /* Take the remaining space */
  overflow-y: auto; /* Enable vertical scrollbar if content overflows */
}

.footer {
  flex: 0 1 auto; /* Take the size of its content */
}

.allInfo {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  /* overflow-y: auto; */
  /* padding: 10px 15px 0px 15px; */
  /* gap: 10px; */
}

.bookInfo {
  width: 100%;
  /* height: 20%; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  /* padding: 16px; */
  /* margin: 0px 1.5% 0px 1.5%; */
  /* padding: 10px 10px 0px 10px; */
  padding: 1vh 1vh 0vh 1vh;
  gap: 1vh;
  /* user-select: none; */
  box-sizing: border-box;
  /* overflow-y: auto; */
  order: 0;
}

.bookInfoTitle {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0px;
  gap: 1vh;
  align-self: center;
  border-right: solid 1px rgba(211, 211, 211, 0.2);
  padding-right: 10px;
  flex: 1;
}

.imagePart {
  /* width: 25%; */
  order: 0;
}

.bookInfoImg {
  width: 7vw;
  border-radius: 6px;
  box-sizing: border-box;
}

.AboutBookText {
  /* width: 70%; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1vh;
  gap: 0.05vh;
  order: 1;
  box-sizing: border-box;
}

.xbutton {
  /* width: 5%; */
  order: 2;
  /* justify-self: flex-end; */
  align-self: flex-start;
  padding: 1vh;
  justify-self: flex-end;
  box-sizing: border-box;
}

.xbutton:hover {
  opacity: 0.7;
  cursor: pointer;
}

.bookName {
  font-family: 'Zilla Slab';
  font-style: normal;
  font-weight: 600;
  font-size: 3vh;
  color: #ffffff;
  margin-bottom: 1vh;
  box-sizing: border-box;
  white-space: break-spaces;
}

.by {
  font-family: 'Zilla Slab';
  font-style: normal;
  font-weight: 600;
  font-size: 1.8vh;
  color: #ffffff;
  margin-bottom: 1.5vh;
}

/* 
.status {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 4px 8px;
    gap: 10px;
    background: rgba(239, 231, 44, 0.54);
    border-radius: 8px;

}

.statusText {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;

}
 */

.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0px;
  gap: 1vw;
  margin-top: 1.5vh;
}

.rejectB {
  height: 3.5vh;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: row;
  align-items: center;
  padding: 0.4vh 0.5vw;
  gap: 0.8vh;
  align-self: center;

  border: 2px solid #ed437d;
  border-radius: 10px;
}

.rejectB:hover {
  opacity: 0.8;
  cursor: pointer;
}

.ApproveB:hover {
  opacity: 0.8;
  cursor: pointer;
}

.disLike {
  width: 20px;
  height: 20px;
}

.Rtext {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 1.8vh;
  /* line-height: 24px; */

  color: #ffffff;
}

.Atext {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 1.8vh;
  /* line-height: 24px; */

  color: #ffffff;
}

.ApproveB {
  height: 3.5vh;

  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: row;
  align-items: center;
  padding: 0.4vh 0.5vw;
  gap: 0.8vh;
  align-self: center;

  border: 2px solid #1ceaa0;
  border-radius: 10px;
  /* background: rgba(28, 234, 160, 0.2); */
}

.Like {
  width: 20px;
  height: 20px;
}

.x {
  position: relative;
  width: 24px;
  height: 24px;
  /* margin-left: 25px; */
}

.moreInfo {
  width: 100%;
  height: 100%;
  /* height: 73%;
    overflow-y: auto; */
  overflow-x: auto;
  box-sizing: border-box;
  order: 1;
  overflow-y: auto;
}

.allInfowithoutbutton {
  height: 93%;
  width: 100%;
  overflow-y: auto;
}

.moreInfo2parts {
  width: 100%;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;

  align-self: flex-start;
  align-content: flex-start;
  padding: 1vh;
  gap: 1vh;
}

.moreInfoPart1 {
  width: 100%;
  /* height: 62%; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1vh;
  /* margin: 1.5% 0px 1.5% 0px; */
  padding: 1vh;
  gap: 1vh;

  box-sizing: border-box;

  background: #29383d;
  border-radius: 12px;
  /* overflow-y: auto;
    overflow-x: auto; */
  order: 0;
}

.infoText {
  width: 100%;
  /* height: 60%; */
  position: relative;
  /* height: %; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1vh;
  gap: 0.5vh;
  box-sizing: border-box;
  order: 0;
}

.inforow {
  display: flex;
  flex-direction: row;
  align-items: flex-start; /* Align items to the start of the row */
  justify-content: space-between; /* Ensure title and content are separated */
  padding: 0px;
  gap: 1vh; /* Add space between title and content */
  width: 100%; /* Ensure each row takes full width */
  box-sizing: border-box;
}

.moreInfoTitle {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  white-space: nowrap; /* Prevent wrapping of titles */
  flex: 0 0 20%; /* Allocate fixed width for titles */
}

.TextLogLine {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  word-break: break-word; /* Ensure text wraps properly */
  flex: 1; /* Let the text take the remaining space */
}

.inforowhover {
  display: flex;
  /* or display: inline-flex; depending on your layout needs */
  flex-direction: row;
  padding: 0px;
  gap: 0.4vh;
  box-sizing: border-box;
  align-items: flex-end;
  justify-content: flex-start;
  /* align-items:flex-end ;
    justify-content: flex-start; */
}

.moreInfoTitleTitle {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  white-space: break-spaces;
  margin-top: 0.3px;
  align-self: flex-end;

  align-items: flex-end;
  align-self: flex-end;
  /* Align the text to the bottom of the parent container */
  align-self: flex-end !important;

  color: #ffffff;
}

.TextLogLinehover {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  white-space: break-spaces;
  margin-top: 0.3px;
  align-self: flex-end;

  justify-self: flex-end;
  align-self: flex-end;
  align-self: flex-end;
  /* Align the text to the bottom of the parent container */
  align-self: flex-end !important;

  color: #ffffff;
}

.Textdecription {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6vh;
  /* line-height: 20px; */
  white-space: break-spaces;
  bottom: 0;
  /* Align to the bottom of the container */

  /* or 143% */

  /* Storywise/White */

  color: #ffffff;
  width: 100%;
}

.UpperStstusBar {
  width: 100%;
  box-sizing: border-box;

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* padding: 1vh; */
  padding-top: 2vh;
  border-width: 1px 0px 0px 0px;
  border-style: solid;
  border-color: #71858b;
  order: 1;
  gap: 2vh;
}

.scoreRowItem {
  display: flex;
  width: 100%;
  padding: 1vh 0.4vw 1vh 0.4vw;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  border-radius: 6px;
  background: #212d31;
  box-sizing: border-box;
}

.TitleScore {
  color: var(--Storywise-White, #fff);
  font-family: Zilla Slab;
  font-size: 1.8vh;
  font-style: normal;
  font-weight: 700;
  line-height: 2.4vh;
  /* 133.333% */
  text-transform: capitalize;
}

.scoreValue {
  padding-right: 5px;
}

.parameterItems {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1.5vh;
  box-sizing: border-box;
  width: 100%;
  transition: max-height 0.2s ease-out, opacity 0.2s ease-out;
}

.parameterItem {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1vh;
  box-sizing: border-box;
  width: 100%;
}

.itemTitle {
  display: flex;
  width: 100%;
  padding: 1vh 0.4vw 1vh 0.4vw;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-radius: 6px;
  background: #212d31;
  box-sizing: border-box;
}

.itemTitle:hover .itemkey {
  color: #1ceaa0;
}

.itemkey {
  color: #fff;
  font-family: Lato;
  font-size: 1.6vh;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  display: flex;
  align-items: center;
  gap: 0.5vw;
  flex-direction: row;
  justify-content: flex-start;
  /* 125% */
}

.expandIcon {
  width: 2.6vh;
  height: 2.6vh;
}

.content {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2vh;
  box-sizing: border-box;
  padding: 0.5vh 0.4vw 0.5vh 0.8vw;

  box-sizing: border-box;
}

.subItem {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.5vh;
  box-sizing: border-box;
  width: 100%;
}

.subKey {
  color: #fff;
  font-family: Lato;
  font-size: 1.6vh;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  /* 125% */
}

.subValue {
  color: #fff;
  font-family: Lato;
  font-size: 1.2vh;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.StstusBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
}

.decroptionScore {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  padding-bottom: 0.3vh;
  z-index: 10;
}

.ScoreCard {
  width: 18%;
  /* flex-shrink: 1; */
}

.ScoreCarddecription {
  width: 100%;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.5vh;
  box-sizing: border-box;
  order: 1;
}

.moreInfoPart2 {
  width: 100%;
  /* height: 62%; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1vh;
  /* margin: 1.5% 0px 1.5% 0px; */
  gap: 10px;

  box-sizing: border-box;

  background: #29383d;
  border-radius: 12px;
  /* overflow-y: auto;
    overflow-x: auto; */
  overflow-x: auto;
}

.moreInfoPartautodecline {
  width: 100%;
  /* height: 62%; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1vh;
  /* margin: 1.5% 0px 1.5% 0px; */

  box-sizing: border-box;

  background: #29383d;
  border-radius: 12px;
  /* overflow-y: auto;
    overflow-x: auto; */
  overflow-x: auto;
}

.firstTitle {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  box-sizing: border-box;
}

.shoeAll {
  position: relative;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: flex-start;
  border-width: 0px;
  box-sizing: border-box;
  background: none;
  cursor: pointer;
  font-size: 1.6vh;
}

.shoeAll:hover {
  opacity: 0.7;
  cursor: pointer;
}

.shoeAllText {
  position: relative;
  color: #dddee0;
  font-weight: 600;
  font-family: Lato;
  /* line-height: 20px; */
  font-size: 1.6vh;
  text-align: center;
  white-space: nowrap;
  box-sizing: border-box;
}
.shoeAllText:hover {
  cursor: pointer;
  color: #1ceaa0;
}
.similarBooks {
  width: 100%;
  position: relative;
  gap: 1.2vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  box-sizing: border-box;
}

.similarBook {
  width: 32.02%;
  position: relative;
  gap: 1.2vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.4vh;
  border-radius: 8px;
  box-sizing: border-box;
  background-color: #1c2e33;
  border: 1px solid #1c2e33;
  word-wrap: break-word;
  flex: 1;
}

.similarBook:hover {
  opacity: 0.7;
  cursor: pointer;
  border: 1px solid #1ceaa0;
  border-radius: 8px;
}

.similarBookImg {
  width: 1.9vw;
  min-width: 0px;
  min-height: 0px;
  position: relative;
  flex-shrink: 0;
  box-sizing: border-box;
  order: 0;
}

.similarBooktext {
  position: relative;
  gap: 0.2vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  order: 1;
}

.similarBookName {
  position: relative;
  color: #ffffff;
  font-size: 1.2vh;
  font-weight: 700;
  font-family: Lato;
  /* line-height: 16px; */

  box-sizing: border-box;
  text-wrap: wrap;
}

.similarBookAuthor {
  position: relative;
  align-self: stretch;
  color: #ffffff;
  font-size: 1.2vh;
  font-family: Lato;
  /* line-height: 20px; */
  white-space: nowrap;
  box-sizing: border-box;
}

.lowerBar {
  width: 100%;
  /* position: fixed; */
  bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1vh;
  order: 2;
  align-self: flex-end;
  box-sizing: border-box;
  overflow-x: auto;
  /* margin-top: 10px; */

  background: linear-gradient(110.45deg, #001d26 43.18%, #013343 110.25%);
}

.ViewSynopsis {
  box-sizing: border-box;

  /* Auto layout */
  align-self: center;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.6vh 1.2vh;
  gap: 8px;

  /* Storywise/Stormcloud */

  border: 2px solid #71858b;
  border-radius: 14px;
  background: #001d26;
}

.ViewSynopsis:hover {
  opacity: 0.7;
  cursor: pointer;
  border: 2px solid #1ceaa0;
  border-radius: 14px;
}

.file {
  position: relative;
  width: 20px;
  height: 20px;
  left: 0px;
  top: 0px;
}

.ViewSynopsisText {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 2vh;

  /* line-height: 24px; */
  /* identical to box height, or 120% */

  display: flex;
  align-items: center;
  text-align: center;

  /* Storywise/White */
  white-space: nowrap;
  color: #ffffff;
}

.review {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.9vh 1.2vh;
  gap: 0.8vh;
  align-self: center;

  background: #1ceaa0;
  border-radius: 14px;
}
.manualreview {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.9vh 1.2vh;
  gap: 0.8vh;
  align-self: center;

  background: #ff0000;
  border-radius: 14px;
}

.review:hover {
  opacity: 0.7;
  cursor: pointer;
}

.reviewText {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 2vh;
  /* line-height: 24px; */
  /* identical to box height, or 120% */

  display: flex;
  align-items: center;
  text-align: center;

  /* Storywise/Vampire Black */

  color: #0a0b09;
  white-space: nowrap;
}

.autorinfofoter {
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  flex-direction: row;
  /* overflow-x: auto; */
  box-sizing: border-box;
}

.autorinfoTitle {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: row;
}

.moreInfoPart2authorinfo {
  width: 100%;
  /* height: 62%; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1vh;
  /* margin: 1.5% 0px 1.5% 0px; */
  gap: 10px;

  box-sizing: border-box;

  background: #29383d;
  border-radius: 12px;
  /* overflow-y: auto;
    overflow-x: auto; */
  order: 1;
  overflow-x: auto;
  position: relative;
  /* height: %; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2vh;
  gap: 0.5vh;
  box-sizing: border-box;
  gap: 2vh;
}

.autorinfoTitleText {
  color: var(--storywise-white, #fff);
  font-family: Zilla Slab;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 120% */
  display: flex;
  align-items: flex-start;
  gap: 0.5vh;
  flex-direction: column;
}

.autorinfoText {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  white-space: break-spaces;
  margin-top: 0.3px;
  color: #ffffff;
}

.autorinfoTitledeitails {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  /* flex-wrap: wrap; */
  /* align-self: stretch; */
  white-space: nowrap;
}

.moreInfoTitleauthor {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  user-select: none;
  white-space: nowrap;
}

.personalwweb {
  /* cancel the a line  */
  text-decoration: none;
  font-family: 'Lato';
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  /* if no place go to new line */
  white-space: break-spaces;
}

.inforowAuthor {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  gap: 0.9vw;
  box-sizing: border-box;
  width: 100%;
  /* Ensure the row spans the entire width */
  color: #ffffff;
}

.moreInfoTitleauthorAuthor {
  flex: 1;
  /* Distribute available space equally among titles */
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  user-select: none;
  white-space: nowrap;
  color: #ffffff;
}

.TextLogLineAuthor {
  flex: 3;
  /* Distribute available space equally among text lines */
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  white-space: break-spaces;
  margin-top: 0.3px;
  color: #ffffff;
  color: #ffffff;
}

.moreInfoTitleauthorAuthorheader {
  color: var(--storywise-white, #fff);
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
}

.TextLogLineAuthorheaderAge {
  display: flex;
  padding: 2px 4px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-radius: 6px;
  background: #0e171a;
}

.TextLogLineAuthorheader {
  color: #fff;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
}

.moreInfoTitleObservations {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  white-space: nowrap;
}

a.linkifyText,
a:visited.linkifyText {
  color: #ffffff;
  text-decoration: underline;
}

.tasteBoxContainer {
  display: flex;
  align-items: end;
  justify-content: end;
  padding: 20px 10px;
}

.tasteBox {
  color: #ffffff;
}

.tasteBox > :nth-child(1) {
  margin-bottom: 10px; /* Adds a gap after the first element */
}

.tasteBoxTitle {
  color: #fff;
  font-family: 'Zilla Slab';
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
  white-space: nowrap;
  /* line-height: 20px; */
}

.tasteLineItem {
  word-wrap: none;
}

.gaugeContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gaugeSvg {
  position: relative;
  transform: scale(1); /* Optional scaling */
}

.gaugeArc {
  transition: stroke-dashoffset 0.5s ease-in-out;
}

.gaugeValue {
  position: absolute;
  bottom: 20px; /* Adjust to center text within the arc */
  font-size: 1.2em;
  font-weight: bold;
  color: #000;
  text-align: center;
}

.genre {
  font-size: 14px;
  font-weight: normal;
}

.firstGenre {
  font-weight: bold; /* Slightly heavier */
}

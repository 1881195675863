.FrameRootRoot {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: right;
  width: 100%;
  align-items: center;
  border-width: 1px 0px 0px 0px;
  border-style: solid;
  border-color: #dddee0;
  box-sizing: border-box;
  background-color: #ffffff;
}

.ButtonReject {
  width: 93px;
  position: relative;
  gap: 6px;
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px;
  border-width: 0px;
  border-radius: 14px;
  border-top-width: 2px;
  border-right-width: 2px;
  border-bottom-width: 2px;
  border-left-width: 2px;
  border-style: solid;
  border-color: #ed437d;
  margin-right: 12px;
  /* margin: 0px 0px 1px 0px;
    padding: 8px 13.1px;
    border-width: 0px;
    border-radius: 10px; */
  box-sizing: border-box;
  background: none;
  align-self: center;
}

.ButtonReject:hover {
  opacity: 70%;
  background-color: #f2dcdc;
}

.Dislike {
  width: 13.8px;
  position: relative;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 0.73px 0px 2.67px 0px;
  box-sizing: border-box;
}

.hand {
  width: 10.3px;
  min-width: 0px;
  height: 13.5px;
  min-height: 0px;
  left: 0px;
  top: 0px;
  position: absolute;
  box-sizing: border-box;
}

.handB {
  width: 4.33px;
  min-width: 0px;
  min-height: 0px;
  position: relative;
  box-sizing: border-box;
}

.rejectText {
  position: relative;
  color: black;
  font-weight: 600;
  font-family: Lato;
  line-height: 20px;
  text-align: center;
  white-space: pre-wrap;
  box-sizing: border-box;
}

.ButtonApprove {
  position: relative;
  gap: 6px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px;
  border-width: 0px;
  border-radius: 14px;
  border-top-width: 2px;
  border-right-width: 2px;
  border-bottom-width: 2px;
  border-left-width: 2px;
  border-style: solid;
  border-color: #1ce99f;
  margin-right: 10px;
  /* padding: 8px 12px 8px 13.1px;
  border-width: 0px;
  border-radius: 10px; */
  box-sizing: border-box;
  background: none;
  align-self: center;
}

.ButtonApprove:hover {
  opacity: 70%;
  background-color: #d9f2e9;
}

.Like {
  width: 13.8px;
  position: relative;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 2.67px 0px 0.73px 0px;
  box-sizing: border-box;
}

.handa {
  width: 10.3px;
  min-width: 0px;
  height: 13.5px;
  min-height: 0px;
  left: 3.48828125px;
  top: 0px;
  position: absolute;
  box-sizing: border-box;
}

.handaB {
  width: 4.33px;
  min-width: 0px;
  min-height: 0px;
  position: relative;
  box-sizing: border-box;
}

.approveText {
  position: relative;
  color: black;
  font-weight: 600;
  font-family: Lato;
  line-height: 20px;
  text-align: center;
  box-sizing: border-box;
}

.FrameRootRootManuscriptRejected {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 1vh 1vw 1vh 1vw;
  border-width: 1px 0px 0px 0px;
  border-style: solid;
  border-color: #dddee0;
  box-sizing: border-box;
  background-color: #ffffff;
  align-items: center;
  gap: 0.9vw;
}

.bot {
  cursor: pointer;
  width: 60px;
  align-self: center;
  box-sizing: border-box;
  margin-right: 0.5vw;
  margin-top: 0.5vh;
}
/* on rejection */
.ButtonManuscriptRejected {
  position: relative;
  gap: 6px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px;
  border-width: 0px;
  box-sizing: border-box;
  background: none;
  align-self: center;
}

.ManuscriptRejectedText {
  color: #ed437d;
  text-align: center;
  font-family: 'Lato';
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 120% */
}

.ButtonManuscriptRejected:hover {
  opacity: 70%;
}

.ButtonUndo {
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 8px;
  border-radius: 14px;
  border: 2px solid var(--storywise-jet-stream, #bac6ca);
  background: var(--storywise-white, #fff);
}

.ButtonUndo:hover {
  opacity: 70%;
}

.UndoText {
  color: var(--storywise-vampire-black, #0a0b09);
  text-align: center;
  font-family: 'Lato';
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 120% */
}

.ManuscriptApprovedText {
  color: var(--storywise-secondary-600, #16bb80);
  text-align: center;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 120% */
}

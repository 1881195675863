.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 500;
  box-sizing: border-box;
  background: linear-gradient(
    110.45deg,
    rgba(0, 29, 38, 0.4) 43.18%,
    rgba(1, 51, 67, 0.4) 110.25%
  );
  backdrop-filter: blur(3px);
  box-sizing: border-box;
}

.deletebox {
  position: absolute;
  width: 40%;
  height: 60%;
  left: 30%;
  top: 20%;

  background: #ffffff;

  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  box-sizing: border-box;
}

.boxsplit {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  box-sizing: border-box;
}

.title {
  width: 100%;
  height: 15%;
  box-sizing: border-box;
  box-sizing: border-box;

  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;

  box-sizing: border-box;

  border-bottom: 1px solid #dddee0;
  order: 0;
}

.titleText {
  font-family: 'Zilla Slab';
  font-style: normal;
  font-weight: 700;
  font-size: 3vh;
  color: #0a0b09;
  box-sizing: border-box;

  order: 0;
}

.buttonOut {
  box-sizing: border-box;

  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  justify-content: center;
  align-self: center;
  order: 1;
}

.xbutton {
  box-sizing: border-box;

  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  justify-content: center;
  align-self: center;
  order: 1;
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: inline;
}

.deleteboxContent {
  width: 100%;
  height: 85%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  box-sizing: border-box;
  position: relative;

  order: 1;
}

.deleteboxContent {
  width: 100%;
  height: 85%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  box-sizing: border-box;
  box-sizing: border-box;

  order: 1;
}
.editboxContentTextNimage {
  width: 100%;
  height: 82%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  padding: 0px;
  order: 0;
  gap: 0;
  box-sizing: border-box;
  overflow-y: auto;
}

.editboxContentTextNimage2 {
  width: 85%;
  height: 82%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 0px;
  order: 0;
  gap: 0;
  box-sizing: border-box;
}

.imageContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1vw;
  box-sizing: border-box;

  /* margin-top: 2vh;
    margin-bottom: 2vh; */
}

.imageContainer1 {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1vw;
  margin-top: 1vh;
  margin-bottom: 1vh;
  box-sizing: border-box;
}
.imageUploadPart {
  margin: 20px 0px 20px 0px;
  padding: 4vh 1vw 4vh 1vw;
  width: 88%;
  display: flex;
  flex-direction: row;
  align-self: center;
  align-items: center;
  /* padding: 12px; */
  /* gap: 12px; */
  justify-content: center;
  align-items: center;
  gap: 0vw;
  box-sizing: border-box;

  background: #eaf2f4;
  border-radius: 12px;

  order: 0;
}

.imageUploadPart1 {
  margin: 5px 0px 5px 0px;
  width: 98%;
  display: flex;
  flex-direction: row;
  align-self: center;
  align-items: center;
  padding: 12px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  gap: 2vw;

  background: #eaf2f4;
  border-radius: 12px;

  order: 0;
}
.imageContainerImage1 {
  width: 8vh;
  height: 8vh;
  border-radius: 6px;
  order: 0;
  box-sizing: border-box;
}
.imageContainerImage2 {
  width: 10vh;
  height: 10vh;
  border-radius: 6px;
  order: 0;
  box-sizing: border-box;
}
.imageUploadPart1:hover {
  opacity: 0.5;
  box-sizing: border-box;
}

.book_image {
  /* width: 68px;
    height: 100px; */
  width: 7vw;
  height: 10vh;
  border-radius: 6px;
  box-sizing: border-box;

  /* Inside auto layout */

  order: 0;
}

.book_name {
  align-self: center;
  font-family: 'Zilla Slab';
  font-style: normal;
  font-weight: 600;
  font-size: 2.5vh;
  line-height: 3vh;
  box-sizing: border-box;

  color: #0a0b09;
  margin-bottom: 2vh;
}
.editboxContentTextP {
  width: 92%;
  align-self: center;
  box-sizing: border-box;

  /* Inside auto layout */

  order: 0;
}

.editboxContent {
  width: 100%;
  height: 82%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0px;
  box-sizing: border-box;
  padding: 0vh 2vw 0vh 2vw;
}
.editboxContentTextTitle {
  text-align: center;
  font-family: 'Zilla Slab';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  /* or 125% */
  box-sizing: border-box;

  text-align: center;
  box-sizing: border-box;
  /* Storywise/Vampire Black */

  color: #0a0b09;
}

.buttons {
  width: 100%;
  height: 18%;
  box-sizing: border-box;
  justify-self: flex-end;

  /* Auto layout */
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  /* responsive padding */
  padding: 1vh 1vw 1vh 0vw;
  gap: 1vw;

  border-top: 1px solid #dddee0;

  /* Inside auto layout */
}

.cancelButtton {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.6vh 1vw 0.6vh 1vw;
  gap: 8px;

  background: #ffffff;
  /* Storywise/Jet Stream */

  border: 2px solid #bac6ca;
  border-radius: 14px;

  /* Inside auto layout */
  box-sizing: border-box;

  cursor: pointer;
  order: 0;
}

.cancelButtton:hover {
  opacity: 0.5;
}

.cancelButttonText {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 3vh;
  line-height: 3.4vh;
  /* identical to box height, or 120% */
  box-sizing: border-box;

  display: flex;
  align-items: center;
  text-align: center;

  /* Storywise/Vampire Black */

  color: #0a0b09;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.deleteButtton {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.8vh 1vw 0.8vh 1vw;
  gap: 8px;

  box-sizing: border-box;

  background: #1ceaa0;
  border-radius: 14px;

  /* Inside auto layout */
  cursor: pointer;

  order: 1;
}

.deleteButtton:hover {
  opacity: 0.8;
}

.deleteButttonImage {
  width: 20px;
  height: 20px;

  order: 0;
}

.deleteButttonText {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 3vh;
  line-height: 3.4vh;
  /* identical to box height, or 120% */
  box-sizing: border-box;

  display: flex;
  align-items: center;
  text-align: center;

  /* Storywise/White */

  color: #ffffff;

  order: 1;
}

.word-count-container {
  width: 100%;
  display: flex;
  padding-bottom: 0px;
  align-items: center;
  justify-content: flex-start;
  gap: 1vh;
  flex-direction: row;
  box-sizing: border-box;

  margin-top: 0vh;
}

/* Input */
.input {
  width: 10vw;
  align-self: center;
  height: 5vh;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  font-weight: 500;
  font-size: 2vh;
  border-radius: 16px;
  border: 1px solid var(--storywise-gainsboro, #dddee0);
  background: var(--storywise-white, #fff);
}

.input::placeholder {
  box-sizing: border-box;

  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 1.4vh;
  line-height: 2vh;
  text-align: left;
  color: #a2a4a1;
  align-self: center;
}

.titles {
  color: var(--storywise-vampire-black, #0a0b09);
  font-family: 'Zilla Slab';
  font-size: 2.2vh;
  font-style: normal;
  font-weight: 700;
  line-height: 2.8vh; /* 140% */
  padding: 0px;

  box-sizing: border-box;
}

/* EmailIntegration.module.css */
.imageContainerSelected {
  border: 2px solid #1ceaa0; /* Example: blue border for selected */
  /* box-shadow: 0 0 8px #00EEB0;  */
}

.imageContainerUnselected {
  /* Optional: define styles for unselected state if necessary */
}

.titl {
  color: var(--storywise-vampire-black, #0a0b09);
  font-family: 'Zilla Slab';
  font-size: 3vh;
  font-style: normal;
  font-weight: 700;
  /* line-height: 3.8vh;  */
  padding: 0px;
  padding-top: 2vh;
  padding-bottom: 2vh;
  box-sizing: border-box;
}

.checkboxContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 2vh;
  font-style: normal;
  font-weight: 500;
  line-height: 2vh;
  gap: 0.2vw;
  align-self: flex-start;
  color: var(--storywise-vampire-black, #0a0b09);
  font-family: 'Zilla Slab';
  font-size: 1.5vh;
  font-style: normal;
  font-weight: 700;
  box-sizing: border-box;

  line-height: 2.8vh; /* 140% */
  padding: 0px;
}

.checkContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.2vh;
  font-style: normal;
  font-weight: 500;
  line-height: 2vh;
  gap: 0.2vw;
  align-self: flex-start;
  justify-self: flex-end;
  margin-bottom: 1vh;
  box-sizing: border-box;
}

.linktomail {
  color: #2f80ed;
  font-family: 'Lato';
  font-size: 1.8vh;
  font-style: normal;
  font-weight: 500;
  box-sizing: border-box;

  line-height: 2.4vh;
  text-decoration: none;
}

.privacyLink {
  color: #2f80ed;
  font-size: 1.2vh;
  font-style: normal;
  font-weight: 500;
  box-sizing: border-box;

  line-height: 2vh;
}

.Link {
  color: #2f80ed;
  font-size: 1.2vh;
  font-style: normal;
  font-weight: 500;
  box-sizing: border-box;

  line-height: 2vh;
}

.outlook_image {
  width: 20vh;
  border-radius: 6px;
  order: 0;
}

.warningTitle {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 2.5vh;
  line-height: 3vh;
  box-sizing: border-box;
  font-size: 2vh;
  font-style: normal;
  font-weight: 500;
  line-height: 2vh;
  gap: 0.2vw;
  align-self: flex-start;
  color: var(--storywise-vampire-black, #0a0b09);
  font-family: 'Zilla Slab';
  font-size: 1.5vh;
  font-style: normal;
  font-weight: 700;
  box-sizing: border-box;

  line-height: 2.8vh; /* 140% */
}

.emailProviderName {
  font-family: 'Zilla Slab';
}
